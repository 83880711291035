import GeneralRepository from "../general.repository";
import IGeneralRepository from "interfaces/irepository.interface";

export class AdvertisementRepository
  extends GeneralRepository
  implements IGeneralRepository
{
  constructor() {
    super("advertisement");
  }
}
