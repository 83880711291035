import * as React from "react";
import { Box } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

interface SkeletonProps {
  lines?: number;
  width?: number;
}

const SkeletonPaymentFormItem: React.FC<SkeletonProps> = ({ lines }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "start",
        alignItems: "start",
        flexDirection: "column",
        width: "100%",
        mt: "-14px",
      }}>
      <Skeleton variant="text" sx={{ fontSize: "14px", width: "150px" }} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "start",
          flexDirection: "row",
          width: "100%",
          mt: 1,
          gap: 5,
        }}>
        <Skeleton variant="text" sx={{ fontSize: "14px", width: "70px" }} />
        <Skeleton variant="text" sx={{ fontSize: "14px", width: "110px" }} />
        <Skeleton variant="text" sx={{ fontSize: "14px", width: "90px" }} />
      </Box>
    </Box>
  );
};

export { SkeletonPaymentFormItem };
