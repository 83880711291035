import { Box, Drawer, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import { Config } from "tools/utils/config";
import CloseIcon from "@mui/icons-material/Close";
import { MyButton } from "components/elements/button/MyButton";
import { useLabel } from "hooks/useLabel";
import { FilterList } from "components/product/filter/FilterList";
import { MyIcon } from "components/elements/icon/MyIcon";
import { IconType } from "components/elements/icon/IconContext";
import { FilterTools } from "components/product/filter/FilterTools";
import { useSearch } from "hooks/useSearch";

const Filters: React.FC = () => {
  const { LL } = useLabel();
  const {
    openFilterDrawer,
    closeFilterDrawer,
    handleOpenFilterDrawer,
    filterCriteriaLength,
    addCriteriaFilter,
    removeCriteriaFilter,
    criteriaFilter,
    removeRangeFilterMin,
    removeRangeFilterMax,
    replaceRangeFilterMin,
    replaceRangeFilterMax,
    rangeFilter,
    clearFilterCriteria,
  } = useSearch();

  const processFilterLength = () => {
    if (filterCriteriaLength) {
      return `(${filterCriteriaLength})`;
    } else {
      return "";
    }
  };

  return (
    <React.Fragment>
      <MyButton
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 1,
          width: "100%",
        }}
        customColor="blackTransparent"
        cb={handleOpenFilterDrawer}
      >
        <MyIcon type={IconType.FILTER_ICON} />
        <Typography sx={{ textTransform: "capitalize" }} variant={"body1"}>
          {LL("Filter")}
        </Typography>
        <Typography sx={{ textTransform: "capitalize" }} variant={"body1"}>
          {processFilterLength()}
        </Typography>
      </MyButton>
      <Drawer
        open={openFilterDrawer}
        onClose={closeFilterDrawer}
        anchor={"right"}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"flex-end"}
        >
          <Box
            className="drawerCloseButton"
            sx={{
              mt: 3,
              mr: 3,
              width: "auto",
              position: "absolute",
              top: 0,
              right: 0,
              zIndex: 10,
            }}
          >
            <IconButton onClick={closeFilterDrawer}>
              <CloseIcon className="drawerSvg" />
            </IconButton>
          </Box>
        </Stack>
        <Box
          sx={{
            width: { xs: "auto", sm: Config.DRAWER_WIDTH_MAIN },
            mt: 5,
          }}
        >
          <Box sx={{ px: 4, py: 2 }}>
            <FilterTools
              clearFilterCriteria={clearFilterCriteria}
              filterCriteriaLength={filterCriteriaLength}
            />
          </Box>
          <Box sx={{ px: 2, py: 1 }}>
            <FilterList
              criteriaFilter={criteriaFilter}
              addCriteriaFilter={addCriteriaFilter}
              removeCriteriaFilter={removeCriteriaFilter}
              rangeFilter={rangeFilter}
              removeRangeFilterMin={removeRangeFilterMin}
              removeRangeFilterMax={removeRangeFilterMax}
              replaceRangeFilterMin={replaceRangeFilterMin}
              replaceRangeFilterMax={replaceRangeFilterMax}
              filterCriteriaLength={filterCriteriaLength}
            />
          </Box>
        </Box>
      </Drawer>
    </React.Fragment>
  );
};

export { Filters };
