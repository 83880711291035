import { GeneralService } from "services/general.service";
import Idto from "../../interfaces/idto.interface";
export default class RequestSortCriteriaDTO implements Idto {
  field?: string;
  asc?: boolean;

  static prepareSortCriteria(
    field: string,
    asc: boolean
  ): RequestSortCriteriaDTO {
    const sortCriteria = new RequestSortCriteriaDTO();
    sortCriteria.field = field;
    sortCriteria.asc = asc;
    return sortCriteria;
  }

  static prepareSorter(fields: string[]): Sorter[] {
    const arr: Sorter[] = [];
    fields.forEach((field) => {
      arr.push(
        {
          label: GeneralService.LL(field + "_asc"),
          value: RequestSortCriteriaDTO.prepareSortCriteria(field, true),
        },
        {
          label: GeneralService.LL(field + "_desc"),
          value: RequestSortCriteriaDTO.prepareSortCriteria(field, false),
        }
      );
    });
    return arr;
  }

  static prepareIdentifiers = (string?: string): string[] => {
    if(!string) return [];
    string = string.replace(/\s+/g, '');
    return string.split(",");
  }
}

export type Sorter = {
  label: string;
  value: RequestSortCriteriaDTO;
};
