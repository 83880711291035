import { Box, Typography } from "@mui/material";
import { CartDto } from "dto/sale/cart.dto";
import { useCart } from "hooks/useCart";
import { useCountry } from "hooks/useCountry";
import { useLabel } from "hooks/useLabel";
import React from "react";

const CartTotalPrice: React.FC = () => {
  const { LL } = useLabel();
  const { currencyCode, currencyRate } = useCountry();
  const { cartObjects } = useCart();

  if (!cartObjects) return null;
  if (!cartObjects.length) return null;
  const total = CartDto.calculateTotalPrice(cartObjects, currencyRate);

  return (
    <Box
      sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
      <Typography
        sx={{ fontWeight: "bold", fontSize: { xs: "16px", sm: "14px" } }}>
        {LL("total_price")}
      </Typography>
      <Typography
        sx={{
          fontWeight: "bold",
          fontSize: { xs: "16px", sm: "14px" },
        }}>{` ${total} ${currencyCode}`}</Typography>
    </Box>
  );
};

export { CartTotalPrice };
