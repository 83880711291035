import {
  ListItem,
  IconButton,
  ListItemButton,
  Box,
  Typography,
  Divider,
} from "@mui/material";
import { CartDto } from "dto/sale/cart.dto";
import { useCart } from "hooks/useCart";
import { useCountry } from "hooks/useCountry";
import { useLabel } from "hooks/useLabel";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";
import { RouteTools } from "tools/utils/route.tool";
import { Image } from "components/elements/display/Image";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { InputQuantity } from "components/elements/form/InputQuantity";
import { ProductFilterList } from "components/product/product/checkout/ProductFilterList";
import { useResponsive } from "hooks/useResponsive";

type Props = {
  item: CartDto;
};
const CartItem: React.FC<Props> = ({ item }) => {
  const { LL } = useLabel();
  const { currencyCode, currencyRate } = useCountry();
  const { removeFromCart, setQuantityCart } = useCart();
  const { downSM } = useResponsive();

  const handleClick = () => {
    const url = CommonTools.processObjectField(item, [
      "product",
      "_urlObj",
      "urlfull",
    ]);
    if (!url) return;

    RouteTools.setHistory(url, {});
  };

  const handleRemove = () => {
    if (!item) return;
    removeFromCart(item);
  };

  if (!item) return null;
  const total = CartDto.calculateTotalPriceProduct(
    item.real_price,
    item.quantity,
    currencyRate
  );

  const setQuantity = (value: number | string) => {
    if (!item) return;
    setQuantityCart(value, item);
  };
  return (
    <ListItem
      className="cartItem"
      sx={{
        flexDirection: { xs: "column", sm: "row" },
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        px: 0,
      }}
      secondaryAction={
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}>
          <Box
            sx={{
              px: 2,
              display: { xs: "block", sm: "none" },
            }}>
            <Divider
              sx={{
                // backgroundColor: "rgba(0, 0, 0, 0.1)",
                width: "auto",
                height: "1px",
                px: 15,
                py: "1px",
                boxShadow: "none",
                // border: "1px solid rgba(0, 0, 0, 0.1)",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              pl: { xs: 0, sm: 2 },
            }}>
            <IconButton onClick={handleRemove}>
              <DeleteForeverIcon />
            </IconButton>
          </Box>
        </Box>
      }>
      <ListItemButton
        className="cartItem"
        onClick={handleClick}
        sx={{
          // maxWidth: { xs: "none", md: "324px" },
          // pr: { xs: 0, sm: 2 },
          // px: 0,
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },

          // background: "red",
        }}
        disableRipple>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: { xs: "100%", sm: 100 },
            minWidth: { xs: "100%", sm: 100 },
            height: { xs: "100%", sm: 100 },
            maxHeight: { xs: "100%", sm: 100 },
          }}>
          <Image
            url={CommonTools.processObjectField(item, [
              "product",
              "_galleryDefault",
              "cdnurl",
            ])}
            withLinK={false}
            sizeInPx={{
              width: downSM ? 286 : 100,
              height: downSM ? 286 : 100,
            }}
          />
        </Box>
        <Box sx={{ ml: 2, width: "100%" }}>
          <Box>
            <Typography
              sx={{
                maxWidth: { xs: "calc(100% - 10px )" },
                width: "170px",
                fontWeight: "bold",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                py: { xs: 1, sm: 0 },
              }}>
              {CommonTools.processObjectField(item, ["product", "_name"])}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
              gap: "4px",
              width: "100%",
              mt: 1,
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}>
            <Typography>{LL("Quantity")}</Typography>
            <Box sx={{ maxWidth: "130px" }}>
              <InputQuantity value={item.quantity} setValue={setQuantity} />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              gap: "4px",
              mt: 1,
            }}>
            <Box>
              <Typography>{LL("Price")}</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                flexDirection: "row",
                gap: "4px",
              }}>
              <Typography sx={{ fontWeight: "bold" }}>{total}</Typography>
              <Typography sx={{ fontWeight: "bold" }}>
                {currencyCode}
              </Typography>
            </Box>
          </Box>
        </Box>
      </ListItemButton>
    </ListItem>
  );
};

export { CartItem };
