import React from "react";
import ReactPlayer from "react-player";
import { logger } from "tools/utils/logger";

type Props = {
  url?: string | null;
  width?: number | string;
  height?: number | string;
  controls?: boolean;
  light?: any;
  style?: React.CSSProperties;
  objectFit?: "cover" | "contain" | "fill" | "none" | "scale-down";
};

const VideoFile: React.FC<Props> = ({
  url,
  width,
  height,
  controls = true,
  light,
  style,
  objectFit,
}) => {
  url = url ?? "";
  width = width ?? "640px";
  height = height ?? "360px";


  return (
    <ReactPlayer
      url={url}
      width={width}
      height={height}
      controls={controls}
      light={light}
      style={style}
      playing={true}
      loop={true}
      muted={true}
      playsinline={true}
      pip={false}
      objectfit={objectFit}
    />
  );
};

export { VideoFile };
