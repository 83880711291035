import React from "react";

import { AdvertisementDto } from "dto/static/advertisement.dto";
import { Box, Typography } from "@mui/material";

import { CommonTools } from "tools/utils/common.tool";
import { NavButton } from "components/elements/button/NavButton";

type Props = {
  item: AdvertisementDto;
  index: number;
};

const AdvertisementItemHomeTop: React.FC<Props> = ({ item }) => {
  if (!item) return null;
  return (
    <Box sx={{ position: "relative", width: "auto", height: "auto" }}>
      {/* <Box sx={{ width: "100%", height: 1080 }}> */}
      <Box className="mainBanner">
        <Box
          sx={{
            width: "100%",
            height: "100%",
            backgroundImage: `url(${CommonTools.processObjectField(item, [
              "_filecdnurl",
            ])})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              backgroundColor: "rgba(0, 0, 0, 0.1)",
            },
          }}></Box>
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: { xs: "50%", sm: "60%" },
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
          width: "100%",
        }}>
        <Box>
          <Typography
            sx={{
              fontSize: { xs: "20px", sm: "24px" },
              color: "white",
              fontWeight: "bold",
              px: { xs: 2, sm: 0 },
            }}>
            {CommonTools.processObjectField(item, ["_name"])}
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              fontSize: { xs: "30px", sm: "60px" },
              color: "white",
              fontWeight: "bold",
              px: { xs: 2, sm: 0 },
            }}>
            {CommonTools.processObjectField(item, ["_shortdescription"])}
          </Typography>
        </Box>
        <Box
          sx={{
            mt: 3,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Box sx={{ width: "200px" }}>
            <NavButton
              buttonTheme="goldenBtn"
              href={CommonTools.processObjectField(item, ["urltogo"])}>
              {CommonTools.processObjectField(item, ["_btname"])}
            </NavButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export { AdvertisementItemHomeTop };
