import {JWTRepository} from "../../repositories/system/jwt.repository";
import { GeneralService } from "../general.service";

export class JWTService extends GeneralService<JWTRepository> {
  constructor() {
    super(new JWTRepository());
  }

  generate = async (cb?: any, cbparameters?: any): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;

    const t = await this.repository.generate(this.handleGet, cbparameters);
    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  refresh = async (cb?: any, cbparameters?: any): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;

    const t = await this.repository.refresh(this.handleGet, cbparameters);
    if (cb === undefined) {
      return this.handleGet(t);
    }
  };
}
