import React from "react";
import { Box } from "@mui/material";
import { useLabel } from "hooks/useLabel";
import { NavButton } from "components/elements/button/NavButton";

type Props = {
  code: string;
  label: string;
};

const StaticHTTPCodePage: React.FC<Props> = ({ code, label }) => {
  const { LL } = useLabel();

  return (
    <Box
      sx={{
        width: "100%",
        height: "90vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box sx={{ fontSize: "150px", fontWeight: "500" }}>{code}</Box>
      <Box sx={{ fontSize: "30px" }}>{LL(label)}</Box>
      <NavButton
        sx={{ fontSize: "16px", mt: 1 }}
        href={"/"}
        underline="hover"
        color="text.secondary"
      >
        {LL("404_backHome")}
      </NavButton>
    </Box>
  );
};

export { StaticHTTPCodePage };
