import * as React from "react";
import { Box, Divider } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Unstable_Grid2";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { inherits } from "util";
import { useResponsive } from "hooks/useResponsive";

interface SkeletonProps {
  lines?: number;
  width?: number;
}

const SkeletonGalleryHome: React.FC<SkeletonProps> = ({ lines }) => {
  const {
    matchesXS,
    matchesSM,
    matchesMD,
    matchesLG,
    downXS,
    downLG,
    downXL,
    downSM,
  } = useResponsive();

  let gridItemsCount;
  if (downSM) {
    gridItemsCount = 12;
  } else if (downXL) {
    gridItemsCount = 6;
  } else {
    gridItemsCount = 3;
  }

  const gridItem = (
    <Grid xs={12}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          flexDirection: "column",
          width: "100%",
        }}>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: { xs: "repeat(2, 1fr)", md: "repeat(4, 1fr)" },
            gridTemplateRows: {
              xs: "repeat(4, 475px)",
              md: "repeat(2, 475px)",
            },
            gap: "10px",
            width: "100%",
          }}>
          <Skeleton variant="rectangular" sx={{ height: 475, width: "100%" }} />
          <Skeleton variant="rectangular" sx={{ height: 475, width: "100%" }} />
          <Skeleton
            variant="rectangular"
            sx={{ height: 960, width: "100%", gridRow: "span 2" }}
          />
          <Skeleton variant="rectangular" sx={{ height: 475, width: "100%" }} />
          <Skeleton variant="rectangular" sx={{ height: 475, width: "100%" }} />
          <Skeleton variant="rectangular" sx={{ height: 475, width: "100%" }} />
          <Skeleton variant="rectangular" sx={{ height: 475, width: "100%" }} />
        </Box>
      </Box>
    </Grid>
  );

  return (
    <Box sx={{ width: "100%", height: "auto" }} className="">
      <Box
        sx={{
          width: "100%",
          minHeight: "960px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          mt: 4,
        }}>
        <Grid
          container
          sx={{
            // maxWidth: "1522px",
            width: "100%",
            overflow: "hidden",
            mt: 4,
          }}
          spacing={2}>
          {Array(lines)
            .fill(null)
            .map((_, index) => (
              <React.Fragment key={index}>{gridItem}</React.Fragment>
            ))}
        </Grid>
      </Box>
    </Box>
  );
};

export { SkeletonGalleryHome };
