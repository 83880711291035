import { Box, SxProps } from "@mui/material";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { NavigatorDto } from "dto/static/navigator.dto";
import { useList } from "hooks/useList";
import React from "react";
import { NavigatorService } from "services/static/navigator.service";
import { NavigatorSection } from "tools/types/navigatorsection";
import { Status } from "tools/types/status";
import { BottomNavigatorItem } from "./BottomNavigatorItem";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";

type Props = {
  level: number;
  idParent: string | number;
};
const service = new NavigatorService();
const BottomNavigatorLevel: React.FC<Props> = ({ level, idParent }) => {
  const getList = (cb?: any, cbParams?: any, req?: RequestListDTO) => {
    service.getList(cb, cbParams, req);
  };
  const [loading, objects] = useList<NavigatorDto>(
    getList,
    new RequestListDTO(
      [
        RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()]),
        RequestFilterDTO.prepareFilter("navigatorsection", [
          NavigatorSection.BOTTOM.toString(),
        ]),
        RequestFilterDTO.prepareFilter("idparent", [idParent.toString()]),
      ],
      1,
      -1,
      [RequestSortCriteriaDTO.prepareSortCriteria("order", true)]
    ),
    [idParent],
    true,
    `menu-bottom-${idParent}`
  );

  const processItem = (item: NavigatorDto, index: number) => {
    if (!item) return null;
    return (
      <Box
        sx={{
          className: "bottomNavigatorLevel",
          display: "flex",

          gap: "30%",
          width: "120px",
          justifyContent: "center",
          alignItems: "start",
        }}
        key={index}>
        <BottomNavigatorItem item={item} level={level} />
      </Box>
    );
  };

  if (loading) return null;
  if (!objects) return null;
  if (!objects.length) return null;
  const style: SxProps =
    idParent === "0"
      ? {
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          gap: "10%",
          textDecoration: "none",
        }
      : {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        };
  return (
    <Box sx={style}>
      {objects.map((item: NavigatorDto, index: number) =>
        processItem(item, index)
      )}
    </Box>
  );
};

export { BottomNavigatorLevel };
