import { Search } from "@mui/icons-material";
import { IconButton, InputAdornment } from "@mui/material";
import { MyTextField } from "components/elements/form/MyTextField";
import { useLabel } from "hooks/useLabel";
import { useSearch } from "hooks/useSearch";
import React, { useState } from "react";
import { RouteTools } from "tools/utils/route.tool";

type Props = {
  handleAction?: () => void;
};
const SearchField: React.FC<Props> = ({ handleAction }) => {
  const { LL } = useLabel();
  const { handleSetSearch } = useSearch();
  const [searchValue, setSearchValue] = useState<string>("");
  const handleChange = (field: string, value: string) => {
    setSearchValue(value);
  };

  const applySearch = () => {
    handleSetSearch(searchValue);
    RouteTools.setHistory("/search", {});
    setTimeout(() => {
      if (handleAction) handleAction();
      setSearchValue("");
    }, 100);
  };
  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      applySearch();
    }
  };

  return (
    <MyTextField
      field="search"
      value={searchValue}
      setObjectField={handleChange}
      label={LL("search")}
      fullWidth
      variant="standard"
      selectTheme={"standardText3"}
      onKeyDown={onKeyDown}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={applySearch}>
              <Search />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export { SearchField };
