import { Box } from "@mui/material";
import { Image } from "components/elements/display/Image";
import { useConfigFile } from "hooks/useConfigFile";
import React from "react";

const CheckoutImage: React.FC = () => {
  const { CF } = useConfigFile();
  const url = CF("checkout_image");
  if (!url) return null;
  return (
    <Box sx={{ maxWidth: "1920px" }}>
      <Image
        url={url}
        withLinK={false}
        sizeInPercentage={{
          width: "100%",
          height: "100%",
        }}
        typeMeasure="percentage"
      />
    </Box>
  );
};

export { CheckoutImage };
