import React, { useRef, useState } from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Box from "@mui/material/Box";
// import { MyButton } from "components/general/MyButton";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import EastIcon from "@mui/icons-material/East";
import IconButton from "@mui/material/IconButton";
import { logger } from "tools/utils/logger";

type CustomArrowProps = {
  type?: string;
  onClick?: () => void;
  isEdge?: boolean;
  carouselStyle?: any;
};

const CustomArrowLeft: React.FC<CustomArrowProps> = ({
  type,
  onClick,
  isEdge,
  carouselStyle,
}) => {
  return (
    <Box
      className={
        carouselStyle === "primary" || carouselStyle === "testimonial"
          ? "slider-container"
          : "slider-container1"
      }>
      <IconButton
        onClick={onClick}
        className="icon-slider"
        sx={
          carouselStyle === "primary" || carouselStyle === "testimonial"
            ? { position: "absolute", left: 10, top: "48%", zIndex: 1 }
            : { position: "absolute", right: 50, top: "95%", zIndex: 1 }
        }
        size="large">
        {carouselStyle === "primary" ? (
          <KeyboardArrowLeftIcon style={{ fontSize: 50 }} />
        ) : (
          <KeyboardBackspaceIcon style={{ fontSize: 20 }} />
        )}
      </IconButton>
    </Box>
  );
};

const CustomArrowRight: React.FC<CustomArrowProps> = ({
  type,
  onClick,
  isEdge,
  carouselStyle,
}) => {
  return (
    <Box
      className={
        carouselStyle === "primary" || carouselStyle === "testimonial"
          ? "slider-container"
          : "slider-container1"
      }>
      <IconButton
        onClick={onClick}
        className="icon-slider"
        sx={
          carouselStyle === "primary" || carouselStyle === "testimonial"
            ? { position: "absolute", right: 10, top: "48%", zIndex: 1 }
            : { position: "absolute", right: 10, top: "95%", zIndex: 1 }
        }
        size="large">
        {carouselStyle === "primary" ? (
          <KeyboardArrowRightIcon style={{ fontSize: 50 }} />
        ) : (
          <KeyboardBackspaceIcon
            style={{ fontSize: 20, transform: "rotate(180deg)" }}
          />
        )}
      </IconButton>
    </Box>
  );
};

type CarouselProps = {
  children?: React.ReactNode;
  _speed?: number;
  _slidesToShow?: number;
  _slidesToScroll?: number;
  _autoplay?: boolean;
  _autoplaySpeed?: number;
  _dots?: boolean;
  _initialSlide?: number;
  carouselStyle?: any;
  customPaging?: (i: number) => any;
};

const Carousel: React.FC<CarouselProps> = ({
  children,
  _speed,
  _slidesToShow,
  _slidesToScroll,
  _autoplay,
  _autoplaySpeed,
  _dots,
  _initialSlide,
  carouselStyle,
  customPaging,
  ...props
}) => {
  const mainCarousel = useRef(null);
  const dotsCarousel = useRef(null);

  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: carouselStyle === "gallery" ? dotsCarousel.current : null,
  };

  const settingsDots = {
    slidesToShow: 4,
    slidesToScroll: 1,
    asNavFor: mainCarousel.current,
    focusOnSelect: true,
  };

  const customCarouselStyle =
    carouselStyle === "primary"
      ? {
          dots: true,
          dotsClass: "slick-dots slick-dots1",
        }
      : carouselStyle === "secondary"
      ? {
          dots: false,
          dotsClass: "slick-dots slider-dots2",
        }
      : carouselStyle === "gallery"
      ? {
          dots: true,
          dotsClass: "slick-dots slick-gallery",
        }
      : carouselStyle === "testimonial"
      ? {
          dots: true,
          dotsClass: "slick-dots slick-testimonial",
        }
      : {
          dots: false,
          dotsClass: "slick-dots slick-thumb",
        };

  const pagingSettings = customPaging
    ? {
        customPaging: (i: number) => customPaging(i),
      }
    : {};
  const settings = {
    // dots: true,
    ...customCarouselStyle,
    // fade: true,
    ...pagingSettings,
    slidesToShow: _slidesToShow ?? 1,
    slidesToScroll: _slidesToScroll ?? 1,
    initialSlide: _initialSlide ?? 0,
    autoplay: _autoplay ?? true,
    autoplaySpeed: _autoplaySpeed ?? 8000,
    nextArrow: <CustomArrowRight carouselStyle={carouselStyle} />,
    prevArrow: <CustomArrowLeft carouselStyle={carouselStyle} />,

    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: _slidesToShow ?? 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: _slidesToShow && _slidesToShow < 1 ? _slidesToShow : 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box sx={{}}>
      <Slider {...settings} className="slider-design">
        {children}
      </Slider>
    </Box>
  );
};

export { Carousel };
