import { Box } from "@mui/material";
import { MyBreadcrumbs } from "components/elements/breadcrumb/MyBreadcrumbs";
import { BreadcrumbDto } from "dto/app/breadcrumb.dto";
import { PageDto } from "dto/static/page.dto";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";

type Props = {
  object: PageDto;
};

const PageBreadcrumb: React.FC<Props> = ({ object }) => {
  return (
    <Box>
      <MyBreadcrumbs
        objects={[
          new BreadcrumbDto(CommonTools.processObjectField(object, ["_name"])),
        ]}
      />
    </Box>
  );
};

export { PageBreadcrumb };
