import GeneralRepository from "../general.repository";
import IGeneralRepository from "interfaces/irepository.interface";

export class CategoryBlogRepository
  extends GeneralRepository
  implements IGeneralRepository
{
  constructor() {
    super("categoryblog");
  }

}
