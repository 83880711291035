import React from "react";

import { PartnerDTO } from "dto/static/partner.dto";
import { Box } from "@mui/material";
import { useList } from "hooks/useList";
import { PartnerService } from "services/static/partner.service";
import RequestListDTO from "dto/app/requestlist.dto";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import { Status } from "tools/types/status";
import { PartnerItem } from "./PartnerItem";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { SkeletonPartnerList } from "components/elements/skeleton/SkeletonPartnerList";

const service = new PartnerService();
const PartnerList: React.FC = () => {
  const getList = (cb?: any, cbParams?: any, req?: RequestListDTO) => {
    service.getList(cb, cbParams, req);
  };

  const [loading, objects] = useList<PartnerDTO>(
    getList,
    new RequestListDTO(
      [RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()])],
      1,
      -1,
      [RequestSortCriteriaDTO.prepareSortCriteria("order", true)]
    ),
    [],
    true,
    "partner_page_site"
  );

  const processItem = (item: PartnerDTO, index: number) => {
    if (!item) return null;
    return (
      <Box
        data-aos="fade-up"
        data-aos-anchor-placement="top-bottom"
        data-aos-delay={index * 100}
        key={index}
        sx={{
          px: 1,
          py: 2,
        }}>
        <PartnerItem item={item} />
      </Box>
    );
  };
  if (loading)
    return (
      <Box mt={3}>
        <SkeletonPartnerList lines={4} />
      </Box>
    );
  if (!objects) return null;
  if (!objects.length) return null;
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Box
        className="button-grid"
        sx={{
          justifyContent: "center",
          alignItems: "center",
          p: 2,
          px: 10,
          gap: 4,
        }}>
        {objects.map((item: PartnerDTO, index: number) =>
          processItem(item, index)
        )}
      </Box>
    </Box>
  );
};

export { PartnerList };
