import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import SelectOptions from "dto/app/selectoptions.dto";
import React from "react";
import { PayMethodType } from "tools/types/paymethod";

type Props = {
  value: string | number | undefined;
  setObjField: (field: string, value: any) => void;
  field?: string;
  label: string;
};

const SelectPayMethod: React.FC<Props> = ({
  value,
  setObjField,
  field = "paymethod",
  label,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setObjField(field, event.target.value);
  };
  const processItem = (item: SelectOptions, index: number) => {
    return (
      <FormControlLabel
        key={index}
        value={item.value}
        control={<Radio />}
        label={item.label}
      />
    );
  };

  const objects = PayMethodType.GA("general");
  return (
    <FormControl>
      <FormLabel className="methodLabel" id="paymethod-label" sx={{ fontSize: "14px" }}>
        {label}
      </FormLabel>
      <RadioGroup
        row
        aria-labelledby="paymethod-label"
        name="paymethod-group"
        value={value}
        onChange={handleChange}>
        {objects.map((item, index) => processItem(item, index))}
      </RadioGroup>
    </FormControl>
  );
};

export { SelectPayMethod };
