import { Box, MenuItem, Typography } from "@mui/material";
import RequestSortCriteriaDTO, {
  Sorter,
} from "dto/app/requestsortcriteria.dto";
import React from "react";
import SortIcon from "@mui/icons-material/Sort";
import FilterListIcon from "@mui/icons-material/FilterList";
import { MyIcon } from "../icon/MyIcon";
import { IconType } from "../icon/IconContext";

type Props = {
  item: Sorter;
  setSortCriteria: (sortCriteria: RequestSortCriteriaDTO) => void;
  selectedItem?: Sorter;
};

const SorterItem: React.FC<Props> = ({
  item,
  setSortCriteria,
  selectedItem,
}) => {
  const handleClick = () => {
    setSortCriteria(item.value);
  };
  const selected = selectedItem
    ? selectedItem.value.field === item.value.field &&
      selectedItem.value.asc === item.value.asc
    : false;
  return (
    <MenuItem selected={selected} onClick={handleClick}>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        {item.value.asc ? (
          <MyIcon type={IconType.ASC_ICON} colorFill="black" />
        ) : (
          <MyIcon type={IconType.DESC_ICON} colorFill="black" />
        )}
        <Typography>{item.label}</Typography>
      </Box>
    </MenuItem>
  );
};

export { SorterItem };
