import Idto from "interfaces/idto.interface";
import { SelectedProductFilterDto } from "./productfilter.dto";

export class OfferFilterDto implements Idto {
  id?: number | string;
  idoffer?: number;
  idfilter?: number;
  idfilterdictionar?: number;

  constructor(
    id?: number | string,
    idoffer?: number,
    idfilter?: number,
    idfilterdictionar?: number
  ) {
    this.id = id || 0;
    this.idoffer = idoffer || 0;
    this.idfilter = idfilter || 0;
    this.idfilterdictionar = idfilterdictionar || 0;
  }

  static offerFilterToSelectedFilter(
    object: OfferFilterDto
  ): SelectedProductFilterDto {
    return new SelectedProductFilterDto(
      object.idfilter ?? 0,
      object.idfilterdictionar ?? 0
    );
  }

  static arrOfferFilterToSelectedFilter(
    arr?: OfferFilterDto[]
  ): SelectedProductFilterDto[] {
    if (!arr) return [];

    return arr.map((item) => this.offerFilterToSelectedFilter(item));
  }
}
