import { Box, Typography } from "@mui/material";
import { OrderDto } from "dto/sale/order.dto";
import { useLabel } from "hooks/useLabel";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";
import { DateTools } from "tools/utils/date.tool";

type Props = {
  object: OrderDto;
};

const DeliveryInfo: React.FC<Props> = ({ object }) => {
  const { LL } = useLabel();

  const processDate = () => {
    if (!object) return null;
    if (!object.datedelivery) return null;
    return (
      <Box>
        <Typography>{LL("datedelivery")}</Typography>
        <Typography>
          {DateTools.displayTimestampInDateAndHHMM(object.datedelivery)}
        </Typography>
      </Box>
    );
  };
  if (!object) return null;
  return (
    <Box sx={{ width: "100%" }}>
      <Box>{processDate()}</Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
        <Typography>{LL("delivery_location")}</Typography>
        <Typography sx={{ fontWeight: "bold" }}>
          {`${CommonTools.processObjectField(object, [
            "countryObj",
            "nameofficial",
          ])}, ${CommonTools.processObjectField(object, [
            "locationObj",
            "_name",
          ])}, ${CommonTools.processObjectField(object, [
            "destinatar_address",
          ])}`}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
        <Typography>{LL("delivery_method")}</Typography>
        <Typography sx={{ fontWeight: "bold" }}>
          {CommonTools.processObjectField(object, [
            "deliveryMethodObj",
            "_name",
          ])}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
        <Typography>{LL("delivery_comments")}</Typography>
        <Typography sx={{ fontWeight: "bold" }}>
          {CommonTools.processObjectField(object, ["delivery_comments"])}
        </Typography>
      </Box>
    </Box>
  );
};

export { DeliveryInfo };
