import React from "react";
import { NoImage } from "./NoImage";

type SizeImageInPx = {
  width?: number;
  height?: number;
};

type SizeImageInPercentage = {
  width?: string;
  height?: string;
};
export type Measures = "pixel" | "percentage";
type Props = {
  url: string;
  alt?: string;
  sizeInPx?: SizeImageInPx;
  sizeInPercentage?: SizeImageInPercentage;
  style?: React.CSSProperties;
  typeMeasure?: Measures;
  withLinK?: boolean;
  fullUrl?: string;
  noWrap?: boolean;
  cutImageWidth?: number;
  cutImageHeight?: number;
};

const Image: React.FC<Props> = ({
  url,
  alt = "image",
  sizeInPx = {
    width: 100,
    height: 100,
  },
  sizeInPercentage = {
    width: "100%",
    height: "100%",
  },
  style,
  typeMeasure = "pixel",
  withLinK = true,
  fullUrl = url,
  noWrap = false,
  cutImageWidth,
  cutImageHeight,
}) => {
  const inPixel = typeMeasure === "pixel";
  let width = inPixel ? sizeInPx.width : sizeInPercentage.width;
  let height = inPixel ? sizeInPx.height : sizeInPercentage.height;
  url =
    url && inPixel
      ? `${url}?_w=${width}&_h=${height}&_nw=${noWrap ? 1 : 0}`
      : url;

  style = style || {};
  if (cutImageWidth && cutImageHeight) {
    url = `${url}?_w=${cutImageWidth}&_h=${cutImageHeight}&_nw=${
      noWrap ? 1 : 0
    }`;
  }

  const image = (
    <img src={url} width={width} height={height} alt={alt} style={style} />
  );

  if (!url) return <NoImage />;
  return withLinK ? (
    <a href={fullUrl} target="_blank" rel="noreferrer">
      {image}
    </a>
  ) : (
    image
  );
};

export { Image };
