import * as React from "react";
import { Box, Divider } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Unstable_Grid2";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { inherits } from "util";
import { useResponsive } from "hooks/useResponsive";

interface SkeletonProps {
  lines?: number;
  width?: number;
}

const SkeletonLoginPage: React.FC<SkeletonProps> = ({ lines }) => {
  const {
    matchesXS,
    matchesSM,
    matchesMD,
    matchesLG,
    downXS,
    downLG,
    downXL,
    downSM,
  } = useResponsive();

  let gridItemsCount;
  if (downSM) {
    gridItemsCount = 12;
  } else if (downLG) {
    gridItemsCount = 6;
  } else if (downXL) {
    gridItemsCount = 4;
  } else {
    gridItemsCount = 3;
  }

  const gridItem = (
    <Grid xs={12}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignContent: "center",
          alignItems: "center",
          // marginX: "auto",
          flexDirection: "column",
          width: "100%",
        }}>
        <Box sx={{ px: 1, width: "100%" }}>
          <Skeleton
            variant="text"
            width="300px"
            sx={{ height: "30px", mt: 1 }}
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "start",
            alignItems: "start",
            flexDirection: "column",
            px: 1,
            mt: 3,
          }}>
          <Skeleton variant="text" sx={{ height: "25px", width: "60%" }} />
          <Skeleton variant="text" sx={{ height: "25px", width: "30%" }} />
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "start",
            alignItems: "start",
            flexDirection: "column",
            px: 1,
            mt: 3,
          }}>
          <Skeleton variant="text" sx={{ height: "25px", width: "60%" }} />
          <Skeleton variant="text" sx={{ height: "25px", width: "36%" }} />
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "start",
            alignItems: "start",
            flexDirection: "column",
            px: 1,
            mt: 3,
          }}>
          <Skeleton variant="text" sx={{ height: "25px", width: "60%" }} />
          <Skeleton variant="text" sx={{ height: "25px", width: "20%" }} />
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "start",
            alignItems: "start",
            flexDirection: "column",
            px: 1,
            mt: 3,
          }}>
          <Skeleton variant="text" sx={{ height: "25px", width: "60%" }} />
          <Skeleton variant="text" sx={{ height: "25px", width: "42%" }} />
        </Box>
      </Box>
      <Box
        sx={{
          mt: 3,
          width: "75%",
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
        }}>
        <Skeleton
          variant="rectangular"
          sx={{ height: "45px", width: "100%" }}
        />
      </Box>
    </Grid>
  );

  return (
    <Box
      sx={{
        width: "40vw",
        maxWidth: "600px",
        height: "auto",
        // maxWidth: "600px",
        display: { xs: "none", md: "block" },
      }}
      className="">
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          maxWidth: "600px",
          //   mt: 7,
          py: 20,
          px: 5,

          //   margin: "auto",
          //   padding: "20px 0px 20px 0px",
        }}>
        <Grid
          container
          sx={{
            // maxWidth: "1522px",
            width: "100%",
            overflow: "hidden",
          }}
          spacing={2}>
          {Array(lines)
            .fill(null)
            .map((_, index) => (
              <React.Fragment key={index}>{gridItem}</React.Fragment>
            ))}
        </Grid>
      </Box>
    </Box>
  );
};

export { SkeletonLoginPage };
