import { Box } from "@mui/material";
import { SocialPageList } from "components/static/socialpage/SocialPageList";

import React from "react";

const SocialBlock: React.FC = () => {
  return (
    <Box>
      <SocialPageList />
    </Box>
  );
};

export { SocialBlock };
