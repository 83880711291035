import React, { useEffect } from "react";
import { IPage } from "interfaces/page.interface";
import { Box } from "@mui/material";
import { useProduct } from "hooks/useProduct";
import { CommonTools } from "tools/utils/common.tool";
import { ProductShortInfo } from "components/product/product/page/ProductShortInfo";
import { DetailItem } from "components/product/product/page/DetailItem";
import { HtmlTag } from "components/elements/display/HtmlTag";
import { ProductBreadcrumb } from "components/product/product/page/ProductBreadcrumb";
import { ProductMedia } from "components/product/product/page/ProductMedia";
import { ButtonAddToCart } from "components/product/product/page/ButtonAddToCart";
import { ProductFilterList } from "components/product/productfilter/ProductFilterList";
import { useLabel } from "hooks/useLabel";
import { SkeletonProductPage } from "components/elements/skeleton/product/SkeletonProductPage";
import { SkeletonBreadCrumb } from "components/elements/skeleton/SkeletonBreadCrumbs";
import { Config } from "tools/utils/config";

const ProductPage: React.FC<IPage> = ({ currentRoute }) => {
  const {
    get,
    product,
    loadingProduct,
    resetState,
    getProductFilters,
    getOffers,
    getSelectedOffer,
  } = useProduct();
  const id = CommonTools.processIdFromPaths(currentRoute, 2);
  const { LL } = useLabel();
  useEffect(() => {
    return () => {
      resetState();
    };
  }, []);

  useEffect(() => {
    if (currentRoute?._mainobject !== "product") return;
    if (!id) return;
    get(id);
  }, [get, id]);

  useEffect(() => {
    getProductFilters();
  }, [getProductFilters]);

  useEffect(() => {
    getOffers();
  }, [getOffers]);

  useEffect(() => {
    getSelectedOffer();
  }, [getSelectedOffer]);

  if (!currentRoute) return null;
  if (!id) return null;
  if (loadingProduct)
    return (
      <Box>
        <SkeletonBreadCrumb />
        <SkeletonProductPage />
      </Box>
    );
  if (!product) return null;

  return (
    <Box
      sx={{
        mb: 10,
        height: "auto",
        maxWidth: "1920px",
        marginX: "auto",
        minHeight: `calc(100vh - ${Config.HEADER_HEIGHT}px - ${Config.FOOTER_HEIGHT}px + 118px)`,
  
      }}>
      <Box sx={{ width: "auto", px: { xs: 4, sm: 6, md: 10 }, py: 3 }}>
        <ProductBreadcrumb />
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "auto",
        }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "start",
            px: { xs: 4, sm: 6, md: 10 },
            gap: 5,
            flexDirection: { xs: "column", lg: "row" },
            height: "auto",
          }}>
          <Box
            sx={{
              maxWidth: { xs: "none", lg: "910px" },
              width: {
                xs: "100%",
                lg: "60%",
              },
            }}>
            <ProductMedia />
          </Box>
          <Box
            sx={{
              width: { xs: "100%", lg: "40%" },
              maxWidth: { md: "600px" },
            }}>
            <ProductShortInfo item={product} />
            <ProductFilterList />
            <Box sx={{ mt: 3 }}>
              <ButtonAddToCart object={product} />
            </Box>
            <Box sx={{ mt: 3 }}>
              <DetailItem identifier={LL("detail_product")}>
                <HtmlTag
                  content={CommonTools.processObjectField(product, [
                    "_description",
                  ])}
                />
              </DetailItem>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* <Grid container>
        <Grid item md={6}>
          <ProductMedia />
        </Grid>
        <Grid item md={6}>
          <ProductShortInfo item={product} />
          <ButtonAddToCart object={product} />
          <ProductFilterList />
          <DetailItem identifier={LL("detail_product")}>
            <HtmlTag
              content={CommonTools.processObjectField(product, [
                "_description",
              ])}
            />
          </DetailItem>
        </Grid>
      </Grid> */}
    </Box>
  );
};

export { ProductPage };
