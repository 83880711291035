import { Box, Typography } from "@mui/material";

import { useLabel } from "hooks/useLabel";
import React from "react";
import { MyIcon } from "../icon/MyIcon";
import { IconType } from "../icon/IconContext";

type NoResultProps = {
  identifier: string;
};

const NoResult: React.FC<NoResultProps> = ({ identifier }) => {
  const { LL } = useLabel();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        py: 20,
      }}>
      <Box sx={{ width: "300px", height: "300px" }}>
        <MyIcon
          type={IconType.NO_RESULT}
          width="100%"
          height="100%"
          colorFill="gray"
        />
      </Box>
      <Box sx={{ mt: 3 }}>
        <Typography variant="h6" textAlign="center">
          {LL("no_result_" + identifier)}
        </Typography>
        <Typography
          variant="h6"
          textAlign="center"
          sx={{ fontWeight: "500", color: "gray" }}>
          {LL("we_couldn't_find_anything")}
        </Typography>
      </Box>
    </Box>
  );
};

export { NoResult };
