import { Box, Typography } from "@mui/material";
import { PageDto } from "dto/static/page.dto";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";

type Props = {
  object: PageDto;
};

const PageShortDescription: React.FC<Props> = ({ object }) => {
  if (!object) return null;
  if (!CommonTools.processObjectField(object, ["_shortdescription"]))
    return null;
  return (
    <Box
      sx={{
        py: 10,
        px: { xs: 4, sm: 6, md: 10 },
        width: "auto",
        display: "flex",
        justifyContent: "center",
      }}>
      <Box
        sx={{
          width: "100%",
          maxWidth: "800px",
          display: "flex",
          justifyContentL: "center",
          flexDirection: "column",
          textAlign: "center",
        }}>
        <Box sx={{ mb: 3 }}>
          <Typography sx={{ fontSize: { xs: "16px", sm: "14px" } }}>
            {CommonTools.processObjectField(object, ["_shortdescription"])}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export { PageShortDescription };
