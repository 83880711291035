import React, { createContext, useContext } from "react";
import IProvider from "interfaces/provider.interface";
import { useUserAgent } from "react-ua";
import { Config } from "tools/utils/config";

type Props = {
  isBot: boolean;
};
export const BotContext = createContext<Props>({
  isBot: false,
});

export const BotProvider: React.FC<IProvider> = ({ children }) => {
  const ua = useUserAgent();
  const isBot = Config.BOT_DECLARATION.test(ua.ua);

  const value = {
    isBot,
  };

  return <BotContext.Provider value={value}>{children}</BotContext.Provider>;
};

export const useBot = () => useContext(BotContext);
