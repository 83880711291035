import { OrderRepository } from "repositories/sale/order.repository";
import { GeneralService } from "services/general.service";

export class OrderService extends GeneralService<OrderRepository> {
  constructor() {
    super(new OrderRepository());
  }

  checkout = async (cb?: any, cbparameters?: any, data?: any): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.checkout(this.handleGet, cbparameters, data);

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  getByUUID = async (uuid: string, cb?: any, cbparameters?: any): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.getByUUID(uuid, this.handleGet, cbparameters);

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };
}
