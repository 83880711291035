import React from "react";

import { NavigatorDto } from "dto/static/navigator.dto";
import { NavigatorType } from "tools/types/navigatortype";
import { MenuNoUrl } from "./MenuNoUrl";
import { SxProps } from "@mui/material";
import { MenuExternalUrl } from "./MenuExternalUrl";
import { MenuLocalUrl } from "./MenuLocalUrl";
import { MenuToPage } from "./MenuToPage";
import { MenuAnchor } from "./MenuAnchor";

type Props = {
  item: NavigatorDto;
  myStyle?: SxProps;
  linkStyle?: SxProps;
  className?: string;
};

const MenuItem: React.FC<Props> = ({ item, myStyle, linkStyle, className }) => {
  if (!item) return null;
  if (!item.navigatortype) return null;
  switch (item.navigatortype) {
    case NavigatorType.NO_URL: {
      return <MenuNoUrl item={item} myStyle={myStyle} className={className} />;
    }
    case NavigatorType.URL_TOPAGE: {
      return <MenuToPage item={item} myStyle={myStyle} className={className} />;
    }
    case NavigatorType.URL_LOCAL: {
      return (
        <MenuLocalUrl item={item} myStyle={myStyle} className={className} />
      );
    }
    case NavigatorType.URL_EXTERN: {
      return (
        <MenuExternalUrl
          item={item}
          myStyle={myStyle}
          linkStyle={linkStyle}
          className={className}
        />
      );
    }
    case NavigatorType.ANCHOR: {
      return (
        <MenuAnchor
          item={item}
          myStyle={myStyle}
          linkStyle={linkStyle}
          className={className}
        />
      );
    }
    default: {
      return null;
    }
  }
};

export { MenuItem };
