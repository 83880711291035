import { Box, Typography } from "@mui/material";
import { useCategoryProduct } from "hooks/useCategoryProduct";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";
import { Image } from "components/elements/display/Image";
import { VideoFile } from "components/elements/display/VideoFile";


const CategoryTopBlock: React.FC = () => {
  const { categoryProduct } = useCategoryProduct();
  const styleBlock = {
    width: "100%",
    height: "100%",
    boxSize: "content-box",
    overflow: "hidden",
  };

  const processImage = () => {
    if (!categoryProduct) return null;
    if (
      !CommonTools.processObjectField(categoryProduct, [
        "_galleryDefault",
        "cdnurl",
      ])
    )
      return null
    return (
      <Image
        url={CommonTools.processObjectField(categoryProduct, [
          "_galleryDefault",
          "cdnurl",
        ])}
        typeMeasure="percentage"
        sizeInPercentage={{ width: "105%", height: "105%" }}
        withLinK={false}
        style={{ margin: 0, padding: 0, objectFit: "cover" }}
        cutImageHeight={570}
        cutImageWidth={2500}
      />
    );
  };

  const processMedia = () => {
    if (!categoryProduct) return null;
    if (
      CommonTools.processObjectField(categoryProduct, [
        "_attachmentDefault",
        "cdnurl",
      ])
    ) {
      return (
        <VideoFile
          url={CommonTools.processObjectField(categoryProduct, [
            "_attachmentDefault",
            "cdnurl",
          ])}
          //   light={processImage() !== null ? processImage() : false}
          controls={false}
          width="100%"
          height="100%"
        />
      );
    } else {
      return processImage();
    }
  };

  if (!categoryProduct) return null;
  if (
    !CommonTools.processObjectField(categoryProduct, [
      "_attachmentDefault",
      "cdnurl",
    ]) &&
    !CommonTools.processObjectField(categoryProduct, [
      "_galleryDefault",
      "cdnurl",
    ])
  )
    return null;
  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
        height: { xs: "20vh", md: "40vh" },
        maxHeight: "570px",
      }}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "0",
          width: "100%",
          transform: "translateY(-55%)",
        }}>
        <Box className="addBlocks" sx={[styleBlock, { position: "relative" }]}>
          {processMedia()}
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, 0)",
            }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Typography
                sx={{
                  fontSize: { xs: "20px", sm: "30px" },
                  color: "white",
                  fontWeight: "bold",
                }}>
                {CommonTools.processObjectField(categoryProduct, ["_name"])}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export { CategoryTopBlock };
