import * as React from "react";
import { Box } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

interface SkeletonProps {
  lines?: number;
  width?: number;
}

const SkeletonBreadCrumb: React.FC<SkeletonProps> = ({ lines }) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        py: 3,
      }}>
      <Box sx={{ px: { xs: 4, sm: 6, md: 10 }, width: "100%" }}>
        <Skeleton
          variant="text"
          sx={{ width: { xs: "300px", md: "30%" }, fontSize: "18px" }}
        />
      </Box>
    </Box>
  );
};

export { SkeletonBreadCrumb };
