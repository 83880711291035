import * as React from "react";
import { Box, Divider } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Unstable_Grid2";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { inherits } from "util";
import { useResponsive } from "hooks/useResponsive";

interface SkeletonProps {
  lines?: number;
  width?: number;
}

const SkeletonCartItem: React.FC<SkeletonProps> = ({ lines }) => {
  const { downSM, downMD, downXL } = useResponsive();

  let gridItemsCount;
  if (downMD) {
    gridItemsCount = 12;
  } else if (downXL) {
    gridItemsCount = 6;
  } else {
    gridItemsCount = 4;
  }

  const gridItem = (
    <Grid xs={gridItemsCount}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          gap: 4,
        }}>
        <Box sx={{ height: "100px", width: "100px" }}>
          <Skeleton
            variant="rectangular"
            sx={{ height: "100px", width: "100px", borderRadius: 2 }}
          />
        </Box>
        <Box sx={{ width: "80%" }}>
          <Skeleton variant="text" sx={{ width: "100%", height: 24 }} />
          <Box
            sx={{
              mt: 1,
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}>
            <Skeleton variant="text" sx={{ width: 100, height: 24 }} />
            <Skeleton variant="text" sx={{ width: 100, height: 24 }} />
          </Box>
          <Box
            sx={{
              mt: 1,

              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}>
            <Skeleton variant="text" sx={{ width: 80, height: 24 }} />
            <Skeleton variant="text" sx={{ width: 120, height: 24 }} />
          </Box>
        </Box>
      </Box>
    </Grid>
  );

  return (
    <Box
      sx={{ width: "100%", height: "auto", py: { xs: 2, md: 8 } }}
      className="">
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}>
        <Grid
          container
          sx={{
            // maxWidth: "1522px",
            width: "100%",
            overflow: "hidden",
          }}
          spacing={6}>
          {Array(lines)
            .fill(null)
            .map((_, index) => (
              <React.Fragment key={index}>{gridItem}</React.Fragment>
            ))}
        </Grid>
      </Box>
    </Box>
  );
};

export { SkeletonCartItem };
