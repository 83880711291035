import { Box } from "@mui/material";
import { MyBreadcrumbs } from "components/elements/breadcrumb/MyBreadcrumbs";
import { CategoryProductDto } from "dto/product/categoryproduct.dto";
import { useCategoryProduct } from "hooks/useCategoryProduct";
import { useProduct } from "hooks/useProduct";
import React, { useEffect } from "react";
import { CommonTools } from "tools/utils/common.tool";

const ProductBreadcrumb: React.FC = () => {
  const { getBreadcrumbCategory, breadcrumbCategory } = useCategoryProduct();
  const { product } = useProduct();

  useEffect(() => {
    if (!product) return;
    getBreadcrumbCategory(
      CommonTools.processObjectField(product, ["categorycode"])
    );
  }, [product]);

  if (!product) return null;
  if (!breadcrumbCategory) return null;
  if (!breadcrumbCategory.length) return null;
  return (
    <Box>
      <MyBreadcrumbs
        objects={CategoryProductDto.prepareBreadcrumbs(breadcrumbCategory)}
      />
    </Box>
  );
};

export { ProductBreadcrumb };
