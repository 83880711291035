import { Box, Grid } from "@mui/material";
import { BlogList } from "components/static/blog/list/BlogList";
import { BlogPagination } from "components/static/blog/list/BlogPagination";
import { BlogCategoryList } from "components/static/blogcategory/BlogCategoryList";
import { useBlogCategory } from "hooks/useBlogCategory";

import { IPage } from "interfaces/page.interface";
import React, { useEffect } from "react";
import { CommonTools } from "tools/utils/common.tool";
import { useResponsive } from "hooks/useResponsive";
import { Config } from "tools/utils/config";

const BlogCategoryPage: React.FC<IPage> = ({ currentRoute }) => {
  let id = CommonTools.processIdFromPaths(currentRoute, 2)
    ? CommonTools.processIdFromPaths(currentRoute, 2)
    : CommonTools.processObjectField(currentRoute, ["processedData", "idobj"])
    ? CommonTools.processObjectField(currentRoute, ["processedData", "idobj"])
    : "";

  const { getCategoryList, getListBlog, getDefaultFilters, setIdBlogCategory } =
    useBlogCategory();

  const { matchesHeight } = useResponsive();

  useEffect(() => {
    getDefaultFilters(currentRoute);
  }, [getDefaultFilters]);

  useEffect(() => {
    setIdBlogCategory(id);
  }, [id]);

  useEffect(() => {
    getCategoryList();
  }, [getCategoryList]);

  useEffect(() => {
    if (!currentRoute) return;
    if (!currentRoute._mainobject) return;
    if (
      CommonTools.processObjectField(currentRoute, ["_mainobject"]) !==
      "categoryblog"
    ) {
      return;
    }
    getListBlog();
  }, [getListBlog]);

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "1920px",
        marginX: "auto",
        minHeight: `calc(100vh - ${Config.HEADER_HEIGHT}px - ${Config.FOOTER_HEIGHT}px )`,
      }}>
      <Box
        sx={{
          px: { xs: 4, sm: 6, md: 10 },
          py: 4,
          // background: "red",
          // height: matchesHeight ? "calc(100vh - 66px)" : "auto",

          display: "flex",
          justifyContent: "center",
          alignItems: "start",
          flexDirection: { xs: "column-reverse", md: "row" },
          gap: 4,
        }}>
        <Box sx={{ width: { xs: "100%", md: "70%" } }}>
          <BlogList />
        </Box>
        <Box sx={{ width: { xs: "100%", md: "30%" } }}>
          <BlogCategoryList />
        </Box>
      </Box>
      <Box
        sx={{
          px: { xs: 4, sm: 6, md: 10 },
          py: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "start",
        }}>
        <BlogPagination />
      </Box>
    </Box>
  );
};

export { BlogCategoryPage };
