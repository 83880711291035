import React from "react";

import { NavigatorDto } from "dto/static/navigator.dto";
import { Box, SxProps } from "@mui/material";
import { CommonTools } from "tools/utils/common.tool";
import { NavButton } from "components/elements/button/NavButton";

type Props = {
  item: NavigatorDto;
  myStyle?: SxProps;
  className?: string;
};

const MenuToPage: React.FC<Props> = ({ item, myStyle = {}, className }) => {
  if (!item) return null;

  return (
    <Box className={className}>
      <NavButton
        className={"textAnimation"}
        href={CommonTools.processObjectField(item, ["_fullurl"])}
        sx={myStyle}>
        {CommonTools.processObjectField(item, ["_name"])}
      </NavButton>
    </Box>
  );
};

export { MenuToPage };
