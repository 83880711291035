import { MediaDto } from "dto/system/media.dto";
import Idto from "interfaces/idto.interface";
import { Status } from "tools/types/status";

export class FilterDictionarDto extends MediaDto implements Idto {
  id?: number | string;
  identifier?: string;
  idfilter?: number;
  order?: number;

  status?: number;
  _idlang?: number | string;
  _name?: string;

  constructor(
    _idlang?: number | string,
    id?: number | string,
    idfilter?: number,
    identifier?: string,
    order?: number,
    status?: number,
    _name?: string
  ) {
    super();
    this.id = id || 0;
    this.identifier = identifier || "";
    this.order = order || 0;
    this.status = status || Status.ACTIVE;
    this._idlang = _idlang || 0;
    this._name = _name || "";
    this.idfilter = idfilter || 0;
  }
}
