import { PageDto } from "dto/static/page.dto";
import { CallbackType } from "interfaces/commontypes.interface";
import { PageService } from "services/static/page.service";
import useObject from "./useObject";
import { useMemo } from "react";

type UsePage = (
  id: number | string,
  useCache?: boolean,
  cacheIdentifier?: string,
  noMeta?: boolean
) => {
  object: PageDto | null;
  loading: boolean;
};

const service = new PageService();
export const usePage: UsePage = (
  id,
  useCache = false,
  cacheIdentifier = "",
  noMeta = true
) => {
  const get = (id: number | string, cb?: CallbackType, cbParams?: any) => {
    if (!id) return;
    service.get(id.toString(), cb, cbParams,undefined, noMeta);
  };

  const [loading, object] = useObject<PageDto>(
    get,
    id.toString(),
    [id],
    useCache,
    cacheIdentifier
  );

  return useMemo(
    () => ({
      object,
      loading,
    }),
    [object, loading]
  );
};
