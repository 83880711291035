import React from "react";
import { Box, Typography, Popover } from "@mui/material";

import { useLabel } from "hooks/useLabel";
import { MyButton } from "components/elements/button/MyButton";

import { SearchField } from "./SearchField";

type Props = {
  anchorEl: HTMLElement | null;
  open: boolean;
  handleClose: () => void;
};

const SearchPopper: React.FC<Props> = ({ anchorEl, open, handleClose }) => {
  const { LL } = useLabel();

  return (
    <Popover
      id="search-popover"
      open={open}
      aria-labelledby={"basic-button-search"}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}>
      <Box
        sx={{
          minWidth: 300,
          minHeight: 100,
          padding: 5,
        }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            alignItems: "center",
            py: 2,
            gap: 3,
          }}>
          <SearchField handleAction={handleClose} />
          <Box sx={{ mt: "20px" }}>
            <MyButton
              cb={handleClose}
              variant="text"
              disableRipple
              className="myButtonAnimation"
              width={"auto"}
              sx={{
                ml: 4,
                color: "black",
                height: "auto",
              }}>
              <Typography
                className="textAnimation"
                sx={{ textTransform: "uppercase" }}>
                {LL("bt_cancel")}
              </Typography>
            </MyButton>
          </Box>
        </Box>
      </Box>
    </Popover>
  );
};

export { SearchPopper };
