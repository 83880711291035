import { SystemService } from "services/system/system.service";
import useObject from "./useObject";

type Props = (suffix?: string) => void;
const service = new SystemService();
export const useSeoInfo: Props = (suffix = "") => {
  const get = (id: string, cb?: any, cbParams?: any) => {
    service.getSeoInfo(id, cb, cbParams);
  };
  useObject(get, suffix, []);
};
