import { Box } from "@mui/material";
import { IPage } from "interfaces/page.interface";
import React, { useLayoutEffect } from "react";
import { useEffect, useReducer, useRef } from "react";
import { Logo } from "components/general/Logo";
import { SearchBlock } from "components/header/SearchBlock";
import { CartBlock } from "components/header/CartBlock";
import { MenuBlock } from "components/header/MenuBlock";

import { CommonTools } from "tools/utils/common.tool";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { TopNavigatorLevel } from "components/static/menu/top/TopNavigatorLevel";
import { LanguageBlock } from "components/header/LanguageBlock";

type State = {
  bgColor: string;
  shadow: string;
  svgColor: string;
  headerHeight: number;
  alignItems: string;
  justifyContent: string;
  logoHeight: number;
  logoWidth: number;
  logoWidthMobile: number;

  translateX: number;
  translateY: number;
};

type Action =
  | { type: "SET_BG_COLOR"; payload: string }
  | { type: "SET_SHADOW"; payload: string }
  | { type: "SET_SVG_COLOR"; payload: string }
  | {
      type: "SET_COMMON_DATA";
      payload: {
        headerHeight: number;
        alignItems: string;
        justifyContent: string;
        logoHeight: number;
        logoWidth: number;
        logoWidthMobile: number;
        translateX: number;
        translateY: number;
      };
    };

const initialState: State = {
  bgColor: "transparent",
  shadow: "none",
  svgColor: "white",
  headerHeight: 400,
  alignItems: "start",
  justifyContent: "space-between",
  logoHeight: 300,
  logoWidth: 498,
  logoWidthMobile: 332,
  translateX: 0,
  translateY: 0,
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "SET_BG_COLOR":
      return { ...state, bgColor: action.payload };
    case "SET_SHADOW":
      return { ...state, shadow: action.payload };
    case "SET_SVG_COLOR":
      return { ...state, svgColor: action.payload };
    case "SET_COMMON_DATA": {
      return {
        ...state,
        headerHeight: action.payload.headerHeight,
        alignItems: action.payload.alignItems,
        justifyContent: action.payload.justifyContent,
        logoHeight: action.payload.logoHeight,
        logoWidth: action.payload.logoWidth,
        logoWidthMobile: action.payload.logoWidthMobile,
        translateX: action.payload.translateX,
        translateY: action.payload.translateY,
      };
    }
    default:
      return state;
  }
}

const Header: React.FC<IPage> = ({ currentRoute }) => {
  const isHomePage =
    CommonTools.processObjectField(currentRoute, ["_mainobject"]) ===
    "homepage";

  const isMD = useMediaQuery(useTheme().breakpoints.down("md"));
  const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"));

  const [state, dispatch] = useReducer(reducer, initialState);
  const bgColorRef = useRef(state.bgColor);

  useEffect(() => {
    bgColorRef.current = state.bgColor;
  }, [state.bgColor]);

  const onScroll = () => {
    const currentBgColor = bgColorRef.current;
    const shouldChangeToWhite =
      window.scrollY > 10 && currentBgColor !== "white";
    const shouldChangeToTransparent =
      window.scrollY <= 1 && currentBgColor !== "transparent";

    if (shouldChangeToWhite) {
      setTimeout(() => {
        dispatch({
          type: "SET_BG_COLOR",
          payload: "white",
        });
        dispatch({
          type: "SET_SHADOW",
          payload: "0px 4px 4px rgba(0, 0, 0, 0.1)",
        });
        dispatch({
          type: "SET_SVG_COLOR",
          payload: "black",
        });
      }, 500);
    } else if (shouldChangeToTransparent) {
      dispatch({
        type: "SET_BG_COLOR",
        payload: "transparent",
      });
      dispatch({
        type: "SET_SHADOW",
        payload: "none",
      });
      dispatch({
        type: "SET_SVG_COLOR",
        payload: "white",
      });
    }
    dispatch({
      type: "SET_COMMON_DATA",
      payload: {
        headerHeight: window.scrollY > 0 ? 110 : 400,
        alignItems: "center",
        justifyContent: "center",
        logoHeight: window.scrollY > 0 ? 100 : 300,
        logoWidth: window.scrollY > 0 ? 166 : 498,
        logoWidthMobile: window.scrollY > 0 ? 120 : 300,

        translateX: 0,
        translateY: 0,
      },
    });
  };

  useLayoutEffect(() => {
    if (!isHomePage) {
      dispatch({
        type: "SET_SVG_COLOR",
        payload: "black",
      });
    } else {
      window.addEventListener("scroll", onScroll);
      if (window.scrollY > 0 || state.headerHeight === 110) {
        dispatch({
          type: "SET_SVG_COLOR",
          payload: "black",
        });
      } else {
        dispatch({
          type: "SET_SVG_COLOR",
          payload: "white",
        });
      }
    }

    return () => {
      if (isHomePage) window.removeEventListener("scroll", onScroll);
    };
  }, [isHomePage, state.headerHeight]);

  const handleScrollTop = () => {
    if (isHomePage) window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Box
      sx={{
        width: "100%",
        // height: isHomePage ? state.headerHeight : "110px",
        // boxShadow: isHomePage ? state.shadow : "rgba(0, 0, 0, 0.1) 0px 4px 4px",
        height: "110px",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 4px",
        // position: "fixed",
        top: 0,
        zIndex: 100,
        backgroundColor: "#27272a",
        transition: "height 0.5s ease",
      }}>
      <Box
        sx={{
          // width: "auto",
          // background: "red",
          display: "flex",
          px: { xs: 2, sm: 4 },
          height: "100%",
          // transition: isHomePage ? "alignItems 0.5s ease" : "none",
        }}>
        <Box
          sx={{
            width: "auto",
            display: "flex",
            alignItems: "center",
            // position: "fixed",
            py: "41px",
            zIndex: 2,
            gap: 3,
          }}>
          <Box
            sx={{
              px: 0,
              py: 0,
              // pr: isMobile ? 6 : 0
            }}>
            <MenuBlock
              // colorSvg={isHomePage ? state.svgColor : "black"}
              colorSvg={"white"}
              isMobile={isMobile}
            />
          </Box>
          <Box
            sx={{
              px: 1,
              display: isMD ? "none" : "block",
            }}>
            <TopNavigatorLevel level={1} idParent="0" color={state.svgColor} />
          </Box>
        </Box>
        <Box
          sx={{
            // position: "absolute",
            // top: 0,
            // left: 0,
            display: "flex",
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: isHomePage
              ? "center"
              : isMobile
              ? "center"
              : "center",
            px: 0,
            transform:
              isHomePage && !isMobile
                ? `translate(${state.translateX}px, ${state.translateY}px)`
                : `translate(0, 0)`,
            transition: isHomePage ? "transform 0.5s ease" : "none",
            zIndex: 1,
          }}>
          <Logo
            // width={
            //   isHomePage && !isMobile
            //     ? state.logoWidth
            //     : isHomePage && isMobile
            //     ? state.logoWidthMobile
            //     : 166
            // }
            width={166}
            height={110}
            // height={isHomePage ? state.logoHeight : isMobile ? 100 : 110}
            style={{
              transition: isHomePage
                ? "width 0.3s ease, height 0.3s ease"
                : isMobile
                ? "none"
                : "none",
              zIndex: 10,
              // fill: state.svgColor,
              fill: "white",
            }}
            handleAction={isHomePage ? handleScrollTop : undefined}
          />
        </Box>

        <Box
          sx={{
            width: "auto",
            display: "flex",
            justifyContent: isMD ? "end" : "space-between",
            alignItems: "center",
            // position: "fixed",
            right: isMobile ? 20 : 40,
            py: "37px",
            zIndex: 1,
          }}>
          <Box
            sx={{
              px: 1,
              // pl: isMobile ? 6 : 0
            }}>
            <CartBlock
              // colorSvg={isHomePage ? state.svgColor : "black"}
              colorSvg={"white"}
            />
          </Box>
          <Box
            sx={{
              px: 1,
              display: isMD ? "none" : "block",
            }}>
            <SearchBlock // colorSvg={isHomePage ? state.svgColor : "black"}
              colorSvg={"white"}
            />
          </Box>
          <Box
            sx={{
              color: state.svgColor,
              display: isMD ? "none" : "block",
            }}>
            <LanguageBlock // colorSvg={isHomePage ? state.svgColor : "black"}
              colorSvg={"white"}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export { Header };
