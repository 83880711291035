import React, { useState } from "react";

import { Box, IconButton } from "@mui/material";
import { GalleryDto } from "dto/system/gallery.dto";

import { Image } from "components/elements/display/Image";
import { CommonTools } from "tools/utils/common.tool";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import { useConfig } from "hooks/useConfig";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
type Props = {
  objects: Array<GalleryDto>;
  carouselStyle?: any;
};

const GalleryCarousel: React.FC<Props> = ({ objects, carouselStyle }) => {
  const [nav1, setNav1] = useState<Slider | undefined>(undefined);
  const [nav2, setNav2] = useState<Slider | undefined>(undefined);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { CC } = useConfig();
  const processItem = (item: GalleryDto, index: number) => {
    if (!item) return null;

    return (
      <Box key={index}>
        <Image
          key={index}
          url={CommonTools.processObjectField(item, ["cdnurl"])}
          typeMeasure="percentage"
          // sizeInPercentage={{ width: "100%", height: "90%" }}
          withLinK={false}
          style={{
            objectFit: "cover",
            width: "100%",
          }}
        />
      </Box>
    );
  };

  const processItemSliderSeconde = (item: GalleryDto, index: number) => {
    if (!item) return null;

    return (
      <Box key={index}>
        <Image
          key={index}
          url={CommonTools.processObjectField(item, ["cdnurl"])}
          typeMeasure="percentage"
          // sizeInPercentage={{ width: "100%", height: "90%" }}
          withLinK={false}
          style={{
            objectFit: "cover",
            width: "100%",
          }}
        />
      </Box>
    );
  };

  const handleNext = () => {
    if (nav1) {
      nav1.slickNext();
    }
  };
  const handlePrev = () => {
    if (nav1) {
      nav1.slickPrev();
    }
  };

  const slidesToShow = CommonTools.calculateNumberOfElementsShowOnCarousel(
    parseInt(CC("display_on_product_page", "4")),
    objects.length
  );
  const display = slidesToShow >= 3;

  type CustomArrowProps = {
    type?: string;
    onClick?: () => void;
    isEdge?: boolean;
    carouselStyle?: any;
  };

  const CustomArrowLeft: React.FC<CustomArrowProps> = ({
    type,
    onClick,
    isEdge,
    carouselStyle,
  }) => {
    return (
      <Box
        className={
          carouselStyle === "primary" ? "slider-container" : "slider-container1"
        }>
        <IconButton
          onClick={onClick}
          className="icon-slider"
          sx={
            carouselStyle === "primary"
              ? {
                  position: "absolute",
                  display: { xs: "none", sm: "block" },
                  left: 5,
                  top: { md: "40%" },
                  zIndex: 1,
                }
              : { position: "absolute", right: 50, top: "95%", zIndex: 1 }
          }
          size="large">
          {carouselStyle === "primary" ? (
            <KeyboardArrowLeftIcon style={{ fontSize: 50 }} />
          ) : (
            <KeyboardBackspaceIcon style={{ fontSize: 20 }} />
          )}
        </IconButton>
      </Box>
    );
  };

  const CustomArrowRight: React.FC<CustomArrowProps> = ({
    type,
    onClick,
    isEdge,
    carouselStyle,
  }) => {
    return (
      <Box
        className={
          carouselStyle === "primary" ? "slider-container" : "slider-container1"
        }>
        <IconButton
          onClick={onClick}
          className="icon-slider"
          sx={
            carouselStyle === "primary"
              ? {
                  position: "absolute",
                  display: { xs: "none", sm: "block" },
                  right: 5,
                  top: { md: "40%" },
                  zIndex: 1,
                }
              : { position: "absolute", right: 10, top: "95%", zIndex: 1 }
          }
          size="large">
          {carouselStyle === "primary" ? (
            <KeyboardArrowRightIcon style={{ fontSize: 50 }} />
          ) : (
            <KeyboardBackspaceIcon
              style={{ fontSize: 20, transform: "rotate(180deg)" }}
            />
          )}
        </IconButton>
      </Box>
    );
  };

  return (
    <Box>
      <Slider
        slidesToShow={1}
        slidesToScroll={1}
        asNavFor={nav2}
        ref={(slider1) => setNav1(slider1 ?? undefined)}
        beforeChange={(oldIndex, newIndex) => setCurrentIndex(newIndex)}
        infinite={false}
        arrows={false}>
        {objects.map((item, index) => processItem(item, index))}
      </Slider>
      {display ? (
        <Slider
          asNavFor={nav1}
          ref={(slider2) => setNav2(slider2 ?? undefined)}
          slidesToShow={slidesToShow}
          focusOnSelect={true}
          nextArrow={<CustomArrowRight carouselStyle={carouselStyle} />}
          prevArrow={<CustomArrowLeft carouselStyle={carouselStyle} />}>
          {objects.map((item, index) => processItemSliderSeconde(item, index))}
        </Slider>
      ) : null}
    </Box>
  );
};

export { GalleryCarousel };
