import { Box, Typography } from "@mui/material";
import { useSearch } from "hooks/useSearch";
import React from "react";

const TopBlockSearch: React.FC = () => {
  const { search, totalProducts } = useSearch();
  return (
    <Box>
      {search && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "3px",
          }}>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "20px",
              textTransform: "uppercase",
            }}>{`"${search}"`}</Typography>
          <Typography
            sx={{ fontSize: "20px" }}>{`(${totalProducts})`}</Typography>
        </Box>
      )}
    </Box>
  );
};

export { TopBlockSearch };
