import React from "react";

import { AdvertisementDto } from "dto/static/advertisement.dto";
import { Box, Typography } from "@mui/material";

import { CommonTools } from "tools/utils/common.tool";
import { Image } from "components/elements/display/Image";
// import AOS from "aos";

type Props = {
  item: AdvertisementDto;
  index: number;
};

const AdvertisementItemBenefits: React.FC<Props> = ({ item }) => {
  if (!item) return null;
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}>
        <Image
          url={CommonTools.processObjectField(item, ["_filecdnurl"])}
          sizeInPx={{
            width: 150,
            height: 150,
          }}
          typeMeasure="pixel"
          withLinK={false}
        />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Typography
          sx={{
            width: "300px",
            textAlign: "center",
            mt: 3,
            fontSize: { xs: "20px", sm: "24px" },
            color: "black",
            fontWeight: "bold",
          }}>
          {CommonTools.processObjectField(item, ["_name"])}
        </Typography>
      </Box>
    </Box>
  );
};

export { AdvertisementItemBenefits };
