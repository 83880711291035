import React, { createContext, useEffect, useState } from "react";
import IProvider from "interfaces/provider.interface";
import { CookieInfoDialog } from "components/cookie/CookieInfoDialog";
import { useBot } from "./BotProvider";
import { LocalStorageTools } from "api/localstorage.api";

type Props = {};
export const CookieUsageContext = createContext<Props>({});

export const CookieUsageProvider: React.FC<IProvider> = ({ children }) => {
  const { isBot } = useBot();
  const processDefault = () => {
    if (isBot) return false;
    if (processLocal()) return false;
    return true;
  };
  const [open, setOpen] = useState<boolean>(processDefault());

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (isBot) return;
    if (processLocal()) return;
    LocalStorageTools.saveValue("cookie_usage", "true");
  }, []);

  return (
    <CookieUsageContext.Provider value={{}}>
      {children}
      <CookieInfoDialog open={open} handleClose={handleClose} />
    </CookieUsageContext.Provider>
  );
};

const processLocal = () => {
  const cookieUsage = LocalStorageTools.getValue("cookie_usage");
  if (!cookieUsage) return false;
  return true;
};
