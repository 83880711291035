import React from "react";
import { IPage } from "interfaces/page.interface";
import { Box, Divider, Skeleton, Typography } from "@mui/material";
import { useLabel } from "hooks/useLabel";
import { OrderService } from "services/sale/order.service";
import useObject from "hooks/useObject";
import { CommonTools } from "tools/utils/common.tool";

import { ClientInfo } from "components/sale/order/ClientInfo";
import { PaymentInfo } from "components/sale/order/PaymentInfo";
import { DeliveryInfo } from "components/sale/order/DeliveryInfo";
import { OrderInfo } from "components/sale/order/OrderInfo";
import { OrderProductList } from "components/sale/orderproduct/OrderProductList";
import { PayAction } from "components/sale/order/PayAction";
import { Config } from "tools/utils/config";
import { SkeletonOrderInfo } from "components/elements/skeleton/SkeletonOrderInfo";

const service = new OrderService();
const OrderInfoPage: React.FC<IPage> = ({ currentRoute }) => {
  const id = CommonTools.processIdFromPaths(currentRoute, 1);

  const { LL } = useLabel();

  const getObject = (uuid: string, cb?: any, cbParams?: any) => {
    if (currentRoute?._mainobject !== "orderinfo") return;
    if (!uuid) return;
    service.getByUUID(uuid, cb, cbParams);
  };

  const [loading, object] = useObject(getObject, id, [id]);

  if (loading) return <SkeletonOrderInfo lines={3} />;
  if (!object) return null;

  return (
    <Box
      sx={{
        px: { xs: 4, sm: 4, md: 10 },
        height: "100%",
        maxWidth: "1920px",
        marginX: "auto",
        minHeight: `calc(100vh - ${Config.HEADER_HEIGHT}px - ${Config.FOOTER_HEIGHT}px)`,
      }}>
      <Box
        sx={{
          pt: 3,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Typography sx={{ fontSize: "24px", fontWeight: "bold" }}>
          {LL("CartOrderInfo")}
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "start",
          flexDirection: { xs: "column", md: "row" },
          gap: 3,
        }}>
        <Box
          mt={3}
          sx={{
            width: { xs: "100%", md: "50%" },
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}>
          <OrderInfo object={object} />
          <ClientInfo object={object} />
          <DeliveryInfo object={object} />
        </Box>
        <Box mt={3} sx={{ width: { xs: "100%", md: "50%" } }}>
          <PaymentInfo object={object} />
        </Box>
      </Box>
      <Box sx={{ px: { xs: 0, md: 10 }, pt: 3 }}>
        <Divider
          sx={{
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            width: "auto",
            px: 10,
          }}
        />
      </Box>
      <Box
        sx={{
          pt: 3,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Typography sx={{ fontSize: "24px", fontWeight: "bold" }}>
          {LL("CartOrderProducts")}
        </Typography>
      </Box>
      <Box>
        <OrderProductList
          idOrder={CommonTools.processObjectField(object, ["id"])}
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: { xs: "center", md: "flex-end" },
          py: 3,
        }}>
        <PayAction object={object} />
      </Box>
    </Box>
  );
};

export { OrderInfoPage };
