import { Box, Typography } from "@mui/material";
import { ButtonWithLoading } from "components/elements/button/ButtonWithLoading";
import { MyTextField } from "components/elements/form/MyTextField";
import { SignupDto } from "dto/user/user.dto";
import { useForm } from "hooks/useForm";
import { useLabel } from "hooks/useLabel";
import { CallbackType } from "interfaces/commontypes.interface";

import React, { useState } from "react";
import RequiredValidator from "validators/required.validator";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { PasswordField } from "components/elements/form/PasswordField";

type Props = {
  onSubmit: (obj: SignupDto, cb: CallbackType) => void;
};
const SignUpForm: React.FC<Props> = ({ onSubmit }) => {
  const { LL } = useLabel();
  const [loading, setLoading] = useState(false);
  const [obj, disabled, setObjField] = useForm<SignupDto>(
    new SignupDto(),
    RequiredValidator.getValidators(["email", "password"])
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    onSubmit(obj, cb);
  };

  const cb = () => {
    setLoading(false);
  };

  return (
    <Box sx={{ width: "100%", maxWidth: "400px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
        }}>
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: "bold",
            textTransform: "uppercase",
          }}>
          {LL("signup_title")}
        </Typography>
      </Box>
      <form onSubmit={handleSubmit}>
        <Box mt={3} sx={{ width: "100%" }}>
          <MyTextField
            field="email"
            label={LL("field_email")}
            setObjectField={setObjField}
            value={obj.email}
            sx={{ width: "100%" }}
            selectTheme={"standardText"}
          />
        </Box>
        <Box mt={3} sx={{ width: "100%" }}>
          <PasswordField
            field="password"
            label={LL("field_password")}
            setObjectField={setObjField}
            value={obj.password}
            sx={{ width: "100%" }}
            selectTheme={"standardText"}
          />
        </Box>
        <Box
          mt={3}
          sx={{ width: "100%", display: "flex", justifyContent: "end" }}>
          <Box
            sx={{ width: { xs: "100%", md: "200px" }, height: "45px" }}
            className={`whiteButton listItemButton ${
              disabled ? "disabled" : ""
            } ${loading ? "loadingBtn" : ""}
            `}>
            <ButtonWithLoading
              type="submit"
              disabled={loading || disabled}
              loading={loading}
              sx={{
                height: "100%",
                background: "red",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                width: "100%",
              }}>
              <Typography sx={{ mt: "4px", ml: 3 }}>
                {LL("btn_signup")}
              </Typography>
              <Box
                sx={{
                  width: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                {!disabled && <KeyboardArrowRightIcon className="iconSvg3" />}
              </Box>
            </ButtonWithLoading>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export { SignUpForm };
