import { Box, List, ListItemButton, Skeleton, Typography } from "@mui/material";
import { CategoryBlogDto, GroupedCategory } from "dto/static/categoryblog.dto";
import { useBlogCategory } from "hooks/useBlogCategory";
import React, { useCallback, useEffect, useState } from "react";

import { BlogCategoryMainItem } from "./BlogCategoryMainItem";
import { useLabel } from "hooks/useLabel";
import { SkeletonCategoryList } from "components/elements/skeleton/blog/SkeletonCategoryList";
const BlogCategoryList: React.FC = () => {
  const { categoryBlogObjects, selectCategory, categoryId } = useBlogCategory();
  const { LL } = useLabel();

  const [loading, setLoading] = useState<boolean>(true);

  const checkLoading = useCallback(() => {
    let loading = false;
    if (!categoryBlogObjects) loading = true;
    setLoading(loading);
  }, [categoryBlogObjects]);

  useEffect(() => {
    checkLoading();
  }, [checkLoading]);

  const processItem = (item: GroupedCategory, index: number) => {
    if (!item) return null;
    return <BlogCategoryMainItem item={item} key={index} />;
  };

  const handleClick = () => {
    selectCategory(null);
  };
  if (loading) return <SkeletonCategoryList />;
  if (!categoryBlogObjects) return null;
  if (!categoryBlogObjects.length) return null;
  const objects = CategoryBlogDto.groupByParent(categoryBlogObjects);
  return (
    <Box
      sx={{
        boxShadow: 1,
        borderRadius: 1,
        padding: 2,
        backgroundColor: "background.paper",
        minHeight: "369px",
      }}>
      <List>
        <ListItemButton
          key={"all"}
          onClick={handleClick}
          selected={!categoryId}>
          <Typography>{LL("all")}</Typography>
        </ListItemButton>
        {objects.map((item: GroupedCategory, index: number) =>
          processItem(item, index)
        )}
      </List>
    </Box>
  );
};

export { BlogCategoryList };
