import SelectOptions from "dto/app/selectoptions.dto";

export class AdvertisementType {
  public static _LL: any = false;
  public static setLL(_l: any): any {
    this._LL = _l;
  }

  public static IMAGE_SIMPLE = 1;
  public static IMAGE_CTA = 2;
  public static IMAGE_DESCRIPTION = 3;
  public static VIDEO = 100;

  public static _constants: { [key: number]: string } = {
    1: "IMAGE_SIMPLE",
    2: "IMAGE_CTA",
    3: "IMAGE_DESCRIPTION",
    100: "VIDEO",
  };

  // TODO: Implement this
  public static LL = (key: any) => {
    const k: number = parseInt(key);
    if (!this._constants.hasOwnProperty(k)) return "";
    return this._LL("AdvertisementType_" + this._constants[k].toString());
  };

  public static GA_KEYS = (key: string): Array<number> => {
    let rez: Array<number> = [];

    if (key === "general") {
      rez = [
        this.IMAGE_SIMPLE,
        this.IMAGE_CTA,
        this.IMAGE_DESCRIPTION,
        this.VIDEO,
      ];
    }

    return rez;
  };

  public static GA = (
    key: string,
    emptyValue?: boolean,
    emptyLabel?: string
  ) => {
    const keys = this.GA_KEYS(key);
    const rez: Array<SelectOptions> = [];
    if (emptyValue) {
      const empty = new SelectOptions();
      empty.value = "";
      empty.label = emptyLabel ?? AdvertisementType._LL("all");
      rez.push(empty);
    }
    keys.forEach((k) => {
      const so = new SelectOptions();
      so.value = k;
      so.label = AdvertisementType.LL(k);
      rez.push(so);
    });

    return rez;
  };
}
