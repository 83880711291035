import React, { useState } from "react";

import { FilterDictionarDto } from "dto/product/filterdictionar.dto";

import { Box, FormControlLabel, Radio, Typography } from "@mui/material";
import { CommonTools } from "tools/utils/common.tool";
import { Button } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import { Image } from "components/elements/display/Image";
import { logger } from "tools/utils/logger";

type Props = {
  item: FilterDictionarDto;
  selected: boolean;
  itemsLength?: number;

  onSelected: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const ProductFilterDictionarItem: React.FC<Props> = ({
  item,
  selected,
  onSelected,
  itemsLength,
}) => {
  if (!item) return null;
  const id = CommonTools.processObjectField(item, ["id"]);
  const processGallery = () => {
    if (!item) return null;
    if (!item._galleryDefault) return null;
    return (
      <Box
        sx={{
          width: "auto",
          height: "auto",
          mt: "4px",
          // ml: "-4px",
          mr: "4px",
        }}>
        <Image
          url={CommonTools.processObjectField(item, [
            "_galleryDefault",
            "cdnurl",
          ])}
          withLinK={false}
          style={{ borderRadius: "50%" }}
          sizeInPx={{
            width: 15,
            height: 15,
          }}
        />
      </Box>
    );
  };

  if (!id) return null;
  if (itemsLength === 1) {
    return (
      <Box sx={{ mt: 2, ml: 2, display: "flex", justifyContent: "center" }}>
        {processGallery && <Box>{processGallery()}</Box>}
        <Typography
          sx={{ fontSize: "16px", fontWeight: "500", width: "auto", px: 0 }}>
          {CommonTools.processObjectField(item, ["_name"])}
        </Typography>
      </Box>
    );
  }

  return (
    // <FormControlLabel
    //   value={CommonTools.processObjectField(item, ["id"])}
    //   control={<Radio />}
    //   label={CommonTools.processObjectField(item, ["_name"])}
    // />

    <Box className={selected ? "optionsButtonSelected " : "optionsButton "}>
      <MyButton
        variant={selected ? "contained" : "outlined"}
        value={id}
        onClick={(event) => onSelected(event)}
        sx={{
          width: "120px",
          minHeight: "40px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "5px",
          mx: 1,
        }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          {processGallery && <Box>{processGallery()}</Box>}
          <Typography
            sx={{ fontSize: "12px", fontWeight: "600", width: "auto", px: 0 }}>
            {CommonTools.processObjectField(item, ["_name"])}
          </Typography>
        </Box>
      </MyButton>
    </Box>
  );
};

export { ProductFilterDictionarItem };
