import React, { useState } from "react";
import { MyTextField, Props } from "./MyTextField";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { IconButton, InputAdornment } from "@mui/material";
const PasswordField: React.FC<Props> = ({ ...props }) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const icon = showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />;

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  
  return (
    <MyTextField
      type={showPassword ? "text" : "password"}
      {...props}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {icon}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export { PasswordField };
