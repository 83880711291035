import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { OfferDto } from "dto/product/offer.dto";
import { ProductDto } from "dto/product/product.dto";
import {
  ProductFilterDto,
  SelectedProductFilterDto,
} from "dto/product/productfilter.dto";
import { createDataContext } from "hoc/createDataContext";
import { OfferService } from "services/product/offer.service";

import { ProductService } from "services/product/product.service";
import { ProductFilterService } from "services/product/productfilter.service";
import { Status } from "tools/types/status";
import { CommonTools } from "tools/utils/common.tool";

export type StateResource = {
  product: ProductDto | null;
  loadingProduct: boolean;
  productFilterObjects: ProductFilterDto[] | null;
  idProduct: string;
  offerObjects: OfferDto[] | null;
  selectedOffer: OfferDto | null;
  quantity: number | string;
  selectedFilters: SelectedProductFilterDto[];
  loadingProductFilter: boolean;
  loadingOffer: boolean;
};

export type Actions = {
  setProduct: (product: ProductDto | null) => void;
  setLoadingProduct: (loading: boolean) => void;
  getProduct: (id: string) => void;
  resetState: () => void;
  setProductFilterObjects: (objects: ProductFilterDto[] | null) => void;
  getFilterProductObjects: (id: string) => void;
  setOfferObjects: (objects: OfferDto[] | null) => void;
  getOfferObjects: (id: string) => void;
  setQuantity: (quantity: number | string) => void;
  selectFilter: (filter: SelectedProductFilterDto) => void;
  setSelectedOffer: (
    offers: OfferDto[] | null,
    quantity: number | string,
    filters: SelectedProductFilterDto[]
  ) => void;
};

const service = new ProductService();
const serviceProductFilter = new ProductFilterService();
const serviceOffer = new OfferService();

const SET_PRODUCT = "set_product";
const SET_LOADING_PRODUCT = "set_loading_product";
const RESET_STATE = "reset_state";
const SET_PRODUCT_FILTER_OBJECTS = "set_product_filter_objects";
const SET_OFFER_OBJECTS = "set_offer_objects";
const SET_QUANTITY = "set_quantity";
const SELECT_FILTER = "select_filter";
const SET_SELECTED_OFFER = "set_selected_offer";
const SET_LOADING_PRODUCT_FILTER = "set_loading_product_filter";
const SET_LOADING_OFFER = "set_loading_offer";

const resourceReducer = (state: StateResource, action: any) => {
  switch (action.type) {
    case SET_PRODUCT:
      return {
        ...state,
        product: action.payload,
        loadingProduct: false,
        idProduct: CommonTools.processObjectField(action, ["payload", "id"]),
      };
    case SET_LOADING_PRODUCT:
      return { ...state, loadingProduct: action.payload };
    case SET_PRODUCT_FILTER_OBJECTS: {
      let selectedFilters: SelectedProductFilterDto[] = [];
      if (action.payload) {
        const group = ProductFilterDto.groupByFilterId(action.payload);
        if (group.displayFilterDictinonar.length) {
          const product = state.product;
          selectedFilters = SelectedProductFilterDto.prepareDefaultFilters(
            group.displayFilterDictinonar,
            product
          );
        }
      }
      return {
        ...state,
        productFilterObjects: action.payload,
        selectedFilters,
        loadingProductFilter: false,
      };
    }
    case SET_OFFER_OBJECTS:
      return { ...state, offerObjects: action.payload, loadingOffer: false };
    case SET_QUANTITY: {
      return { ...state, quantity: action.payload };
    }
    case SELECT_FILTER: {
      const currentFilters = state.selectedFilters;
      const index = currentFilters.findIndex(
        (item) => item.idfilter === action.payload.idfilter
      );
      if (index > -1) {
        currentFilters[index] = action.payload;
      } else {
        currentFilters.push(action.payload);
      }
      const newFilters = [...currentFilters];
      return { ...state, selectedFilters: newFilters };
    }
    case SET_SELECTED_OFFER:
      return { ...state, selectedOffer: action.payload };
    case SET_LOADING_PRODUCT_FILTER:
      return { ...state, loadingProductFilter: true };
    case SET_LOADING_OFFER:
      return { ...state, loadingOffer: true };
    case RESET_STATE:
      return {
        product: null,
        loadingProduct: true,
        productFilterObjects: null,
        idProduct: "",
        offerObjects: null,
        selectedOffer: null,
        quantity: 1,
        selectedFilters: [],
        loadingProductFilter: true,
        loadingOffer: true,
      };

    default:
      return state;
  }
};

const setSelectedOffer =
  (dispatch: any) =>
  (
    offers: OfferDto[] | null,
    quantity: number | string,
    filters: SelectedProductFilterDto[]
  ) => {
    if (!offers) return;
    if (!offers.length) return;
    if (!quantity) quantity = 1;
    if (typeof quantity === "string") quantity = parseInt(quantity);
    if (!filters) filters = [];
    if (!Array.isArray(filters)) filters = [];
    if (!filters.length) filters = [];

    dispatch({
      type: SET_SELECTED_OFFER,
      payload: OfferDto.getOfferByQuantityAndFilters(quantity, filters, offers),
    });
  };

const selectFilter = (dispatch: any) => (filter: SelectedProductFilterDto) => {
  dispatch({ type: SELECT_FILTER, payload: filter });
};

const setQuantity = (dispatch: any) => (quantity: number | string) => {
  dispatch({ type: SET_QUANTITY, payload: quantity });
};

const setOfferObjects = (dispatch: any) => (objects: OfferDto[] | null) => {
  dispatch({ type: SET_OFFER_OBJECTS, payload: objects });
};

const setProductFilterObjects =
  (dispatch: any) => (objects: ProductFilterDto[] | null) => {
    dispatch({ type: SET_PRODUCT_FILTER_OBJECTS, payload: objects });
  };

const resetState = (dispatch: any) => () => {
  dispatch({ type: RESET_STATE });
};

const setProduct = (dispatch: any) => (product: ProductDto | null) => {
  dispatch({ type: SET_PRODUCT, payload: product });
};

const setLoadingProduct = (dispatch: any) => (loading: boolean) => {
  dispatch({ type: SET_LOADING_PRODUCT, payload: loading });
};
const getProduct = (dispatch: any) => (id: string) => {
  if (!id) return;
  setLoadingProduct(dispatch)(true);
  service.get(id, handleProduct, { dispatch });
};

const handleProduct =
  (dispatch: any) => (result: ResultObjectDTO<ProductDto>) => {
    if (!result) return;
    if (result.error) return;
    const object = result.obj ? result.obj : null;
    setProduct(dispatch)(object);
  };

const getFilterProductObjects = (dispatch: any) => (id: string) => {
  if (!id) return;
  const req = new RequestListDTO();
  req.page = 1;
  req.onpage = -1;
  req.filters = [RequestFilterDTO.prepareFilter("idproduct", [id])];
  dispatch({ type: SET_LOADING_PRODUCT_FILTER });
  serviceProductFilter.getList(handleFilterProductObjects, { dispatch }, req);
};

const handleFilterProductObjects =
  (dispatch: any) => (result: ResultListDTO<ProductFilterDto>) => {
    if (!result) return;
    if (result.error) return;
    const objects = result.objects ? result.objects : null;
    setProductFilterObjects(dispatch)(objects);
  };

const getOfferObjects = (dispatch: any) => (id: string) => {
  if (!id) return;
  const req = new RequestListDTO();
  req.page = 1;
  req.onpage = -1;
  req.filters = [
    RequestFilterDTO.prepareFilter("idproduct", [id]),
    RequestFilterDTO.prepareFilter("cp", ["1"]),
    RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()]),
  ];
  dispatch({ type: SET_LOADING_OFFER });
  serviceOffer.getList(handleOfferObjects, { dispatch }, req);
};
const handleOfferObjects =
  (dispatch: any) => (result: ResultListDTO<OfferDto>) => {
    if (!result) return;
    if (result.error) return;
    const objects = result.objects ? result.objects : null;
    setOfferObjects(dispatch)(objects);
  };
export const { Provider, Context } = createDataContext<StateResource, Actions>(
  resourceReducer,
  {
    setProduct,
    getProduct,
    setLoadingProduct,
    resetState,
    setProductFilterObjects,
    getFilterProductObjects,
    setOfferObjects,
    getOfferObjects,
    setQuantity,
    selectFilter,
    setSelectedOffer,
  },
  {
    product: null,
    loadingProduct: true,
    productFilterObjects: null,
    idProduct: "",
    offerObjects: null,
    selectedOffer: null,
    quantity: 1,
    selectedFilters: [],
    loadingProductFilter: true,
    loadingOffer: true,
  }
);
