import { Box, Stack, Typography } from "@mui/material";
import { InputQuantity } from "components/elements/form/InputQuantity";
import { useCountry } from "hooks/useCountry";
import { useLabel } from "hooks/useLabel";
import { useProduct } from "hooks/useProduct";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";

const OfferBlock: React.FC = () => {
  const { quantity, setQuantity, selectedOffer } = useProduct();
  const { LL } = useLabel();
  const { currencyRate, currencyCode } = useCountry();

  const processPrice = () => {
    if (!selectedOffer) return LL("out_of_stock");
    const price = CommonTools.processObjectField(selectedOffer, ["real_price"]);
    if (!price) return LL("out_of_stock");
    const finalPrice =
      parseFloat(price) * parseInt(quantity.toString()) * currencyRate;
    if (isNaN(finalPrice) || finalPrice === 0) return LL("out_of_stock");
    return `${finalPrice.toFixed(2)} ${currencyCode}`;
  };
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "start",
        alignItems: "start",
        gap: 2,
        flexDirection: "column",
      }}>
      <Box>
        <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
          {processPrice()}
        </Typography>
      </Box>
      <Box sx={{ width: "150px" }}>
        <InputQuantity value={quantity} setValue={setQuantity} />
      </Box>
    </Box>
  );
};

export { OfferBlock };
