import { Box, Divider } from "@mui/material";
import { NoResult } from "components/elements/display/NoResult";
import { OrderDto } from "dto/sale/order.dto";
import React from "react";
import { UserOrderItem } from "./UserOrderItem";

type Props = {
  objects: OrderDto[];
};

const UserOrderList: React.FC<Props> = ({ objects }) => {
  const processItem = (object: OrderDto, index: number) => {
    if (!object) return null;
    return (
      <Box sx={{ py: 1 }} key={index}>
        <UserOrderItem object={object} />
        <Divider sx={{ py: 1 }} />
      </Box>
    );
  };
  if (!objects) return null;
  if (!objects.length) return <NoResult identifier="my_order" />;
  return (
    <Box sx={{}}>
      {objects.map((object, index) => processItem(object, index))}
    </Box>
  );
};

export { UserOrderList };
