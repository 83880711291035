import { Box } from "@mui/material";
import React from "react";
import { ReactComponent as LogoSvg } from "../../assets/images/newLOGO.svg";
import { NavButton } from "components/elements/button/NavButton";

type Props = {
  width?: string | number;
  height?: string | number;
  style?: React.CSSProperties;
  withLink?: boolean;
  handleAction?: () => void;
};

const Logo: React.FC<Props> = ({
  width = 166,
  height = 100,
  style,
  withLink = true,
  handleAction,
}) => {
  const onClick = (e: any) => {
    if (handleAction) {
      e.preventDefault();
      e.stopPropagation();
      handleAction();
    }
  };
  const logo = (
    <LogoSvg width={width} height={height} style={style} onClick={onClick} />
  );
  return (
    <Box>{withLink ? <NavButton href={"/"}>{logo}</NavButton> : logo}</Box>
  );
};

export { Logo };
