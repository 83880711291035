import { Box, Typography } from "@mui/material";
import { OrderDto } from "dto/sale/order.dto";
import { useLabel } from "hooks/useLabel";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";

type Props = {
  object: OrderDto;
};

const ClientInfo: React.FC<Props> = ({ object }) => {
  const { LL } = useLabel();
  if (!object) return null;
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
        <Typography>{LL("client_name")}</Typography>
        <Typography sx={{ fontWeight: "bold" }}>
          {CommonTools.processObjectField(object, ["client_name"])}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
        <Typography>{LL("client_mobil")}</Typography>
        <Typography sx={{ fontWeight: "bold" }}>
          {CommonTools.processObjectField(object, ["client_mobil"])}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
        <Typography>{LL("client_email")}</Typography>
        <Typography sx={{ fontWeight: "bold" }}>
          {CommonTools.processObjectField(object, ["client_email"])}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
        <Typography>{LL("client_othercontacts")}</Typography>
        <Typography sx={{ fontWeight: "bold" }}>
          {CommonTools.processObjectField(object, ["client_othercontacts"])}
        </Typography>
      </Box>
    </Box>
  );
};

export { ClientInfo };
