import { ReactComponent as CartIcon } from "assets/images/CartIcon.svg";
import { ReactComponent as UserIcon } from "assets/images/UserIcon.svg";
import { ReactComponent as SearchIcon } from "assets/images/searchIcon.svg";
import { ReactComponent as WishIcon } from "assets/images/WishIcon.svg";
import { ReactComponent as MenuIcon } from "assets/images/MenuIcon.svg";
import { ReactComponent as LeftArrowIcon } from "assets/images/left-arrow.svg";
import { ReactComponent as FilterSvg } from "assets/images/filterSvg.svg";
import { ReactComponent as CartSecondIcon } from "assets/images/cartSecond.svg";
import { ReactComponent as OrderHistoryIcon } from "assets/images/orderHistory.svg";
import { ReactComponent as WishSecondIcon } from "assets/images/wishSecond.svg";
import { ReactComponent as FileIcon } from "assets/images/fileIcon.svg";
import { ReactComponent as AscIcon } from "assets/images/ascIcon.svg";
import { ReactComponent as DescIcon } from "assets/images/descIcon.svg";
import { ReactComponent as NoResult } from "assets/images/noResult.svg";
import { ReactComponent as WhyUs } from "assets/images/whyUs.svg";
import { ReactComponent as NoImage } from "assets/images/noImage.svg";

type Props = {
  type: string;
  colorSvg?: string;
  colorFill?: string;
  width?: string;
  height?: string;
};
export const IconContext: React.FC<Props> = ({
  type,
  colorSvg,
  width = "24px",
  height = "24px",
  colorFill,
}) => {
  const renderIcon = (
    SvgComponent: React.FC<React.SVGProps<SVGSVGElement>>
  ) => {
    return (
      <SvgComponent
        style={{
          color: colorSvg,
          stroke: colorSvg,
          fill: colorFill,
          width: width,
          height: height,
        }}
      />
    );
  };

  switch (type) {
    case IconType.CART:
      return renderIcon(CartIcon);
    case IconType.USER:
      return renderIcon(UserIcon);
    case IconType.SEARCH:
      return renderIcon(SearchIcon);
    case IconType.WISH:
      return renderIcon(WishIcon);
    case IconType.MENU:
      return renderIcon(MenuIcon);
    case IconType.LEFT_ARROW:
      return renderIcon(LeftArrowIcon);
    case IconType.FILTER_ICON:
      return renderIcon(FilterSvg);
    case IconType.CART_SECOND:
      return renderIcon(CartSecondIcon);
    case IconType.ORDER_HISTORY:
      return renderIcon(OrderHistoryIcon);
    case IconType.WISH_SECOND:
      return renderIcon(WishSecondIcon);
    case IconType.FILE:
      return renderIcon(FileIcon);
    case IconType.ASC_ICON:
      return renderIcon(AscIcon);
    case IconType.DESC_ICON:
      return renderIcon(DescIcon);
    case IconType.NO_RESULT:
      return renderIcon(NoResult);
    case IconType.WHY_US:
      return renderIcon(WhyUs);
    case IconType.NO_IMAGE:
      return renderIcon(NoImage);
    default:
      return <></>;
  }
};

export class IconType {
  static MENU = "menu";
  static CART = "cart";
  static USER = "user";
  static SEARCH = "search";
  static WISH = "wish";
  static LEFT_ARROW = "leftArrow";
  static FILTER_ICON = "filterIcon";
  static CART_SECOND = "cartSecond";
  static ORDER_HISTORY = "orderHistory";
  static WISH_SECOND = "wishSecond";
  static FILE = "file";
  static ASC_ICON = "ascIcon";
  static DESC_ICON = "descIcon";
  static NO_RESULT = "noResult";
  static WHY_US = "whyUs";
  static NO_IMAGE = "noImage";
}
