import SelectOptions from "dto/app/selectoptions.dto";
import Idto from "interfaces/idto.interface";
import { CommonTools } from "tools/utils/common.tool";
import { Status } from "tools/types/status";
import { CountryDto } from "./country.dto";
import { GeneralService } from "services/general.service";
import { NomenclatureDto } from "dto/nomenclature/nomenclature.dto";

export class LocationDto implements Idto {
  id?: number | string;
  identifier?: string;

  order?: number;
  status?: number;

  hierarchicalcode?: string;
  level?: number;
  idparent?: string;

  _idlang?: number | string;
  _name?: string;
  idtypelocation?: number;
  idcountry?: number;

  typeLocationObj?: NomenclatureDto;
  countryObj?: CountryDto;

  constructor(
    _idlang?: number | string,
    idparent?: string,
    id?: number | string,
    identifier?: string,
    order?: number,
    status?: number,
    _name?: string,
    idtypelocation?: number,
    idcountry?: number
  ) {
    this.id = id || 0;
    this.identifier = identifier || "";
    this.order = order || 0;

    this.status = status || Status.ACTIVE;
    this._idlang = _idlang || 0;
    this._name = _name || "";

    this.idparent = idparent || "";
    this.idtypelocation = idtypelocation || 0;
    this.idcountry = idcountry || 0;
  }


  static parseToSelectOptions(
    data: Array<LocationDto>,
    all?: boolean
  ): Array<SelectOptions> {
    if (all === undefined) all = false;
    if (!data) return [];
    if (!Array.isArray(data)) return [];
    if (!data.length) return [];
    let result: Array<SelectOptions> = [];
    if (all) result.push(new SelectOptions(-1, GeneralService.LL("All")));
    data.forEach((element) => {
      result.push(LocationDto.parseOption(element));
    });
    return result;
  }

  static parseOption = (obj: LocationDto): SelectOptions => {
    return new SelectOptions(
      CommonTools.processObjectField(obj, ["id"]),
      CommonTools.processObjectField(obj, ["_name"])
    );
  };
}
