import { Grid, Skeleton } from "@mui/material";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { OrderProductDto } from "dto/sale/orderproduct.dto";
import { useList } from "hooks/useList";
import React from "react";
import { OrderProductService } from "services/sale/orderproduct.service";
import { OrderProductItem } from "./OrderProductItem";
import { SkeletonCartItem } from "components/elements/skeleton/product/SkeletonCartItem";

type Props = {
  idOrder: string;
};

const service = new OrderProductService();
const OrderProductList: React.FC<Props> = ({ idOrder }) => {
  const getList = (cb?: any, cbParam?: any, req?: RequestListDTO) => {
    if (!idOrder) return;
    service.getList(cb, cbParam, req);
  };

  const [loading, objects] = useList<OrderProductDto>(
    getList,
    new RequestListDTO(
      [RequestFilterDTO.prepareFilter("idorder", [idOrder])],
      1,
      -1
    ),
    [idOrder]
  );
  const processItem = (object: OrderProductDto, index: number) => {
    if (!object) return null;
    return (
      <Grid item xs={12} md={6} xl={4} sx={{ px: 0, py: 1 }} key={index}>
        <OrderProductItem object={object} />
      </Grid>
    );
  };

  if (!idOrder) return null;
  if (loading) return <SkeletonCartItem lines={3} />;
  if (!objects) return null;
  if (!objects.length) return null;
  return (
    <Grid
      container
      sx={{
        // width: "100%",
        // background: "red",
        py: 3,
        gap: 0,
        // display: "flex",
        // justifyContent: "start",
      }}>
      {objects.map((object, index) => processItem(object, index))}
    </Grid>
  );
};

export { OrderProductList };
