import { Box } from "@mui/material";
import { GalleryDto } from "dto/system/gallery.dto";
import React from "react";
import { Image } from "components/elements/display/Image";

import { CommonTools } from "tools/utils/common.tool";

type Props = {
  item: GalleryDto;
};

const GalleryItem: React.FC<Props> = ({ item }) => {
  if (!item) return null;
  return (
    <Box
      className="imageDiv"
      sx={{
        width: "auto",
        height: "100%",
        overflow: "hidden",
        position: "relative",
        "::after": {
          content: '""',
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          border: "10px solid white",
          boxSizing: "border-box",
          pointerEvents: "none",
          cursor: "pointer",
        },
      }}>
      <Image
        url={CommonTools.processObjectField(item, ["cdnurl"])}
        typeMeasure="percentage"
        sizeInPercentage={{ width: "100%", height: "100%" }}
        withLinK={false}
        style={{ width: "100%", height: "100%", objectFit: "cover" }}
        // style={{
        //   objectFit: "cover",
        //   width: "100%",
        // }}
        // cutImageWidth={844}
        // cutImageHeight={910}
      />
    </Box>
  );
};

export { GalleryItem };
