import { Box, ListItem, ListItemButton, Typography } from "@mui/material";

import { useDrawer } from "hooks/useDrawer";
import React from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { RouteTools } from "tools/utils/route.tool";
import { CommonTools } from "tools/utils/common.tool";
import { NavigatorDto } from "dto/static/navigator.dto";

type Props = {
  item: NavigatorDto;
};

const DrawerNavigatorItem: React.FC<Props> = ({ item }) => {
  const { closeAllDrawers } = useDrawer();

  const handleNavigate = () => {
    if (!CommonTools.processObjectField(item, ["_fullurl"])) return;
    RouteTools.setHistory(
      CommonTools.processObjectField(item, ["_fullurl"]),
      {}
    );
    closeAllDrawers();
  };

  if (!item) return null;
  return (
    <ListItem>
      <ListItemButton
        onClick={handleNavigate}
        className="listItemButton"
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "start",
        }}>
        <Typography sx={{ fontSize: { xs: "16px", sm: "14px" } }}>
          {CommonTools.processObjectField(item, ["_name"])}
        </Typography>
        <Box>
          <KeyboardArrowRightIcon className="iconSvg" />
        </Box>
      </ListItemButton>
    </ListItem>
  );
};

export { DrawerNavigatorItem };
