import * as React from "react";
import { Box } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

interface SkeletonProps {
  lines?: number;
  width?: number;
}

const SkeletonProductPage: React.FC<SkeletonProps> = ({ lines }) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        pb: "66px",
      }}>
      <Box
        sx={{
          maxWidth: "1920px",
          width: "100%",
          px: { xs: 4, lg: 10 },
          display: "flex",
          justifyContent: "center",
          alignItems: { xs: "center", lg: "start" },
          height: "100%",
          flexDirection: { xs: "column", lg: "row" },
          gap: 4,
        }}>
        <Box
          sx={{
            width: { xs: "100%", lg: "60%" },
            display: "flex",
            justifyContent: "center",
            alignItems: { xs: "center", lg: "end" },
            flexDirection: "column",
            maxWidth: "910px",
            // height: "900px",
            // background: "red",
            // px: 8,
          }}>
          <Skeleton
            variant="rectangular"
            sx={{
              height: { xs: "80vw", lg: "38vw" },
              maxHeight: "754px",
              maxWidth: "910px",
              width: "100%",
            }}
          />
        </Box>
        <Box
          sx={{
            width: { xs: "100%", lg: "40%" },
            maxWidth: { xs: "none", lg: "610px" },
            display: "flex",
            justifyContent: "start",
            alignItems: "start",
            flexDirection: "column",
          }}>
          <Skeleton variant="text" width="80%" height="36px" />
          <Skeleton variant="text" width="40%" height="36px" />
          <Skeleton variant="text" width="20%" height="36px" sx={{ mt: 2 }} />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              flexDirection: "row",
              gap: 2,
              mt: 3,
            }}>
            <Skeleton variant="text" width="30px" sx={{ fontSize: "20px" }} />
            <Skeleton
              variant="rectangular"
              sx={{ height: "51px", width: "76px" }}
            />
            <Skeleton variant="text" width="30px" sx={{ fontSize: "20px" }} />
          </Box>
          <Box
            sx={{
              mt: 3,
              display: "flex",
              justifyContent: "center",
              alignItems: "start",
              flexDirection: "column",
            }}>
            <Skeleton variant="text" width="30%" height="26px" />
            <Box
              sx={{
                mt: 1,
                width: "100%",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: 2,
              }}>
              <Skeleton
                variant="rectangular"
                sx={{ width: "100px", height: "45px" }}
              />
              <Skeleton
                variant="rectangular"
                sx={{ width: "100px", height: "45px" }}
              />
              <Skeleton
                variant="rectangular"
                sx={{ width: "100px", height: "45px" }}
              />
              <Skeleton
                variant="rectangular"
                sx={{ width: "100px", height: "45px" }}
              />
            </Box>
            <Skeleton variant="text" width="30%" height="26px" sx={{ mt: 3 }} />
            <Box
              sx={{
                mt: 1,
                width: "100%",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: 2,
              }}>
              <Skeleton
                variant="rectangular"
                sx={{ width: "100px", height: "45px" }}
              />
              <Skeleton
                variant="rectangular"
                sx={{ width: "100px", height: "45px" }}
              />
              <Skeleton
                variant="rectangular"
                sx={{ width: "100px", height: "45px" }}
              />
              <Skeleton
                variant="rectangular"
                sx={{ width: "100px", height: "45px" }}
              />
            </Box>
          </Box>
          <Skeleton
            variant="rectangular"
            sx={{ width: "100%", mt: 3, height: "45px" }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export { SkeletonProductPage };
