import { Loading } from "components/elements/loading/Loading";
import { ResultMetaDTO } from "dto/app/resultmeta.dto";
import { ResultMetaAlternateDTO } from "dto/app/resultmetaalternate.dto";
import IProvider from "interfaces/provider.interface";
import React, { createContext, useState, useEffect, useContext } from "react";

import { Helmet } from "react-helmet";
import GeneralAxiosRepository from "repositories/generalaxios.repository";
import { CommonTools } from "tools/utils/common.tool";
import { Config } from "tools/utils/config";

export const HeadContext = createContext({
  generalSetMeta: (obj: any) => {},

  processFavicon: (v: string) => {},
});
export const useHead = () => useContext(HeadContext);

export const HeadProvider: React.FC<IProvider> = ({ children }) => {
  const [_publicUrl, setPublicUrl] = useState("");

  const [_title, setTitle] = useState("");
  const [_favicon, setFavicon] = useState("");
  const [_description, setDescription] = useState("");
  const [_author, setAuthor] = useState("");
  const [_languageCode, setLanguageCode] = useState("");
  const [_revisitAfter, setRevisitAfter] = useState("");
  const [_rating, setRating] = useState("");
  const [_googleVerification, setGoogleVerification] = useState("");
  const [_yandexVerification, setYandexVerification] = useState("");
  const [_canonical, setCanonical] = useState("");
  const [_image, setImage] = useState("");
  const [_manifest, setManifest] = useState("");
  const [keyWords, setKeyWords] = useState("");
  const [resourceType, setResourceType] = useState("document");
  const [dcFormat, setDcFormat] = useState("text/html");

  const [_alternates, setAlternates] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const purl = Config.PUBLIC_URL;
    const gv = Config.GOOGLE_VERIFICATION;
    const yv = Config.YANDEX_VERIFICATION;
    setPublicUrl(purl);
    setGoogleVerification(gv);
    setYandexVerification(yv);
    GeneralAxiosRepository.setMetaFunction(generalSetMeta);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (!_publicUrl) return;

    setFavicon(_publicUrl + "favicon.png");
    setManifest(_publicUrl + "manifest.json");
  }, [_publicUrl]);

  const processFavicon = (v: string) => {
    if (!v) return;
    setFavicon(v);
  };

  const customMeta = (n?: any, v?: any, fn?: any, vn?: any) => {
    if (!v) return null;
    fn = fn !== undefined ? fn : "name";
    vn = vn !== undefined ? vn : "content";
    let props: any = {};
    props[fn] = n;
    props[vn] = v;
    return <meta {...props} />;
  };

  const processAlternateList = () => {
    if (!_alternates) return null;
    if (!Array.isArray(_alternates)) return null;
    if (!_alternates.length) return null;

    return (
      <Helmet>
        {_alternates.map((obj: ResultMetaAlternateDTO, index: number) => {
          const hl = obj.hreflang ? obj.hreflang : "";
          const url = obj.url ? obj.url : "";
          return <link rel="alternate" lang={hl} href={url} key={index} />;
        })}
      </Helmet>
    );
  };

  const generalSetMeta = (obj: ResultMetaDTO) => {
    if (CommonTools.processNumberToBoolean(obj.updateInfo)) {
      if (obj.title) setTitle(obj.title);
      if (obj.description) setDescription(obj.description);
      if (obj.author) setAuthor(obj.author);
      if (obj.dcLanguage) setLanguageCode(obj.dcLanguage);
      if (obj.revisitAfter) setRevisitAfter(obj.revisitAfter);
      if (obj.rating) setRating(obj.rating);
      if (obj.urlCanonical) setCanonical(obj.urlCanonical);
      if (obj.image) setImage(obj.image);
      if (obj.alternateUrls) setAlternates(obj.alternateUrls);
      if (obj.keyWords) setKeyWords(obj.keyWords);
      if (obj.resourseType) setResourceType(obj.resourseType);
      if (obj.dcFormat) setDcFormat(obj.dcFormat);
    }
  };

  const value = { generalSetMeta, processFavicon };
  return loading ? (
    <Loading />
  ) : (
    <HeadContext.Provider value={value}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{_title}</title>
        <meta name="description" content={_description} />
        <meta name="author" content={_author} />
        <meta name="DC.Language" content={_languageCode} />
        <meta name="resource-type" content={resourceType} />
        <meta name="keywords" content={keyWords} />
        {customMeta("REVISIT-AFTER", _revisitAfter)}
        {customMeta("RATING", _rating)}
        {customMeta("DC.Title", _title)}
        <meta name="DC.Format" content={dcFormat} />
        {customMeta("google-site-verification", _googleVerification)}
        {customMeta("yandex-verification", _yandexVerification)}

        {customMeta("og:title", _title, "property")}
        {customMeta("og:description", _description, "property")}
        {customMeta("og:url", _canonical, "property")}
        {customMeta("og:image", _image, "property")}

        {customMeta("twitter:title", _title, "property")}
        {customMeta("twitter:description", _description, "property")}
        {customMeta("twitter:url", _canonical, "property")}
        {customMeta("twitter:image", _image, "property")}

        <link rel="canonical" href={_canonical} />
        <link rel="icon" type="image/ico" href={_favicon} />
        <link rel="shortcut icon" href={_favicon} />
        <link rel="apple-touch-icon" href={_favicon} />
        <link rel="manifest" href={_manifest} />

        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
      </Helmet>
      {processAlternateList()}
      {children}
    </HeadContext.Provider>
  );
};
