import Idto from "interfaces/idto.interface";
import { GalleryDto } from "./gallery.dto";
import { AttachmentDto } from "./attachment.dto";
import { VideoDto } from "./video.dto";

export  abstract class  MediaDto implements Idto {
  _galleryDefault?: GalleryDto | null;
  _galleryList?: Array<GalleryDto>;
  _galleryActiveList?: Array<GalleryDto>;

  _attachmentDefault?: AttachmentDto | null;
  _attachmentList?: Array<AttachmentDto>;
  _attachmentActiveList?: Array<AttachmentDto>;

  _videoDefault?: VideoDto | null;
  _videoList?: Array<VideoDto>;
  _videoActiveList?: Array<VideoDto>;
}
