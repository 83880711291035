import GeneralRepository from "../general.repository";
import IGeneralRepository from "interfaces/irepository.interface";

export class BlogRepository
  extends GeneralRepository
  implements IGeneralRepository
{
  constructor() {
    super("blog");
  }

}
