import React from "react";
import { Checkbox, FormControlLabel, CheckboxProps } from "@mui/material";

type Props = CheckboxProps & {
  label: string;
  field: string;
  setObjectField: (field: string, value: any) => void;
};

const MyCheckboxField: React.FC<Props> = ({
  label,
  field,
  setObjectField,
  ...props
}) => {
    
  const handleChange = (event: any) => {
    setObjectField(field, event.target.checked);
  };

  return (
    <FormControlLabel
      control={<Checkbox onChange={handleChange} {...props} />}
      label={label}
    />
  );
};

export { MyCheckboxField };
