import React from "react";

import { AdvertisementDto } from "dto/static/advertisement.dto";
import { Box, Typography } from "@mui/material";

import { CommonTools } from "tools/utils/common.tool";
import { VideoFile } from "components/elements/display/VideoFile";
import { Config } from "tools/utils/config";
import { min } from "date-fns";

type Props = {
  item: AdvertisementDto;
  index: number;
  isHomePage?: boolean;
};

const AdvertisementVideoItemHomeTop: React.FC<Props> = ({
  item,
  isHomePage,
}) => {
  const styleBlock = {
    width: "100%",
    height: "100%",
    boxSize: "content-box",
    overflow: "hidden",
  };
  const processMedia = () => {
    if (!item) return null;
    if (!CommonTools.processObjectField(item, ["_filecdnurl"])) return null;

    return (
      <Box className="videoDiv" sx={{ width: "100%", height: "100%" }}>
        <VideoFile
          url={CommonTools.processObjectField(item, ["_filecdnurl"])}
          controls={false}
          width="100%"
          height="100%"
          style={{ objectFit: isHomePage ? "cover" : "contain" }}
          // style={{ minHeight: "900px" }}
        />
      </Box>
    );
  };
  if (!item) return null;
  if (!CommonTools.processObjectField(item, ["_filecdnurl"])) return null;
  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
        height: isHomePage ? `calc(100%)` : { xs: "20vh", md: "40vh" },
        maxHeight: isHomePage ? "none" : "570px",
      }}>
      <Box
        sx={{
          position: isHomePage ? "block" : "absolute",
          top: isHomePage ? "0%" : "50%",
          left: "0",
          width: "100%",
          height: "100%",
          // height: isHomePage ? "100%" : "auto",
          transform: isHomePage ? "none" : "translateY(-55%)",
        }}>
        <Box
          className="addBlocks"
          sx={[styleBlock, { position: "relative", objectFit: "cover" }]}>
          {processMedia()}
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, 0)",
            }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Typography
                sx={{
                  fontSize: { xs: "20px", sm: "30px" },
                  color: "white",
                  fontWeight: "bold",
                }}>
                {CommonTools.processObjectField(item, ["_name"])}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export { AdvertisementVideoItemHomeTop };
