import {LanguageRepository} from "../../repositories/static/language.repository";
import { GeneralService } from "../general.service";

export class LanguageService extends GeneralService<LanguageRepository> {
  constructor() {
    super(new LanguageRepository());
  }

  getListAdmin = async (cb?: any, cbparameters?: any): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;

    const t = await this.repository.getListAdmin(
      this.handleGetList,
      cbparameters
    );
    if (cb === undefined) {
      return this.handleGetList(t);
    }
  };

  changeLanguageByCode = async (
    code: string,
    cb?: any,
    cbparameters?: any
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;

    const t = await this.repository.changeLanguageByCode(
      code,
      this.handleGet,
      cbparameters
    );
    if (cb === undefined) {
      return this.handleGet(t);
    }
  };
}
