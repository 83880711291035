import { Box, Typography } from "@mui/material";
import { ButtonWithLoading } from "components/elements/button/ButtonWithLoading";

import { MyCheckboxField } from "components/elements/form/MyCheckboxField";
import { MyTextField } from "components/elements/form/MyTextField";
import { LoginSiteDto } from "dto/user/user.dto";
import { useForm } from "hooks/useForm";
import { useLabel } from "hooks/useLabel";
import { CallbackType } from "interfaces/commontypes.interface";

import React, { useState } from "react";
import RequiredValidator from "validators/required.validator";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { PasswordField } from "components/elements/form/PasswordField";
import { NavButton } from "components/elements/button/NavButton";
import { CommonTools } from "tools/utils/common.tool";
import { logger } from "tools/utils/logger";

type Props = {
  onSubmit: (obj: LoginSiteDto, cb: CallbackType) => void;
};
const LoginForm: React.FC<Props> = ({ onSubmit }) => {
  const { LL } = useLabel();
  const [loading, setLoading] = useState(false);
  const [obj, disabled, setObjField] = useForm<LoginSiteDto>(
    new LoginSiteDto(),
    RequiredValidator.getValidators(["email", "password"])
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    onSubmit(obj, cb);
  };

  const cb = () => {
    setLoading(false);
  };

  return (
    <Box sx={{ width: "100%", maxWidth: "400px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
        }}>
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: "bold",
            textTransform: "uppercase",
          }}>
          {LL("login_title")}
        </Typography>
      </Box>
      <form onSubmit={handleSubmit}>
        <Box
          mt={3}
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <MyTextField
            field="email"
            label={LL("field_email")}
            setObjectField={setObjField}
            value={obj.email}
            sx={{ width: "100%" }}
            selectTheme={"standardText"}
          />
        </Box>
        <Box
          mt={3}
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <PasswordField
            field="password"
            label={LL("field_password")}
            setObjectField={setObjField}
            sx={{ width: "100%" }}
            value={obj.password}
            selectTheme={"standardText"}
          />
        </Box>
        <Box
          mt={3}
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: { xs: "start", sm: "space-between" },
            flexDirection: { xs: "column", sm: "row" },
            alignItems: { xs: "start", sm: "center" },
            fontSize: { xs: "16px", sm: "14px" },

            gap: 2,
          }}>
          <MyCheckboxField
            field="remember"
            label={LL("field_remember_me")}
            setObjectField={setObjField}
            checked={obj.remember}
            className="checkboxBlack"
          />
          <Box className="myButtonAnimation">
            <NavButton
              sx={{
                textDecoration: "none",
                color: "black",
                fontWeight: "500",
                fontSize: { xs: "16px", sm: "14px" },
              }}
              className={"textAnimation"}
              href="/forgotpassword"
              _hstate={{
                email: CommonTools.processObjectField(obj, ["email"]),
              }}>
              {LL("btn_forgotpassword")}
            </NavButton>
          </Box>
        </Box>
        <Box
          mt={3}
          sx={{ width: "100%", display: "flex", justifyContent: "end" }}>
          <Box
            sx={{ width: { xs: "100%", md: "200px" }, height: "45px" }}
            className={`whiteButton listItemButton ${
              disabled ? "disabled" : ""
            } ${loading ? "loadingBtn" : ""}
            `}>
            <ButtonWithLoading
              type="submit"
              disabled={loading || disabled}
              loading={loading}
              sx={{
                height: "100%",
                // background: "red",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                width: "100%",
              }}>
              <Typography
                sx={{
                  mt: "4px",
                  ml: { xs: 0, sm: 3 },
                  fontSize: { xs: "16px", sm: "14px" },
                  fontWeight: "bold",
                }}>
                {LL("btn_login")}
              </Typography>
              <Box
                sx={{
                  width: "20px",
                  mt: "3px",
                  display: { xs: "none", sm: "flex" },
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                {!disabled && <KeyboardArrowRightIcon className="iconSvg3" />}
              </Box>
            </ButtonWithLoading>
          </Box>
        </Box>
      </form>
      <Box>
        {/* <NavButton
          href="/forgotpassword"
          _hstate={{
            email: CommonTools.processObjectField(obj, ["email"]),
          }}>
          {LL("btn_forgotpassword")}
        </NavButton> */}
      </Box>
    </Box>
  );
};

export { LoginForm };
