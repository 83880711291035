import React, { useState } from "react";
import { IPage } from "interfaces/page.interface";
import { ResetPasswordForm } from "components/authentication/ResetPasswordForm";
import { Box, Container } from "@mui/material";

import { Config } from "tools/utils/config";
import { ResetPasswordDto } from "dto/user/user.dto";
import { UserService } from "services/user/user.service";

import { CommonTools } from "tools/utils/common.tool";

import { useUser } from "hooks/useUser";
import { useSeoInfo } from "hooks/useSeoInfo";

const service = new UserService();
const ResetPasswordPage: React.FC<IPage> = ({ currentRoute }) => {

  const [loading, setLoading] = useState(false);
  const { handleLogin } = useUser();
  const email = CommonTools.processIdFromPaths(currentRoute, 1);
  const code = CommonTools.processIdFromPaths(currentRoute, 2);
  const cb = () => {
    setLoading(false);
  };
  const onSubmit = (obj: ResetPasswordDto) => {
    setLoading(true);
    service.resetPassword(handleLogin, { cb, remember: false }, obj);
  };
  useSeoInfo("resetpassword")
  return (
    <Box
      sx={{
        maxWidth: "1920px",
        marginX: "auto",
        minHeight: `calc(100vh - ${Config.HEADER_HEIGHT}px - ${Config.FOOTER_HEIGHT}px + 118px)`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}>
      <Container
        maxWidth="sm"
        sx={{
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          height: "100%",
          mt: 8,
          mb: 2,
        }}>
        <Box
          sx={{
            mt: "64px",
            display: "flex",
            alignItems: "center",
            alignContent: "center",
            width: "100%",
            flexDirection: "column",
            justifyContent: "center",
            px: { xs: 4, sm: 10 },
            pb: 6,
            pt: 3,
            borderRadius: 4,
          }}>
          <ResetPasswordForm
            onSubmit={onSubmit}
            defaultObj={new ResetPasswordDto(email, "", code)}
            loading={loading}
          />
        </Box>
      </Container>
    </Box>
  );
};

export { ResetPasswordPage };
