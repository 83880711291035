import Idto from "interfaces/idto.interface";
import { LabelTypes } from "tools/types/labeltypes";
import { Status } from "tools/types/status";
import { CommonTools } from "tools/utils/common.tool";


export class DisplayLabelDto implements Idto {
  id: number;
  identifier: string;
  en: string;
  ro: string;
  ru: string;
  constructor(
    id?: number,
    identifier?: string,
    en?: string,
    ro?: string,
    ru?: string
  ) {
    this.id = id || 0;
    this.identifier = identifier || "";
    this.en = en || "";
    this.ro = ro || "";
    this.ru = ru || "";
  }

  static getLabelLocal(identifier: string): DisplayLabelDto {
    const label = new DisplayLabelDto();
    label.identifier = CommonTools.getPrefixLabel(identifier);
    label.ro = identifier;
    label.ru = identifier;
    label.en = identifier;
    return label;
  }
}

export class LabelDto implements Idto {
  id?: number | string;
  _name?: string;
  identifier?: string;
  type?: number;
  status?: number;
  _idlang?: number;

  constructor(
    id?: number | string,
    name?: string,
    identifier?: string,
    type?: number,
    status?: number,
    idlang?: number
  ) {
    this.id = id || 0;
    this._name = name || "";
    this.identifier = identifier || "";
    this.type = type || LabelTypes.SITE;
    this.status = status || Status.PROCESSED;
    this._idlang = idlang || 0;
  }
  
}
