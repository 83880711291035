import React from "react";
import { Box } from "@mui/material";
import { LanguageSiteOptions } from "components/static/language/LanguageSiteOptions";
import { HeaderBlockProps } from "interfaces/header.interface";


const LanguageBlock: React.FC<HeaderBlockProps> = ({ colorSvg }) => {
  return (
    <Box sx={{ px: 0 }}>
      <LanguageSiteOptions colorSvg={colorSvg} />
    </Box>
  );
};

export { LanguageBlock };
