import { Box, Typography } from "@mui/material";
import { ButtonWithLoading } from "components/elements/button/ButtonWithLoading";

import { MyTextField } from "components/elements/form/MyTextField";
import { ResetPasswordDto } from "dto/user/user.dto";
import { useForm } from "hooks/useForm";
import { useLabel } from "hooks/useLabel";

import React from "react";
import RequiredValidator from "validators/required.validator";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { PasswordField } from "components/elements/form/PasswordField";

type Props = {
  onSubmit: (obj: ResetPasswordDto) => void;
  loading: boolean;
  defaultObj: ResetPasswordDto;
};
const ResetPasswordForm: React.FC<Props> = ({
  onSubmit,
  loading,
  defaultObj,
}) => {
  const { LL } = useLabel();

  const [obj, disabled, setObjField] = useForm<ResetPasswordDto>(
    defaultObj,
    RequiredValidator.getValidators(["email", "password", "resetcode"])
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    onSubmit(obj);
  };

  return (
    <Box sx={{ width: "100%", maxWidth: "400px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
        }}>
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: "bold",
            textTransform: "uppercase",
          }}>
          {LL("resetpassword_title")}
        </Typography>
      </Box>
      <form onSubmit={handleSubmit}>
        <Box
          mt={3}
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <MyTextField
            field="email"
            label={LL("field_email")}
            setObjectField={setObjField}
            value={obj.email}
            sx={{ width: "100%" }}
            selectTheme={"standardText"}
          />
        </Box>
        <Box
          mt={3}
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <PasswordField
            field="password"
            label={LL("field_password")}
            setObjectField={setObjField}
            sx={{ width: "100%" }}
            value={obj.password}
            selectTheme={"standardText"}
          />
        </Box>
        <Box
          mt={3}
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <MyTextField
            field="resetcode"
            label={LL("field_resetcode")}
            setObjectField={setObjField}
            value={obj.resetcode}
            sx={{ width: "100%", textTransform: "uppercase" }}
            selectTheme={"standardText"}
          />
        </Box>
        <Box
          mt={3}
          sx={{ width: "100%", display: "flex", justifyContent: "end" }}>
          <Box
            sx={{ width: { xs: "100%", md: "200px" }, height: "45px" }}
            className={`whiteButton listItemButton ${
              disabled ? "disabled" : ""
            } ${loading ? "loadingBtn" : ""}
            `}>
            <ButtonWithLoading
              type="submit"
              disabled={loading || disabled}
              loading={loading}
              sx={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                width: "100%",
              }}>
              <Typography
                sx={{ mt: "4px", fontWeight: "600", ml: { xs: 0, sm: 3 } }}>
                {LL("btn_resetpassword")}
              </Typography>
              <Box
                sx={{
                  width: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                {!disabled && <KeyboardArrowRightIcon className="iconSvg3" />}
              </Box>
            </ButtonWithLoading>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export { ResetPasswordForm };
