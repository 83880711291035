import GeneralRepository from "../general.repository";
import IGeneralRepository from "interfaces/irepository.interface";

export class ContactPointRepository
  extends GeneralRepository
  implements IGeneralRepository
{
  constructor() {
    super("contactpoint");
  }

}
