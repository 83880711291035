import React from "react";
import { Box } from "@mui/material";
import { useDrawer } from "hooks/useDrawer";
import { MyButton } from "components/elements/button/MyButton";
import { HeaderBlockProps } from "interfaces/header.interface";
import { IconType } from "components/elements/icon/IconContext";
import { MyIcon } from "components/elements/icon/MyIcon";

const MenuBlock: React.FC<HeaderBlockProps> = ({ colorSvg, isMobile }) => {
  const { handleOpenMainDrawer } = useDrawer();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: colorSvg,
        px: 0,
        mt: "3px",
        width: "50px",
      }}>
      <MyButton
        cb={handleOpenMainDrawer}
        variant="text"
        sx={{ color: colorSvg, py: 0, fontSize: "14px", width: "20px" }}>
        <Box
          sx={{
            // width: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "20px",
          }}>
          <MyIcon type={IconType.MENU} colorSvg={colorSvg} />
        </Box>
      </MyButton>
    </Box>
  );
};

export { MenuBlock };
