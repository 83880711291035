import SelectOptions from "dto/app/selectoptions.dto";

import Idto from "interfaces/idto.interface";
import { CommonTools } from "tools/utils/common.tool";

import { Status } from "tools/types/status";
import { DeliveryMethodOfferDto } from "./deliverymethodoffer.dto";

export class DeliveryMethodDto implements Idto {
  id?: number | string;

  order?: number;

  status?: number;
  _idlang?: number | string;
  _name?: string;

  defaultOfferObj?: DeliveryMethodOfferDto | null;

  constructor(
    _idlang?: number | string,
    id?: number | string,
    order?: number,
    status?: number,
    _name?: string
  ) {
    this.id = id || 0;

    this.order = order || 0;

    this.status = status || Status.ACTIVE;
    this._idlang = _idlang || 0;
    this._name = _name || "";
  }

  static parseToSelectOptions(
    data: Array<DeliveryMethodDto>
  ): Array<SelectOptions> {
    if (!data) return [];
    if (!Array.isArray(data)) return [];
    if (!data.length) return [];
    let result: Array<SelectOptions> = [];
    data.forEach((element) => {
      result.push(DeliveryMethodDto.parseOption(element));
    });
    return result;
  }

  static parseOption = (obj: DeliveryMethodDto): SelectOptions => {
    return new SelectOptions(
      CommonTools.processObjectField(obj, ["id"]),
      CommonTools.processObjectField(obj, ["_name"])
    );
  };
}
