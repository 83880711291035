import GeneralRepository from "../general.repository";
import IGeneralRepository from "interfaces/irepository.interface";

export class PageRepository
  extends GeneralRepository
  implements IGeneralRepository
{
  constructor() {
    super("page");
  }
}
