import { OfferDto } from "dto/product/offer.dto";
import { ProductDto } from "dto/product/product.dto";
import { ProductFilterDto } from "dto/product/productfilter.dto";
import { CommonTools } from "tools/utils/common.tool";
import { Md5 } from "ts-md5";

export class CartDto {
  id: string;
  product: ProductDto;
  quantity: number;
  real_price: number;
  filters: ProductFilterDto[];
  offer: OfferDto;

  constructor(
    product: ProductDto,
    quantity: number,
    real_price: number,
    filters: ProductFilterDto[],
    offer: OfferDto
  ) {
    this.id = CartDto.generateId(product, offer, filters);
    this.product = product;
    this.quantity = quantity;
    this.real_price = real_price;
    this.filters = filters;
    this.offer = offer;
  }

  static add(
    product: ProductDto,
    quantity: number,
    offer: OfferDto,
    selectedFilter: ProductFilterDto[]
  ): CartDto | null {
    if (!product || !quantity || !offer || !offer.offerFilters) return null;
    const real_price = offer.real_price
      ? typeof offer.real_price === "string"
        ? parseFloat(offer.real_price)
        : offer.real_price
      : 0;

    if (!real_price) return null;

    return new CartDto(product, quantity, real_price, selectedFilter, offer);
  }
  static calculateTotalPriceProduct(
    real_price: number,
    quantity: number,
    currency_rate: number
  ): number {
    if (!real_price || !quantity || !currency_rate) return 0;

    const total = real_price * quantity * currency_rate;

    if (isNaN(total)) return 0;
    if (!isFinite(total)) return 0;

    return total;
  }

  static calculateTotalPriceWithoutCurrencyProduct(
    real_price: number,
    quantity: number
  ) {
    if (!real_price || !quantity) return 0;

    const total = real_price * quantity;

    if (isNaN(total)) return 0;
    if (!isFinite(total)) return 0;

    return total;
  }

  static getId(
    product: ProductDto,
    offer: OfferDto | null,
    filters: ProductFilterDto[]
  ): string {
    if (!product || !offer) return CommonTools.generateRandomString(10);

    return CartDto.generateId(product, offer, filters);
  }

  static generateId(
    product: ProductDto,
    offer: OfferDto,
    filters: ProductFilterDto[]
  ): string {
    product = CommonTools.sortObjectKeys(product);
    offer = CommonTools.sortObjectKeys(offer);
    filters = CommonTools.sortObjectKeys(filters);
    const object = JSON.stringify({ product, offer, filters });
    return Md5.hashStr(object);
  }

  static calculateTotalPrice(cart: CartDto[], currency_rate: number): number {
    if (!cart || !cart.length || !currency_rate) return 0;
    let total = 0;
    cart.forEach((item) => {
      total += CartDto.calculateTotalPriceProduct(
        item.real_price,
        item.quantity,
        currency_rate
      );
    });
    return total;
  }

  static calculateTotalPriceWithoutCurrency(cart: CartDto[]): number {
    if (!cart || !cart.length) return 0;
    let total = 0;
    cart.forEach((item) => {
      total += CartDto.calculateTotalPriceWithoutCurrencyProduct(
        item.real_price,
        item.quantity
      );
    });
    return total;
  }

  static calculateTotalPriceOrder(
    totalPriceProduct: number,
    totalPriceDelivery?: number | null,
    currency_rate?: number
  ) {
    if (
      !totalPriceProduct ||
      totalPriceDelivery == null ||
      totalPriceDelivery == undefined ||
      !currency_rate
    )
      return 0;
    return (totalPriceProduct + totalPriceDelivery) * currency_rate;
  }

  static getCartLength(cart: CartDto[]): number {
    if (!cart || !cart.length) return 0;
    const total = cart.reduce((acc, item) => {
      return acc + item.quantity;
    }, 0);
    return total;
  }
}
