import React from "react";

import { FilterDto } from "dto/product/filter.dto";
import { Box, Typography } from "@mui/material";
import { CommonTools } from "tools/utils/common.tool";
type Props = {
  item: FilterDto;
  children: React.ReactNode;
};
const FilterGeneralItem: React.FC<Props> = ({ item, children }) => {
  if (!item) return null;
  return (
    <Box sx={{ width: "auto" }}>
      <Box>
        <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
          {CommonTools.processObjectField(item, ["_name"])}
        </Typography>
      </Box>
      <Box sx={{ ml: 2, py: 1, width: "auto" }}>{children}</Box>
    </Box>
  );
};

export { FilterGeneralItem };
