import { DictionaryDTO } from "dto/app/dictionary.dto";
import { CustomJWTPayload, JWTDto } from "dto/app/jwt.dto";

import Idto from "interfaces/idto.interface";
import { CommonTools } from "tools/utils/common.tool";

import { Status } from "tools/types/status";
import { UserConfigType } from "tools/types/userconfigtype";

export class SignupDto implements Idto {
  email: string;
  password: string;

  constructor(email?: string, password?: string) {
    this.email = email ?? "";
    this.password = password ?? "";
  }
}

export class LoginDto implements Idto {
  email: string;
  password: string;

  constructor(email?: string, password?: string) {
    this.email = email ?? "";
    this.password = password ?? "";
  }

  static parseFromLoginSiteDto(dto: LoginSiteDto): LoginDto {
    return new LoginDto(dto.email, dto.password);
  }
}

export class LoginSiteDto implements Idto {
  email: string;
  password: string;
  remember: boolean;

  constructor(email?: string, password?: string) {
    this.email = email ?? "";
    this.password = password ?? "";
    this.remember = false;
  }
}

export class UserDto implements Idto {
  id?: number;
  email?: string;
  screen_name?: string;
  roles?: number[];
  scopes?: string[];
  configs?: DictionaryDTO[];
  password?: string;
  status?: number;

  constructor(
    id?: number,
    email?: string,
    screen_name?: string,
    roles?: number[],
    scopes?: string[],
    configs?: DictionaryDTO[],
    password?: string,
    status?: number
  ) {
    this.id = id || 0;
    this.email = email || "";
    this.screen_name = screen_name || "";
    this.roles = roles || [];
    this.scopes = scopes || [];
    this.configs = configs || [];
    this.password = password || "";
    this.status = status || Status.USER_ACTIVE;
  }
  static parseFromTokenPayload(payload: CustomJWTPayload): UserDto | null {
    if (
      !CommonTools.processObjectField(payload, ["u_id"]) ||
      CommonTools.processObjectField(payload, ["u_id"]) === "0"
    )
      return null;
    const obj = new UserDto();
    obj.id = payload.u_id;
    obj.email = payload.u_screenname.includes("@") ? payload.u_screenname : "";
    obj.screen_name = payload.u_screenname;
    obj.roles = payload.u_roles;
    obj.scopes = payload.u_scopes;
    obj.configs = payload.u_configs;

    return obj;
  }

  static getFullName(user: UserDto): string {
    if (!user.configs || !user.configs.length) return "";
    const firstname =
      user.configs.find((o) => o.key === UserConfigType.FIRST_NAME)?.value ||
      "";
    const lastname =
      user.configs.find((o) => o.key === UserConfigType.SECOND_NAME)?.value ||
      "";

    if (!firstname && !lastname) return "";
    else if (!firstname) return lastname;
    else if (!lastname) return firstname;
    else return `${firstname} ${lastname}`;
  }

  static addAction = (
    id: string,
    onClick: (url?: string, anchor?: string) => void
  ): ActionMenu => {
    return { id, onClick };
  };
  static getActionBasicAction = (
    onClick: (url?: string, anchor?: string) => void
  ): ActionMenu[] => {
    const actions = ["profile", "login", "signup", "myorder"].map((id) =>
      UserDto.addAction(id, onClick)
    );
    return actions;
  };
  static processUserMenu(
    actions: ActionMenu[],
    isAuth: boolean
  ): UserMenuType[] {
    const menu: UserMenuType[] = [
      {
        id: "profile",
        url: "/profile",
        label: "bt_profile",
        needAuth: true,
        anchor: "generalinfo",
      },
      {
        id: "myorder",
        url: "/profile",
        label: "bt_my_orders",
        needAuth: true,
        anchor: "userorder",
      },
      { id: "login", url: "/login", label: "bt_login", needAuth: false },
      { id: "signup", url: "/signup", label: "bt_signup", needAuth: false },
      { id: "logout", url: "/", label: "bt_logout", needAuth: true },
    ];

    actions.forEach((action) => {
      const item = menu.find((m) => m.id === action.id);
      if (item) {
        item.onClick = action.onClick;
      }
    });
    return menu.filter((o) => o.needAuth === isAuth);
  }
}

type ActionMenu = {
  id: string;
  onClick: (url?: string, anchor?: string) => void;
};
export type UserMenuType = {
  id: string;
  url: string;
  label: string;
  needAuth: boolean;
  onClick?: (url?: string, anchor?: string) => void;
  anchor?: string;
};

export class AuthDto implements Idto {
  user?: UserDto;
  token?: JWTDto;
  remember?: string;
}

export class ChangePasswordDto implements Idto {
  password: string;
  confirmpassword: string;

  constructor(password?: string, confirmpassword?: string) {
    this.password = password ?? "";
    this.confirmpassword = confirmpassword ?? "";
  }
}

export class ForgotPasswordDto implements Idto {
  email: string;
  constructor(email?: string) {
    this.email = email ?? "";
  }
}

export class ResetPasswordDto implements Idto {
  email: string;
  password: string;
  resetcode: string;
  constructor(email?: string, password?: string, resetcode?: string) {
    this.email = email ?? "";
    this.password = password ?? "";
    this.resetcode = resetcode ?? "";
  }
}