import { Box } from "@mui/material";
import { GroupedCategory } from "dto/product/categoryproduct.dto";
import React from "react";
import { DrawerItem } from "./DrawerItem";

type Props = {
  item: GroupedCategory;
};

const DrawerGroupItem: React.FC<Props> = ({ item }) => {
  if (!item) return null;
  const object = item.object;
  const children = item.children;
  if (!object) return null;
  if (!children) return null;
  return (
    <Box sx={{ px: 0 }}>
      <DrawerItem item={object} />
      <Box sx={{ ml: 1 }}>
        {children.map((item: GroupedCategory, index: number) => (
          <DrawerGroupItem item={item} key={index} />
        ))}
      </Box>
    </Box>
  );
};

export { DrawerGroupItem };
