import { Box } from "@mui/material";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { FilterDto } from "dto/product/filter.dto";
import { useList } from "hooks/useList";
import React from "react";
import { FilterDictionarService } from "services/product/filterdictionar.service";
import { Status } from "tools/types/status";
import { FilterDictionarDto } from "dto/product/filterdictionar.dto";
import { FilterDictionarItem } from "./FilterDictinarItem";
import { SkeletonMenuFilterOptionsRow } from "components/elements/skeleton/SkeletonMenuFilter";
import { RequestCriteriaDTO } from "dto/app/requestcriteria.dto";

const service = new FilterDictionarService();
type Props = {
  idFilter: string;
  addCriteriaFilter: (id: string, value: string) => void;
  removeCriteriaFilter: (id: string, value: string) => void;
  criteriaFilter: RequestCriteriaDTO[];
};
const FilterDictionarList: React.FC<Props> = ({
  idFilter,
  addCriteriaFilter,
  removeCriteriaFilter,
  criteriaFilter,
}) => {
  const getList = (cb?: any, cbParams?: any, req?: RequestListDTO) => {
    service.getList(cb, cbParams, req);
  };

  const [loading, objects] = useList<FilterDictionarDto>(
    getList,
    new RequestListDTO(
      [
        RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()]),
        RequestFilterDTO.prepareFilter("idfilter", [idFilter]),
      ],
      1,
      -1,
      [RequestSortCriteriaDTO.prepareSortCriteria("order", true)]
    ),
    [idFilter],
    true,
    `filter-dictinar-product-list-${idFilter}`
  );

  const processItem = (item: FilterDto, index: number) => {
    if (!item) return null;
    return (
      <FilterDictionarItem
        key={index}
        item={item}
        criteriaFilter={criteriaFilter}
        addCriteriaFilter={addCriteriaFilter}
        removeCriteriaFilter={removeCriteriaFilter}
      />
    );
  };

  if (!idFilter) return null;
  if (idFilter === "0") return null;
  if (loading) return <SkeletonMenuFilterOptionsRow lines={4} />;
  if (!objects) return null;
  if (!objects.length) return null;
  return (
    <Box>
      {objects.map((item: FilterDto, index: number) =>
        processItem(item, index)
      )}
    </Box>
  );
};

export { FilterDictionarList };
