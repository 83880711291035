import React from "react";

import { NavigatorDto } from "dto/static/navigator.dto";
import { Box, Link, SxProps, Typography } from "@mui/material";
import { CommonTools } from "tools/utils/common.tool";


type Props = {
  item: NavigatorDto;
  myStyle?: SxProps;
  linkStyle?: SxProps;
  className?: string;
};

const MenuAnchor: React.FC<Props> = ({
  item,
  myStyle = {},
  linkStyle = {},
  className,
}) => {
  const url = CommonTools.processObjectField(item, ["_fullurl"]);
  const navigateToSection = () => {
    if (!url) return;
    const id = url.split("#")[1];
    const element = document.getElementById(id);

    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    e.stopPropagation();
    navigateToSection();
  };
  if (!item) return null;
  return (
    <Box className={className}>
      <Link href={url} onClick={handleClick} sx={linkStyle}>
        <Typography sx={myStyle} className={"textAnimation"}>
          {CommonTools.processObjectField(item, ["_name"])}
        </Typography>
      </Link>
    </Box>
  );
};

export { MenuAnchor };
