import { Box, Link } from "@mui/material";
import { AttachmentDto } from "dto/system/attachment.dto";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";
import { IconType } from "../icon/IconContext";
import { MyIcon } from "../icon/MyIcon";

type Props = {
  item: AttachmentDto;
};

const AttachmentItem: React.FC<Props> = ({ item }) => {
  if (!item) return null;
  return (
    <Box
      sx={{
        color: "black",
        display: "flex",
        justifyContent: "start",
        alignItems: "start",
        gap: 1,
      }}>
      <MyIcon type={IconType.FILE} colorSvg="black" />
      <Link
        href={CommonTools.processObjectField(item, ["cdnurl"])}
        target="_blank"
        rel="noreferrer"
        sx={{
          color: "black",
          fontSize: "18px",
          textDecoration: "none",
          "&:hover": {
            textDecoration: "underline",
          },
        }}>
        {CommonTools.processObjectField(item, ["_name"])}
      </Link>
    </Box>
  );
};

export { AttachmentItem };
