import React from "react";

import { FilterDto } from "dto/product/filter.dto";
import { FilterType } from "tools/types/filtertype";
import { FilterValue } from "./FilterValue";
import { FilterDictionar } from "./FilterDictionar";
import { RequestCriteriaDTO } from "dto/app/requestcriteria.dto";

type Props = {
  item: FilterDto;
  addCriteriaFilter: (id: string, value: string) => void;
  removeCriteriaFilter: (id: string, value: string) => void;
  criteriaFilter: RequestCriteriaDTO[];
  removeRangeFilterMin: (id: string) => void;
  removeRangeFilterMax: (id: string) => void;
  replaceRangeFilterMin: (id: string, value: string) => void;
  replaceRangeFilterMax: (id: string, value: string) => void;
  rangeFilter: RequestCriteriaDTO[];
  filterCriteriaLength: number;
};

const FilterItem: React.FC<Props> = ({
  item,
  addCriteriaFilter,
  removeCriteriaFilter,
  criteriaFilter,
  removeRangeFilterMin,
  removeRangeFilterMax,
  replaceRangeFilterMin,
  replaceRangeFilterMax,
  rangeFilter,
  filterCriteriaLength,
}) => {
  if (!item) return null;

  switch (item.type) {
    case FilterType.DICTIONAR: {
      return (
        <FilterDictionar
          item={item}
          addCriteriaFilter={addCriteriaFilter}
          removeCriteriaFilter={removeCriteriaFilter}
          criteriaFilter={criteriaFilter}
        />
      );
    }
    case FilterType.VALUE: {
      return (
        <FilterValue
          item={item}
          removeRangeFilterMin={removeRangeFilterMin}
          removeRangeFilterMax={removeRangeFilterMax}
          replaceRangeFilterMin={replaceRangeFilterMin}
          replaceRangeFilterMax={replaceRangeFilterMax}
          rangeFilter={rangeFilter}
          filterCriteriaLength={filterCriteriaLength}
        />
      );
    }
    default: {
      return null;
    }
  }
};

export { FilterItem };
