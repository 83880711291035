import RequestListDTO from "dto/app/requestlist.dto";
import GeneralRepository from "../general.repository";
import IGeneralRepository from "interfaces/irepository.interface";
import { LabelTypes } from "tools/types/labeltypes";

export class LabelRepository
  extends GeneralRepository
  implements IGeneralRepository
{
  constructor() {
    super("label");
  }

  getHash = async (cb?: any, cbParams?: any) => {
    const url = `/${this.mainUrl}/hash?filters=type,${LabelTypes.SITE}`;
    return await this.getObject(url, cb, cbParams);
  };

  getLabel = async (str: string, type: number, cb?: any, cbParams?: any) => {
    if(!str) return;
    const url = `/${this.mainUrl}/getlabel/${str}/${type}`;
    return await this.getObject(url, cb, cbParams);
  };

  getLabelSite = async (cb?: any, cbParams?: any, data?: RequestListDTO) => {
    const url = `/${this.mainUrl}/listlabel`;
    return await this.getListObject(url, cb, cbParams, data);
  };
}
