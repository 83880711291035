import Idto from "../../interfaces/idto.interface";
import MessageDto from "./message.dto";
import RequestListDTO from "./requestlist.dto";
import { ResultMetaDTO } from "./resultmeta.dto";
import ResultObjectDTO from "./resultobject.dto";

export default class ResultListDTO<T = Idto > implements Idto {
  error?: number;
  htmlcode?: number;
  messages?: MessageDto[];
  objects?: T[];
  requestinfo?: RequestListDTO;
  total?: number;
  totalpages?: number;
  obj?: ResultObjectDTO;
  metaObj?: ResultMetaDTO;
}
