import React from "react";

import { LoadScript } from "@react-google-maps/api";
import { Config } from "tools/utils/config";
import IProvider from "interfaces/provider.interface";

export const GoogleMapsProvider: React.FC<IProvider> = ({ children }) => {
  const apiKey = Config.API_KEY_GOOGLE_MAP;

  if (!apiKey) return <>{children}</>;
  return <LoadScript googleMapsApiKey={apiKey}>{children}</LoadScript>;
};
