import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from "@mui/material";
import { OrderDto } from "dto/sale/order.dto";
import React from "react";
import { useLabel } from "hooks/useLabel";
import { CommonTools } from "tools/utils/common.tool";
import { DateTools } from "tools/utils/date.tool";
import { useCountry } from "hooks/useCountry";
import { RouteTools } from "tools/utils/route.tool";

type Props = {
  object: OrderDto;
};

const UserOrderItem: React.FC<Props> = ({ object }) => {
  const { LL } = useLabel();
  const { currencyRate, currencyCode } = useCountry();
  const processPrice = (price: number | string) => {
    return `${price} ${currencyCode}`;
  };

  const handleOnClick = () => {
    if (!object) return;
    RouteTools.setHistory(
      `/orderinfo/${CommonTools.processObjectField(object, ["uuid"])}`,
      {}
    );
  };
  if (!object) return null;
  return (
    <Card
      sx={{
        boxShadow: "none",
        px: { xs: 0, sm: 2, md: 5 },
      }}>
      <CardActionArea onClick={handleOnClick}>
      <CardContent sx={{ px: { xs: 0, sm: 2 } }}>
          <Box
            sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
            <Typography>{LL("status")}</Typography>
            <Typography sx={{ fontWeight: "bold" }}>
              {CommonTools.processObjectField(object, ["status_name"])}
            </Typography>
          </Box>
          <Box
            sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
            <Typography>{LL("date")}</Typography>
            <Typography sx={{ fontWeight: "bold" }}>
              {DateTools.displayTimestampInDateAndHHMM(object.date)}
            </Typography>
          </Box>
          <Box
            sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
            <Typography>{LL("paymethod")}</Typography>
            <Typography sx={{ fontWeight: "bold" }}>
              {CommonTools.processObjectField(object, ["paymethod_name"])}
            </Typography>
          </Box>
          <Box
            sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
            <Typography>{LL("paystatus")}</Typography>
            <Typography sx={{ fontWeight: "bold" }}>
              {CommonTools.processObjectField(object, ["paystatus_name"])}
            </Typography>
          </Box>
          <Box
            sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
            <Typography>{LL("pricedue")}</Typography>
            <Typography sx={{ fontWeight: "bold" }}>
              {processPrice(
                CommonTools.convertPrice(
                  CommonTools.processObjectField(object, ["pricedue"]),
                  currencyRate
                )
              )}
            </Typography>
          </Box>
          <Box
            sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
            <Typography>{LL("realpricetotal")}</Typography>
            <Typography sx={{ fontWeight: "bold" }}>
              {processPrice(
                CommonTools.convertPrice(
                  CommonTools.processObjectField(object, ["realpricetotal"]),
                  currencyRate
                )
              )}
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export { UserOrderItem };
