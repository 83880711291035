export class BreadcrumbDto {
  url: string;
  label: string;
  constructor(label: string, url?: string) {
    this.url = url ?? "";
    this.label = label;
  }

  static prepareBreadcrumbForDisplay(
    breadcrumb: BreadcrumbDto[],
    LL: (str: string) => string
  ): BreadcrumbDto[] {
    return [new BreadcrumbDto(LL("crumb_home"), "/"), ...breadcrumb];
  }
}
