import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import IProvider from "interfaces/provider.interface";
import { CountryDto } from "dto/static/country.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { CountryService } from "services/static/country.service";
import ResultListDTO from "dto/app/resultlist.dto";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import { Status } from "tools/types/status";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { HashDto } from "dto/system/hash.dto";
import { CommonTools } from "tools/utils/common.tool";
import { LocalStorageTools } from "api/localstorage.api";
import { Loading } from "components/elements/loading/Loading";
import { JWTContext } from "./JWTProvider";
import { JWTDto } from "dto/app/jwt.dto";
import { CurrencyService } from "services/static/currency.service";
import { CurrencyDto } from "dto/static/currency.dto";
import { SystemService } from "services/system/system.service";
import { SystemInfoDto } from "dto/system/systeminfo.dto";
import { jwtDecode } from "jwt-decode";

type Props = {
  country: string;
  
  countryObjects: Array<CountryDto>;
  changeCountry: (value: string) => void;
  getSelectedCountry: () => CountryDto | null;
  currencyId: string;
  selectCurrency: (value: string | number) => void;
  getCountryId: (code: string) => number | string;
  currencyObjects: Array<CurrencyDto>;
  getSelectedCurrency: () => CurrencyDto | null;
  changeCurrency: (value: string) => void;
  currencyRate: number;
  currencyCode: string;
};
export const CountryContext = createContext<Props>({
  country: "",
  
  countryObjects: [],
  changeCountry: () => {},
  getSelectedCountry: () => null,
  selectCurrency: () => {},
  currencyId: "",
  getCountryId: () => "",
  currencyObjects: [],
  getSelectedCurrency: () => null,
  changeCurrency: (value: string) => {},
  currencyRate: 1,
  currencyCode: "",
});

var isFetchingList = false;
var isFetchingHash = false;
var isFetchingCurrency = false;
var isFetchingCurrencyHash = false;

const countryService = new CountryService();
const currencyService = new CurrencyService();
const systemService = new SystemService();

export const CountryProvider: React.FC<IProvider> = ({ children }) => {
  const { processToken, token } = useContext(JWTContext);
  
  const [country, setCountry] = useState<string>(getSelectedCountryLocal());
  
  const [countryObjects, setCountryObjects] = useState<Array<CountryDto>>(
    getLocalCountry()
  );
  const [hash, setHash] = useState<string>(getLocalHash());
  const [serverHash, setServerHash] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  

  const [currencyId, setCurrencyId] = useState<string>(getCurrencyId());
  const [currencyObjects, setCurrencyObjects] = useState<Array<CurrencyDto>>(
    getLocalCurrency()
  );
  const [currencyHash, setCurrencyHash] = useState<string>(
    getLocalHashCurrency()
  );
  const [currencyServerHash, setCurrencyServerHash] = useState<string>("");
  const [currencyRate, setCurrencyRate] = useState<number>(1);
  const [currencyCode, setCurrencyCode] = useState<string>("");

  const getCurrencyInfo = useCallback(() => {
    if (!token) return;
    const obj = jwtDecode(token);
    if (!obj) return;
    const rate = CommonTools.processObjectField(obj, ["cr_rate"]);
    const code = CommonTools.processObjectField(obj, ["cr_code"]);
    if (rate) setCurrencyRate(parseInt(rate));
    if (code) setCurrencyCode(code);
  }, [token]);

  useEffect(() => {
    getCurrencyInfo();
  }, [getCurrencyInfo]);

  const selectCurrency = (value: string | number) => {
    setCurrencyId(value.toString());
  };

  const getList = useCallback(() => {
    if (isFetchingList) return;
    isFetchingList = true;
    const req = new RequestListDTO();
    req.page = 1;
    req.onpage = -1;
    req.filters = [
      RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()]),
    ];
    countryService.getList(handleGetList, {}, req);
  }, []);

  const getListCurrency = useCallback(() => {
    if (isFetchingCurrency) return;
    isFetchingCurrency = true;
    const req = new RequestListDTO();
    req.page = 1;
    req.onpage = -1;
    req.filters = [
      RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()]),
    ];
    currencyService.getList(handleGetListCurrency, {}, req);
  }, []);

  const handleGetListCurrency = (result: ResultListDTO) => {
    if (!result) return;
    if (result.error) return;
    if (!result.objects) return;
    const objects = result.objects ? result.objects : [];
    setCurrencyObjects(objects);
    LocalStorageTools.saveObject("currency_objects", objects);
    isFetchingCurrency = false;
  };

  const handleGetList = (result: ResultListDTO) => {
    if (!result) return;
    if (result.error) return;
    if (!result.objects) return;
    const objects = result.objects ? result.objects : [];
    setCountryObjects(objects);
    LocalStorageTools.saveObject("country_objects", objects);
    isFetchingList = false;
  };

  const getHash = useCallback(() => {
    if (isFetchingHash) return;
    isFetchingHash = true;
    countryService.getHash(handleGetHash, {});
  }, []);

  const getCurrencyHash = useCallback(() => {
    if (isFetchingCurrencyHash) return;
    isFetchingCurrencyHash = true;
    currencyService.getHash(handleGetCurrencyHash, {});
  }, []);

  const handleGetCurrencyHash = (result: ResultObjectDTO<HashDto>) => {
    if (!result) return;
    if (result.error) return;
    if (!result.obj) return;
    const rez = new HashDto(
      CommonTools.processObjectField(result, ["obj", "hash"])
    );
    setCurrencyServerHash(rez.hash);
    isFetchingCurrencyHash = false;
  };

  const handleGetHash = (result: ResultObjectDTO<HashDto>) => {
    if (!result) return;
    if (result.error) return;
    if (!result.obj) return;
    const rez = new HashDto(
      CommonTools.processObjectField(result, ["obj", "hash"])
    );
    setServerHash(rez.hash);
    isFetchingHash = false;
  };

  const checkHash = useCallback(() => {
    if (!serverHash) return;
    if (hash === serverHash) return;
    setHash(serverHash);
    LocalStorageTools.saveValue("country_hash", serverHash);
  }, [hash, serverHash]);

  const checkCurrencyHash = useCallback(() => {
    if (!currencyServerHash) return;
    if (currencyHash === currencyServerHash) return;
    setCurrencyHash(currencyServerHash);
    LocalStorageTools.saveValue("currency_hash", currencyServerHash);
  }, [currencyHash, currencyServerHash]);

  useEffect(() => {
    getHash();
    getCurrencyHash();
  }, [getHash, getCurrencyHash]);

  useEffect(() => {
    checkHash();
  }, [checkHash]);

  useEffect(() => {
    checkCurrencyHash();
  }, [checkCurrencyHash]);

  useEffect(() => {
    if (!hash) return;
    if (!serverHash) return;
    if (hash === serverHash && countryObjects.length > 0) return;
    getList();
  }, [hash, serverHash, getList, countryObjects.length]);

  useEffect(() => {
    if (!currencyHash) return;
    if (!currencyServerHash) return;
    if (currencyHash === currencyServerHash && currencyObjects.length > 0)
      return;
    getListCurrency();
  }, [
    currencyHash,
    currencyServerHash,
    getListCurrency,
    currencyObjects.length,
  ]);

  const checkLoading = useCallback(() => {
    let loading = false;
    if (!serverHash) loading = true;
    if (!currencyServerHash) loading = true;
    if (!countryObjects.length) loading = true;

    setLoading(loading);
  }, [serverHash, countryObjects.length, currencyServerHash]);

  useEffect(() => {
    checkLoading();
  }, [checkLoading]);

  const changeCountry = (value: string) => {
    setCountry(value);
    LocalStorageTools.saveValue("selected_country", value);
    countryService.changeByCode(value, handleChangeJWTInfo, {});
  };

  const changeCurrency = (value: string) => {
    setCurrencyId(value);
    LocalStorageTools.saveValue("currency_id", value);
    currencyService.changeById(value, handleChangeJWTInfo, {});
  };

  const handleChangeJWTInfo = (
    result: ResultObjectDTO<JWTDto>,
    cbParams?: any
  ) => {
    if (!result) return;
    if (CommonTools.processNumberToBoolean(result.error)) return;
    const obj = result.obj ? result.obj : null;
    if (!obj) return;
    processToken(obj.token);

    if (cbParams && cbParams.handleDataFirstTime) {
      handleDataFirstTime();
    } else {
      window.location.reload();
    }
  };

  const processDefaultInfo = useCallback(() => {
    if (country) return;
    if (!countryObjects) return;
    if (!countryObjects.length) return;
    setCountry(countryObjects[0].code2 || "");
  }, [countryObjects.length, countryObjects, country]);

  useEffect(() => {
    processDefaultInfo();
  }, [processDefaultInfo]);

  const getSelectedCountry = (): CountryDto | null => {
    if (!country) return null;
    if (!countryObjects) return null;
    if (!countryObjects.length) return null;
    const value = countryObjects.find(
      (item) => item.code2?.toLowerCase() === country.toLowerCase()
    );
    if (!value) return null;
    return value;
  };

  const getSelectedCurrency = (): CurrencyDto | null => {
    if (!currencyId) return null;
    if (!currencyObjects) return null;
    if (!currencyObjects.length) return null;
    const value = currencyObjects.find(
      (item) => item.id?.toString() === currencyId
    );
    if (!value) return null;
    return value;
  };

  const getCountryId = (code: string): number | string => {
    if (!countryObjects) return "";
    if (!countryObjects.length) return "";
    const value = countryObjects.find(
      (item) => item.code2?.toLowerCase() === code.toLowerCase()
    );
    if (!value) return "";
    return value.id || "";
  };

  const processUserInfo = (
    countryCode: string,
    languageCode: string,
    currencyId: string
  ) => {
    systemService.changeInfo(
      handleChangeJWTInfo,
      { handleDataFirstTime },
      new SystemInfoDto(languageCode, currencyId, countryCode)
    );
  };

  const handleDataFirstTime = () => {
    LocalStorageTools.saveValue("default_info_was_processed", "true");
  };

  const processDefaultCurrency = useCallback(() => {
    if (!currencyObjects) return;
    if (!currencyObjects.length) return;
    if (currencyId) return;
    const value = currencyObjects[0];
    if (!value) return;
    setCurrencyId(value.id?.toString() || "");
  }, [currencyObjects, currencyId, currencyObjects.length]);

  useEffect(() => {
    processDefaultCurrency();
  }, [processDefaultCurrency]);

  const value = {
    country,
    countryObjects,
    changeCountry,
    getSelectedCountry,
    selectCurrency,
    currencyId,
    getCountryId,
    currencyObjects,
    getSelectedCurrency,
    changeCurrency,
    currencyRate,
    currencyCode,
  };
  if (loading) return <Loading />;
  return (
    <CountryContext.Provider value={value}>{children}</CountryContext.Provider>
  );
};

const getLocalHash = (): string => {
  const hash = LocalStorageTools.getValue("country_hash");
  if (!hash) return "";
  return hash;
};

const getLocalHashCurrency = (): string => {
  const hash = LocalStorageTools.getValue("currency_hash");
  if (!hash) return "";
  return hash;
};

const getLocalCountry = (): CountryDto[] => {
  const objects = LocalStorageTools.getObject("country_objects");
  if (!objects) return [];
  return objects;
};

const getLocalCurrency = (): CurrencyDto[] => {
  const objects = LocalStorageTools.getObject("currency_objects");
  if (!objects) return [];
  return objects;
};

const getSelectedCountryLocal = () => {
  const country = LocalStorageTools.getValue("selected_country");
  if (!country) return "";
  return country;
};

const getCurrencyId = (): string => {
  const value = LocalStorageTools.getValue("currency_id");
  if (!value) return "";
  return value;
};
