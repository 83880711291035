import { FilterDto } from "dto/product/filter.dto";
import React, { useEffect, useRef, useState } from "react";
import { FilterGeneralItem } from "./FilterGeneralItem";
import { useLabel } from "hooks/useLabel";
import { Box, Stack, TextField } from "@mui/material";

import { CommonTools } from "tools/utils/common.tool";
import { RequestCriteriaDTO } from "dto/app/requestcriteria.dto";

type Props = {
  item: FilterDto;
  removeRangeFilterMin: (id: string) => void;
  removeRangeFilterMax: (id: string) => void;
  replaceRangeFilterMin: (id: string, value: string) => void;
  replaceRangeFilterMax: (id: string, value: string) => void;
  rangeFilter: RequestCriteriaDTO[];
  filterCriteriaLength: number;
};

const FilterValue: React.FC<Props> = ({
  item,
  removeRangeFilterMin,
  removeRangeFilterMax,
  replaceRangeFilterMin,
  replaceRangeFilterMax,
  rangeFilter,
  filterCriteriaLength,
}) => {
  const { LL } = useLabel();

  const inputRef = useRef<HTMLInputElement>(null);
  const secondInputRef = useRef<HTMLInputElement>(null);
  const idFilter = CommonTools.processObjectField(item, ["id"]).toString();
  const filter = rangeFilter.find((filter) => filter.id === idFilter);
  const processDefaultValue = () => {
    if (filter && filter.values && filter.values.length) {
      return filter.values[0];
    } else {
      return "";
    }
  };
  const processDefaultSecondValue = () => {
    if (filter && filter.values && filter.values.length > 1) {
      return filter.values[1];
    } else {
      return "";
    }
  };
  const [value, setValue] = useState<string>(processDefaultValue());
  const [secondValue, setSecondValue] = useState<string>(
    processDefaultSecondValue()
  );

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const onChangeSecondValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSecondValue(e.target.value);
  };

  useEffect(() => {
    if (!filterCriteriaLength) {
      setValue("");
      setSecondValue("");
    }
  }, [filterCriteriaLength]);

  const onBlur = () => {
    if (value) {
      replaceRangeFilterMin(idFilter, value);
    } else {
      removeRangeFilterMin(idFilter);
    }
  };

  const onBlurSecondValue = () => {
    if (secondValue) {
      replaceRangeFilterMax(idFilter, secondValue);
    } else {
      removeRangeFilterMax(idFilter);
    }
  };
  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      inputRef.current?.blur();
      secondInputRef.current?.focus();
    }
  };

  const onKeyPressSecondValue = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      secondInputRef.current?.blur();
    }
  };

  if (!item) return null;
  return (
    <FilterGeneralItem item={item}>
      <Stack
        direction="row"
        spacing={2}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Box>
          <TextField
            label={LL("min_value")}
            variant="standard"
            onChange={onChange}
            value={value}
            onBlur={onBlur}
            inputRef={inputRef}
            onKeyDown={onKeyPress}
            className="standardText2"
          />
        </Box>
        <Box>
          <TextField
            label={LL("max_value")}
            variant="standard"
            onChange={onChangeSecondValue}
            value={secondValue}
            onBlur={onBlurSecondValue}
            inputRef={secondInputRef}
            onKeyDown={onKeyPressSecondValue}
            className="standardText2"
          />
        </Box>
      </Stack>
    </FilterGeneralItem>
  );
};

export { FilterValue };
