
import { CommonTools } from "tools/utils/common.tool";
import { FileDto } from "./file.dto";

import { ConfigTypes } from "tools/types/configtypes";

export class ConfigFileDto {
  id?: number;
  identifier?: string;
  comment?: string;
  type?: number;

  id_file?: number;
  _fileObj?: FileDto | null;

  fullurl?: string;
  downloadurl?: string;
  cdnurl?: string;

  file: File | null;

  constructor(
    id?: number,
    identifier?: string,
    type?: number,
    comment?: string,
    id_file?: number,
    _fileObj?: FileDto,
    fullurl?: string,
    downloadurl?: string,
    cdnurl?: string
  ) {
    this.id = id ?? 0;
    this.id_file = id_file ?? 0;
    this._fileObj = _fileObj ?? null;
    this.fullurl = fullurl ?? "";
    this.downloadurl = downloadurl ?? "";
    this.cdnurl = cdnurl ?? "";
    this.file = null;
    this.identifier = identifier ?? "";
    this.comment = comment ?? "";
    this.type = type ?? ConfigTypes.SITE;
  }

  
  static getLocal(identifier: string): ConfigFileDto {
    return new ConfigFileDto(
      0,
      CommonTools.getPrefixLabel(identifier),
      ConfigTypes.SITE,
    );
  }
  
}
