import { Box, ListItem, Typography } from "@mui/material";
import { OrderProductDto } from "dto/sale/orderproduct.dto";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";
import { Image } from "components/elements/display/Image";
import { useLabel } from "hooks/useLabel";
import { useCountry } from "hooks/useCountry";
import { OrderProductFilterList } from "../orderproductfilter/OrderProductFilterList";

type Props = {
  object: OrderProductDto;
};

const OrderProductItem: React.FC<Props> = ({ object }) => {
  const { LL } = useLabel();
  const { currencyCode, currencyRate } = useCountry();

  const processFilters = () => {
    if (!object) return null;
    if (!object.orderProductFilters) return null;
    if (!Array.isArray(object.orderProductFilters)) return null;
    if (!object.orderProductFilters.length) return null;
    return <OrderProductFilterList objects={object.orderProductFilters} />;
  };
  if (!object) return null;
  return (
    <ListItem sx={{ pr: 2 }}>
      <Box
        sx={{
          width: 100,
          minWidth: 100,
          height: 100,
          maxHeight: 100,
        }}>
        <Image
          url={CommonTools.processObjectField(object, [
            "productObj",
            "_galleryDefault",
            "cdnurl",
          ])}
          withLinK={false}
          sizeInPx={{
            width: 100,
            height: 100,
          }}
        />
      </Box>
      <Box sx={{ ml: 2, width: "100%" }}>
        <Box sx={{ width: "100%" }}>
          <Typography
            sx={{
              // maxWidth: { xs: "120px", sm: "170px" },
              width: "calc(100% - 30px)",
              fontWeight: "bold",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}>
            {CommonTools.processObjectField(object, ["productObj", "_name"])}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            gap: "4px",
          }}>
          <Typography>{LL("Quantity")}</Typography>
          <Typography>
            {CommonTools.processObjectField(object, ["quantity"])}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            gap: "4px",
          }}>
          <Box>
            <Typography>{LL("Price")}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              flexDirection: "row",
              gap: "4px",
            }}>
            <Typography sx={{ fontWeight: "bold" }}>
              {CommonTools.convertPrice(
                CommonTools.processObjectField(object, ["pricerealtotal"]),
                currencyRate
              )}
            </Typography>
            <Typography sx={{ fontWeight: "bold" }}>{currencyCode}</Typography>
          </Box>
        </Box>
        <Box>{processFilters()}</Box>
      </Box>
    </ListItem>
  );
};

export { OrderProductItem };
