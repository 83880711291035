import { Box, Typography } from "@mui/material";
import { OrderDto } from "dto/sale/order.dto";
import { useLabel } from "hooks/useLabel";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";
import { DateTools } from "tools/utils/date.tool";

type Props = {
  object: OrderDto;
};

const OrderInfo: React.FC<Props> = ({ object }) => {
  const { LL } = useLabel();
  if (!object) return null;
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
        <Typography>{LL("status")}</Typography>
        <Typography sx={{ fontWeight: "bold" }}>
          {" "}
          {CommonTools.processObjectField(object, ["status_name"])}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
        <Typography>{LL("date")}</Typography>
        <Typography sx={{ fontWeight: "bold" }}>
          {DateTools.displayTimestampInDateAndHHMM(object.date)}
        </Typography>
      </Box>
    </Box>
  );
};

export { OrderInfo };
