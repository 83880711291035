import { Config } from "tools/utils/config";
import GeneralAxiosRepository from "../generalaxios.repository";
import IGeneralRepository from "interfaces/irepository.interface";
import Idto from "interfaces/idto.interface";

export class JWTRepository
  extends GeneralAxiosRepository
  implements IGeneralRepository
{
  get(
    id: string,
    cb?: (result: any, cbParams: any, data: any) => void,
    cbParams?: any
  ): Promise<any> {
    throw new Error("Method not implemented.");
  }
  getList(
    cb?: (result: any, cbParams: any, data: any) => void,
    cbParams?: any,
    data?: any
  ): Promise<any> {
    throw new Error("Method not implemented.");
  }
  add(
    data?: Idto | undefined,
    cb?: (result: any, cbParams: any, data: any) => void,
    cbParams?: any
  ): Promise<any> {
    throw new Error("Method not implemented.");
  }
  update(
    id: string,
    data?: Idto | undefined,
    cb?: (result: any, cbParams: any, data: any) => void,
    cbParams?: any
  ): Promise<any> {
    throw new Error("Method not implemented.");
  }
  delete(
    id: string,
    cb?: (result: any, cbParams: any, data: any) => void,
    cbParams?: any
  ): Promise<any> {
    throw new Error("Method not implemented.");
  }

  addMultipart(
    cb?: (result: any, cbParams: any, data: any) => void,
    cbParams?: any,
    data?: Idto | undefined
  ): Promise<any> {
    throw new Error("Method not implemented.");
  }
  private mainUrl: string = "jwt";

  generate = async (cb?: any, cbParams?: any) => {
    const url = Config.URL_GENERATE_TOKEN;
    return await this.getObject(url, cb, cbParams);
  };

  refresh = async (cb?: any, cbParams?: any) => {
    const url = `/${this.mainUrl}/refresh`;
    return await this.getObject(url, cb, cbParams);
  };

  updateMultipart(
    id: string,
    cb?: (result: any, cbParams: any, data: any) => void,
    cbParams?: any,
    data?: Idto | undefined
  ): Promise<any> {
    throw new Error("Method not implemented.");
  }
}
