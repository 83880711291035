import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  Menu,
  Typography,
} from "@mui/material";

import { useCart } from "hooks/useCart";
import { useLabel } from "hooks/useLabel";
import React from "react";
import { CartItem } from "./CartItem";
import { CartDto } from "dto/sale/cart.dto";
import { RouteTools } from "tools/utils/route.tool";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { CartTotalPrice } from "./CartTotalPrice";

type Props = {
  anchorEl: null | HTMLElement;
  open: boolean;
  handleClose: () => void;
};
const Cart: React.FC<Props> = ({ anchorEl, open, handleClose }) => {
  const { LL } = useLabel();
  const { cartObjects, cartLength } = useCart();
  const processItem = (item: CartDto, index: number) => {
    if (!item) return null;
    return <CartItem item={item} key={index} handleClose={handleClose} />;
  };

  const handleNavigate = (url: string) => {
    RouteTools.setHistory(url, {});
    handleClose();
  };

  return (
    <Menu
      id="basic-menu-cart"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      variant="menu"
      MenuListProps={{
        "aria-labelledby": "basic-button-cart",
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      className="cart-tmp">
      <ListItem sx={{ minWidth: { xs: "94vw", sm: "auto" } }} key="cart">
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Typography variant="h6" sx={{ width: "auto" }}>
            {LL("Cart")}
          </Typography>
        </Box>
      </ListItem>
      <Box sx={{ px: 4, mb: "4px" }}>
        <Divider
          sx={{
            // backgroundColor: "rgba(0, 0, 0, 0.1)",
            width: "auto",
            height: "1px",
            px: 10,
            py: "2px",
            boxShadow: "none",
            mb: "2px",
            // border: "1px solid rgba(0, 0, 0, 0.1)",
          }}
        />
      </Box>
      <Box
        sx={{
          // minWidth: "350px",
          minHeight: "350px",
          maxHeight: "350px",
          display: "flex",
          justifyContent: cartLength ? "center" : "center",
          alignItems: cartLength ? "flex-start" : "center",
          overflowY: "auto",
        }}>
        <List key="cart_items">
          {/* <Box key="cart_items"> */}
          <Box
            sx={{
              mt: "-10px",
              width: "100%",
              height: { xs: "200px", sm: "100%" },
              display: "flex",
              justifyContent: { xs: "start", md: "center" },
              alignItems: "center",
              flexDirection: "column",
            }}>
            {cartLength === 0 ? (
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}>
                <Typography
                  variant="h6"
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  {LL("emptyCart")}
                </Typography>
              </Box>
            ) : (
              cartObjects.map((item, index) => processItem(item, index))
            )}
          </Box>
          {/* </Box> */}
        </List>
      </Box>
      <Box
        sx={{
          width: "calc(100% - 64px)",
          flexGrow: 1,
          display: "flex",
          px: 4,
          py: 1,
          mt: 1,
        }}>
        <CartTotalPrice />
      </Box>
      <Box sx={{ px: 4 }}>
        <Divider
          sx={{
            // backgroundColor: "rgba(0, 0, 0, 0.1)",
            width: "auto",
            height: "1px",
            px: 10,
            py: "1px",
            boxShadow: "none",
            // border: "1px solid rgba(0, 0, 0, 0.1)",
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: 1,
          py: 1,
          px: 4,
        }}>
        <Box
          className="whiteButton listItemButton"
          sx={{ width: { xs: "100%" }, height: "45px", mb: 2 }}>
          <ListItemButton
            key="cart_checkout"
            onClick={handleNavigate.bind(this, "/checkout")}
            // sx={{
            //   display: "flex",
            //   justifyContent: "center",
            //   alignItems: "center",
            //   background: "black",
            // }}>
            sx={{
              height: "100%",
              // background: "red",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              width: "100%",
              background: "black",
              "&:hover": {
                background: "black",
              },
            }}>
            <Typography
              variant="h6"
              sx={{
                color: "white",
                // fontSize: { xs: "16px", sm: "14px" },
                fontWeight: "bold",
              }}>
              {LL("Go_to_checkout")}
            </Typography>
            <Box
              sx={{
                width: "20px",
                mt: { xs: "0px", sm: "1px" },
                display: { xs: "none", sm: "flex" },
                justifyContent: "center",
                alignItems: "center",
              }}>
              <KeyboardArrowRightIcon className="iconSvg2" />
            </Box>
          </ListItemButton>
        </Box>
      </Box>
    </Menu>
  );
};

export { Cart };
