import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Skeleton,
} from "@mui/material";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import SelectOptions from "dto/app/selectoptions.dto";
import { DeliveryMethodDto } from "dto/delivery/deliverymethod.dto";
import { CartDto } from "dto/sale/cart.dto";
import { useCart } from "hooks/useCart";
import { useLabel } from "hooks/useLabel";
import { useList } from "hooks/useList";
import React, { useEffect } from "react";
import { DeliveryMethodService } from "services/delivery/deliverymethod.service";
import { Status } from "tools/types/status";
import { SkeletonPaymentFormItem } from "components/elements/skeleton/SkeletonPaymentFormItem";

type Props = {
  field?: string;
  value?: number | string;
  setObjectField: (field: string, value: any) => void;
  idlocation: number | string;
};

const service = new DeliveryMethodService();
const RadioSelectDeliveryMethod: React.FC<Props> = ({
  field = "iddeliverymethod",
  value,
  setObjectField,
  idlocation,
}) => {
  const { LL } = useLabel();
  const { cartObjects, setDeliveryMethodOffer } = useCart();
  const getList = (cb?: any, cbParams?: any, data?: RequestListDTO) => {
    if (!idlocation) return;
    service.getList(cb, cbParams, data);
  };

  const totalPrice = CartDto.calculateTotalPriceWithoutCurrency(
    cartObjects ?? []
  );

  const [loading, objects, total] = useList<DeliveryMethodDto>(
    getList,
    new RequestListDTO(
      [
        RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()]),
        RequestFilterDTO.prepareFilter("idlocations", [idlocation.toString()]),
        RequestFilterDTO.prepareFilter("cartvalue", [totalPrice.toString()]),
      ],
      1,
      -1
    ),
    [idlocation, totalPrice]
  );

  useEffect(() => {
    setDeliveryMethodOffer(null);
    if (!objects) return;
    if (!objects.length) return;
    if (!value) return;
    const object = objects.find((obj) => obj.id == value);
    if (!object) return;
    const offer = object.defaultOfferObj ?? null;
    setDeliveryMethodOffer(offer);
  }, [objects, value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setObjectField(field, event.target.value);
  };
  const processItem = (item: SelectOptions, index: number) => {
    return (
      <FormControlLabel
        key={index}
        value={item.value}
        control={<Radio />}
        label={item.label}
      />
    );
  };

  if (!idlocation) return null;
  if (loading) return <SkeletonPaymentFormItem />;
  if (!objects) return null;
  if (!total) return <Box>{LL("no_available_delivery_methods")}</Box>;
  const parseObjects = DeliveryMethodDto.parseToSelectOptions(objects);
  return (
    <FormControl>
      <FormLabel id="delivery_method-label">{LL("delivery_method")}</FormLabel>
      <RadioGroup
        row
        aria-labelledby="delivery_method-label"
        name="delivery_method-group"
        value={value}
        onChange={handleChange}>
        {parseObjects.map((item, index) => processItem(item, index))}
      </RadioGroup>
    </FormControl>
  );
};

export { RadioSelectDeliveryMethod };
