import { MyButton } from "components/elements/button/MyButton";
import { useLanguage } from "providers/LanguageProvider";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Typography } from "@mui/material";
type Props = {
  open: boolean;
  toggleDetail: (event: React.MouseEvent<HTMLButtonElement>) => void;
  colorSvg?: string;
};
const SelectedLanguageButton: React.FC<Props> = ({
  open,
  toggleDetail,
  colorSvg,
}) => {
  const { getCurrentLanguage, languageSiteObjects } = useLanguage();
  const obj = getCurrentLanguage();

  const isDisabled = languageSiteObjects.length === 1;
  const handleClick = (event?: React.MouseEvent<HTMLButtonElement>) => {
    if (isDisabled) return;
    if (event) toggleDetail(event);
  };
  if (!obj) return null;
  return (
    <MyButton
      disabled={isDisabled}
      id="basic-button-language"
      aria-controls={open ? "basic-menu-language" : undefined}
      aria-haspopup={true}
      aria-expanded={open ? true : undefined}
      cb={handleClick}
      variant="text"
      sx={{ color: colorSvg }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
        }}>
        <Typography sx={{ fontSize: "15px", fontWeight: "500", mt: "3px" }}>
          {CommonTools.processObjectField(obj, ["code"])}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
          }}
          className={open ? "rotate-icon active" : "rotate-icon"}>
          <ExpandMoreIcon />
        </Box>
      </Box>
    </MyButton>
  );
};

export { SelectedLanguageButton };
