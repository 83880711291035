import { LocalStorageTools } from "api/localstorage.api";
import { DeliveryMethodOfferDto } from "dto/delivery/deliverymethodoffer.dto";
import { CartDto } from "dto/sale/cart.dto";
import { createDataContext } from "hoc/createDataContext";

export type StateResource = {
  cartObjects: Array<CartDto>;
  deliveryMethodOffer: DeliveryMethodOfferDto | null;
};

export type Actions = {
  addToCart: (product: CartDto) => void;
  removeFromCart: (product: CartDto) => void;
  clearCart: () => void;
  addOrRemoveFromCart: (product: CartDto) => void;
  changeQuantity: (id: string, quantity: number) => void;
  setDeliveryMethodOffer: (offer: DeliveryMethodOfferDto | null) => void;
};

const ADD_TO_CART = "add_to_cart";
const REMOVE_FROM_CART = "remove_from_cart";
const CLEAR_CART = "clear_cart";
const ADD_OR_REMOVE_FROM_CART = "add_or_remove_from_cart";
const CHANGE_QUANTITY = "change_quantity";
const SET_DELIVERY_METHOD_OFFER = "set_delivery_method_offer";

const resourceReducer = (state: StateResource, action: any) => {
  switch (action.type) {
    case ADD_TO_CART: {
      const index = state.cartObjects.findIndex(
        (item) => item.id === action.payload.id
      );
      if (index !== -1) {
        state.cartObjects[index] = action.payload;
      } else {
        state.cartObjects.push(action.payload);
      }

      const newCart = [...state.cartObjects];
      LocalStorageTools.saveObject("cart", newCart);
      return { ...state, cartObjects: newCart };
    }
    case REMOVE_FROM_CART: {
      if (!state.cartObjects.find((item) => item.id === action.payload.id))
        return state;
      const newCart = state.cartObjects.filter(
        (item) => item.id !== action.payload.id
      );
      LocalStorageTools.saveObject("cart", newCart);
      return {
        ...state,
        cartObjects: newCart,
      };
    }
    case CLEAR_CART: {
      LocalStorageTools.saveObject("cart", []);
      return { ...state, cartObjects: [] };
    }
    case ADD_OR_REMOVE_FROM_CART: {
      const index = state.cartObjects.findIndex(
        (item) => item.id === action.payload.id
      );
      if (index >= 0) {
        const cartObjects = [...state.cartObjects];
        cartObjects.splice(index, 1);
        LocalStorageTools.saveObject("cart", cartObjects);
        return { ...state, cartObjects };
      } else {
        const newCart = [...state.cartObjects, action.payload];
        LocalStorageTools.saveObject("cart", newCart);
        return {
          ...state,
          cartObjects: newCart,
        };
      }
    }
    case CHANGE_QUANTITY: {
      const object = state.cartObjects.find(
        (item) => item.id === action.payload.id
      );
      if (!object) return state;
      if (!action.payload.quantity) {
        const newCart = state.cartObjects.filter(
          (item) => item.id !== action.payload.id
        );
        LocalStorageTools.saveObject("cart", newCart);
        return {
          ...state,
          cartObjects: newCart,
        };
      } else {
        object.quantity = action.payload.quantity;
        const newCart = [...state.cartObjects];
        LocalStorageTools.saveObject("cart", newCart);
        return { ...state, cartObjects: newCart };
      }
    }
    case SET_DELIVERY_METHOD_OFFER: {
      return { ...state, deliveryMethodOffer: action.payload };
    }

    default:
      return state;
  }
};

const setDeliveryMethodOffer =
  (dispatch: any) => (offer: DeliveryMethodOfferDto | null) => {
    dispatch({ type: SET_DELIVERY_METHOD_OFFER, payload: offer });
  };

const addToCart = (dispatch: any) => (product: CartDto) => {
  dispatch({ type: ADD_TO_CART, payload: product });
};
const removeFromCart = (dispatch: any) => (product: CartDto) => {
  dispatch({ type: REMOVE_FROM_CART, payload: product });
};
const clearCart = (dispatch: any) => () => {
  dispatch({ type: CLEAR_CART });
};
const addOrRemoveFromCart = (dispatch: any) => (product: CartDto) => {
  dispatch({ type: ADD_OR_REMOVE_FROM_CART, payload: product });
};

const changeQuantity = (dispatch: any) => (id: string, quantity: number) => {
  dispatch({ type: CHANGE_QUANTITY, payload: { id, quantity } });
};

const getLocalCart = () => {
  const cart = LocalStorageTools.getObject("cart");
  if (!cart) return [];
  return cart;
};

export const { Provider, Context } = createDataContext<StateResource, Actions>(
  resourceReducer,
  {
    addToCart,
    removeFromCart,
    clearCart,
    addOrRemoveFromCart,
    changeQuantity,
    setDeliveryMethodOffer,
  },
  {
    cartObjects: getLocalCart(),
    deliveryMethodOffer: null,
  }
);
