import React, { useEffect, useRef } from "react";
import { GoogleMap, GoogleMapProps } from "@react-google-maps/api";

type Props = GoogleMapProps & {
  lat?: number;
  lng?: number;
  children: React.ReactNode;
  bounds?: google.maps.LatLngBounds;
};

const GoogleMaps: React.FC<Props> = React.memo(
  ({ lat = 47.01638, lng = 28.847504, children, bounds, ...props }) => {
    const containerStyle = {
      width: "100%",
      height: "100%",
    };

    const center = {
      lat: lat,
      lng: lng,
    };

    const mapRef = useRef<google.maps.Map | null>(null);

    useEffect(() => {
      if (mapRef.current && bounds) {
        mapRef.current.fitBounds(bounds);
      }
    }, [bounds]);

    const onLoad = (map: google.maps.Map) => {
      mapRef.current = map;

      if (bounds) {
        map.fitBounds(bounds);
      }
    };
    return (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={15}
        clickableIcons={false}
        onLoad={onLoad}
        {...props}
      >
        {children}
      </GoogleMap>
    );
  }
);

export { GoogleMaps };
