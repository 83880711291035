import { Box, Menu, Typography } from "@mui/material";
import RequestSortCriteriaDTO, {
  Sorter,
} from "dto/app/requestsortcriteria.dto";
import React, { useState } from "react";
import { MyButton } from "../button/MyButton";
import { SorterItem } from "./SorterItem";
import { MyIcon } from "../icon/MyIcon";
import { IconType } from "../icon/IconContext";
import { logger } from "tools/utils/logger";

type Props = {
  fields: string[];
  setSortCriteria: (sortCriteria: RequestSortCriteriaDTO) => void;
  sortCriteria?: RequestSortCriteriaDTO | null;
};

const SorterBlock: React.FC<Props> = ({
  fields,
  setSortCriteria,
  sortCriteria,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectItem = (item: RequestSortCriteriaDTO) => {
    setSortCriteria(item);
    handleClose();
  };
  const processItem = (item: Sorter, selected: Sorter) => {
    if (!item) return;
    return (
      <SorterItem
        key={item.label}
        item={item}
        setSortCriteria={selectItem}
        selectedItem={selected}
      />
    );
  };
  const objects = RequestSortCriteriaDTO.prepareSorter(fields);
  if (!sortCriteria) return null;
  const selected = objects.find(
    (item) =>
      item.value.field === sortCriteria.field &&
      item.value.asc === sortCriteria.asc
  );
  if (!selected) return null;
  return (
    <Box sx={{ width: { xs: "100%", sm: "100px" } }}>
      <MyButton
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 1,
          width: "100%",
        }}
        customColor="blackTransparent2"
        id="sort-button"
        cb={handleClick}
        aria-controls={open ? "sort-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="text">
        {selected ? (
          selected.value.asc ? (
            <MyIcon type={IconType.ASC_ICON} />
          ) : (
            <MyIcon type={IconType.DESC_ICON} />
          )
        ) : (
          <MyIcon type={IconType.FILTER_ICON} />
        )}
      </MyButton>
      <Menu
        id="sort-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "sort-button",
        }}>
        {objects.map((item) => processItem(item, selected))}
      </Menu>
    </Box>
  );
};

export { SorterBlock };
