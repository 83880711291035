import React from "react";

import { Box } from "@mui/material";


import { OrderProductFilterDto } from "dto/sale/orderproductfilter.dto";
import { OrderProductFilterListItem } from "./OrderProductFilterListItem";

type Props = {
  objects: OrderProductFilterDto[];
};

const OrderProductFilterList: React.FC<Props> = ({ objects }) => {
  const processItem = (item: OrderProductFilterDto, index: number) => {
    if (!item) return null;
    return <OrderProductFilterListItem item={item} key={index} />;
  };
  if (!objects) return null;
  if (!objects.length) return null;

  return <Box>{objects.map((item, index) => processItem(item, index))}</Box>;
};

export { OrderProductFilterList };
