import React from "react";

import { NavigatorDto } from "dto/static/navigator.dto";
import { SxProps, Typography, Link, Box } from "@mui/material";
import { CommonTools } from "tools/utils/common.tool";

type Props = {
  item: NavigatorDto;
  myStyle?: SxProps;
  linkStyle?: SxProps;
  className?: string;
};

const MenuExternalUrl: React.FC<Props> = ({
  item,
  myStyle = {},
  linkStyle = {},
  className,
}) => {
  if (!item) return null;

  return (
    <Box className={className}>
      <Link
        href={CommonTools.processObjectField(item, ["_fullurl"])}
        target="_blank"
        rel="noreferrer"
        sx={linkStyle}>
        <Typography sx={myStyle} className={"textAnimation"}>
          {CommonTools.processObjectField(item, ["_name"])}
        </Typography>
      </Link>
    </Box>
  );
};

export { MenuExternalUrl };
