import { Box, Typography } from "@mui/material";
import { InfoWindowF, MarkerF } from "@react-google-maps/api";
import { HtmlTag } from "components/elements/display/HtmlTag";
import { ContactPointDTO } from "dto/static/contactpoint.dto";
import React, { useState } from "react";
import { CommonTools } from "tools/utils/common.tool";

type Props = {
  object: ContactPointDTO;
};

const ContactPointItem: React.FC<Props> = ({ object }) => {
  const [open, setOpen] = useState(false);
  const position = new google.maps.LatLng(object.lat || 0, object.lng || 0);
  return (
    <MarkerF
      position={position}
      draggable={true}
      onClick={() => {
        setOpen(true);
      }}
    >
      {open ? (
        <InfoWindowF
          onCloseClick={() => {
            setOpen(false);
          }}
        >
          <Box>
            <Typography variant="h6">
              {CommonTools.processObjectField(object, ["_name"])}
            </Typography>
            <HtmlTag content={CommonTools.processObjectField(object, ["_description"])} />
          </Box>
        </InfoWindowF>
      ) : null}
    </MarkerF>
  );
};

export { ContactPointItem };
