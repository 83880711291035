import * as React from "react";
import { Box, Divider } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Unstable_Grid2";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useResponsive } from "hooks/useResponsive";
import { inherits } from "util";

interface SkeletonProps {
  lines?: number;
  width?: number;
}

const SkeletonCategoryList: React.FC<SkeletonProps> = ({ lines }) => {
  const { matchesSM } = useResponsive();

  let gridItemsCount;
  if (matchesSM) {
    gridItemsCount = 4;
  } else {
    gridItemsCount = 12;
  }

  const gridItem = (
    <Box
      sx={{
        display: "flex",
        justifyContent: "start",
        alignContent: "center",
        // marginX: "auto",
        flexDirection: "column",
        width: "100%",
        mb: 3,
      }}>
      <Skeleton variant="text" width="100px" sx={{ height: "18px" }} />
      <Box sx={{ ml: 2 }}>
        <Skeleton variant="text" width="140px" sx={{ mt: 2, height: "18px" }} />
        <Skeleton variant="text" width="120px" sx={{ mt: 2, height: "18px" }} />
        <Box sx={{ ml: 2 }}>
          <Skeleton
            variant="text"
            width="250px"
            sx={{ mt: 1, height: "18px" }}
          />
          <Skeleton
            variant="text"
            width="170px"
            sx={{ mt: 1, height: "18px" }}
          />
        </Box>
      </Box>
      <Box sx={{ ml: 2 }}>
        <Skeleton variant="text" width="180px" sx={{ mt: 2, height: "18px" }} />
        <Box sx={{ ml: 2 }}>
          <Skeleton
            variant="text"
            width="120px"
            sx={{ mt: 1, height: "18px" }}
          />
          <Skeleton
            variant="text"
            width="170px"
            sx={{ mt: 1, height: "18px" }}
          />
        </Box>
      </Box>
    </Box>
  );

  return (
    <Box sx={{ width: "100%", height: "auto" }} className="">
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "start",
          alignContent: "center",
          //   margin: "auto",
          padding: "20px 0px 20px 0px",
        }}>
        <Grid
          container
          xs={12}
          sx={{ maxWidth: "1522px", width: "100%" }}
          spacing={2}>
          {Array(lines)
            .fill(null)
            .map((_, index) => (
              <React.Fragment key={index}>{gridItem}</React.Fragment>
            ))}
        </Grid>
      </Box>
    </Box>
  );
};

export { SkeletonCategoryList };
