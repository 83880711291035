import React from "react";

import { SocialPageDTO } from "dto/static/socialpage.dto";
import { Box, Stack } from "@mui/material";
import { useList } from "hooks/useList";
import { SocialPageService } from "services/static/socialpage.service";
import RequestListDTO from "dto/app/requestlist.dto";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import { Status } from "tools/types/status";
import { SocialPageItem } from "./SocialPageItem";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";

const service = new SocialPageService();
const SocialPageList: React.FC = () => {
  const getList = (cb?: any, cbParams?: any, req?: RequestListDTO) => {
    service.getList(cb, cbParams, req);
  };

  const [loading, objects] = useList<SocialPageDTO>(
    getList,
    new RequestListDTO(
      [RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()])],
      1,
      -1,
      [RequestSortCriteriaDTO.prepareSortCriteria("order", true)]
    ),
    [],
    true,
    "social_page_site"
  );

  const processItem = (item: SocialPageDTO, index: number) => {
    if (!item) return null;
    return (
      <Box key={index}>
        <SocialPageItem item={item} />
      </Box>
    );
  };
  if (loading) return null;
  if (!objects) return null;
  if(!objects.length) return null;
  return (
    <Stack direction={"row"} spacing={2} alignItems={"center"}>
      {objects.map((item: SocialPageDTO, index: number) =>
        processItem(item, index)
      )}
    </Stack>
  );
};

export { SocialPageList };
