import { Box, Typography } from "@mui/material";
import { ButtonWithLoading } from "components/elements/button/ButtonWithLoading";

import { PasswordField } from "components/elements/form/PasswordField";
import { ChangePasswordDto } from "dto/user/user.dto";
import { useForm } from "hooks/useForm";
import { useLabel } from "hooks/useLabel";
import { CallbackType } from "interfaces/commontypes.interface";

import React, { useState } from "react";
import RequiredValidator from "validators/required.validator";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

type Props = {
  onSubmit: (obj: ChangePasswordDto, cb: CallbackType) => void;
};
const ChangePasswordForm: React.FC<Props> = ({ onSubmit }) => {
  const { LL } = useLabel();
  const [loading, setLoading] = useState(false);

  const customValidation = () => {
    if (!obj.password || !obj.confirmpassword) return true;
    if (obj.password === obj.confirmpassword) {
      return false;
    }
    return true;
  };

  const [obj, disabled, setObjField, , setObject] = useForm<ChangePasswordDto>(
    new ChangePasswordDto(),
    RequiredValidator.getValidators(["confirmpassword", "password"]),
    customValidation
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    onSubmit(obj, cb);
  };

  const cb = () => {
    setLoading(false);
    setObject(new ChangePasswordDto());
  };

  return (
    <Box sx={{ width: "100%", maxWidth: "400px", height: "380px" }}>
      <form onSubmit={handleSubmit}>
        <Box mt={3}>
          <PasswordField
            field="password"
            label={LL("field_password")}
            sx={{ width: "100%" }}
            setObjectField={setObjField}
            value={obj.password}
            selectTheme="standardText"
          />
        </Box>
        <Box mt={3}>
          <PasswordField
            field="confirmpassword"
            sx={{ width: "100%" }}
            label={LL("field_confirmpassword")}
            setObjectField={setObjField}
            value={obj.confirmpassword}
            selectTheme="standardText"
          />
        </Box>
        <Box
          mt={3}
          sx={{ width: "100%", display: "flex", justifyContent: "end" }}>
          <Box
            sx={{ width: { xs: "100%", md: "200px" }, height: "45px" }}
            className={`whiteButton listItemButton ${
              disabled ? "disabled" : ""
            } ${loading ? "loadingBtn" : ""}
            `}>
            <ButtonWithLoading
              type="submit"
              disabled={loading || disabled}
              loading={loading}
              sx={{
                height: "100%",
                // background: "red",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                width: "100%",
              }}>
              <Typography
                sx={{ mt: "4px", fontWeight: "600", ml: { xs: 0, sm: 3 } }}>
                {LL("btn_submit")}
              </Typography>
              <Box
                sx={{
                  width: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                {!disabled && <KeyboardArrowRightIcon className="iconSvg3" />}
              </Box>
            </ButtonWithLoading>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export { ChangePasswordForm };
