import { MySelect } from "components/elements/select/MySelect";
import { CountryDto } from "dto/static/country.dto";
import { useCountry } from "hooks/useCountry";

import React from "react";

type Props = {
  field?: string;
  value?: number | string;
  setObjectField: (field: string, value: any) => void;
  showLabel?: boolean;
  color?: string;
  textColor?: string;
  selectTheme?: any;
};

const CountrySelectId: React.FC<Props> = ({
  field = "destinatar_idcountry",
  value,
  setObjectField,
  showLabel = true,
  selectTheme,
}) => {
  const { countryObjects } = useCountry();

  return (
    <MySelect
      options={CountryDto.parseToSelectOptions(countryObjects, "id")}
      _label={"country"}
      setObjectField={setObjectField}
      value={value}
      field={field}
      showLabel={showLabel}
      selectTheme={selectTheme}
      sx={{ width: "100%" }}
    />
  );
};

export { CountrySelectId };
