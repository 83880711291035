import { MainLayout } from "components/general/MainLayout";

import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

export const RoutesProvider: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<MainLayout />} />
          <Route path="*" element={<MainLayout />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
