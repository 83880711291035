import React, { useState, useEffect } from "react";

import { FilterDictionarDto } from "dto/product/filterdictionar.dto";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { CommonTools } from "tools/utils/common.tool";
import { RequestCriteriaDTO } from "dto/app/requestcriteria.dto";

type Props = {
  item: FilterDictionarDto;
  addCriteriaFilter: (id: string, value: string) => void;
  removeCriteriaFilter: (id: string, value: string) => void;
  criteriaFilter: RequestCriteriaDTO[];
};

const FilterDictionarItem: React.FC<Props> = ({
  item,
  addCriteriaFilter,
  removeCriteriaFilter,
  criteriaFilter,
}) => {
  const [disabled, setDisabled] = useState(false);

  const handleChange = (event: any) => {
    const value = CommonTools.processObjectField(item, ["id"]).toString();
    const idFilter = CommonTools.processObjectField(item, [
      "idfilter",
    ]).toString();

    if (event.target.checked) {
      addCriteriaFilter(idFilter, value);
    } else if (!event.target.checked) {
      removeCriteriaFilter(idFilter, value);
    }
    setDisabled(true);
  };

  useEffect(() => {
    setTimeout(() => {
      setDisabled(false);
    }, 1000);
  }, [disabled]);

  const processChecked = () => {
    const filter = criteriaFilter.find(
      (filter) =>
        filter.id ===
        CommonTools.processObjectField(item, ["idfilter"]).toString()
    );

    if (filter && filter.values && filter.values.length) {
      if (
        filter.values.includes(
          CommonTools.processObjectField(item, ["id"]).toString()
        )
      )
        return true;
      else return false;
    } else return false;
  };
  if (!item) return null;

  return (
    <Box>
      <FormControlLabel
        disabled={disabled}
        control={
          <Checkbox
            className="checkboxBlack"
            onChange={handleChange}
            checked={processChecked()}
          />
        }
        label={CommonTools.processObjectField(item, ["_name"])}
      />
    </Box>
  );
};

export { FilterDictionarItem };
