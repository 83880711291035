import React from "react";

import { Box } from "@mui/material";
import { useCategoryProduct } from "hooks/useCategoryProduct";
import { MyBreadcrumbs } from "components/elements/breadcrumb/MyBreadcrumbs";
import { CategoryProductDto } from "dto/product/categoryproduct.dto";

const CategoryBreadcrumbs: React.FC = () => {
  const { breadcrumbCategory } = useCategoryProduct();

  if (!breadcrumbCategory) return null;
  return (
    <Box sx={{ width: "100%" }}>
      <MyBreadcrumbs
        objects={CategoryProductDto.prepareBreadcrumbs(breadcrumbCategory)}
      />
    </Box>
  );
};

export { CategoryBreadcrumbs };
