import React from "react";

type Props = {
  content?: string | null;
};

const HtmlTag: React.FC<Props> = ({ content }) => {
  if (!content) return null;

  return <div dangerouslySetInnerHTML={{ __html: content }} />;
};

export { HtmlTag };
