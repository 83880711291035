import React from "react";

import { AdvertisementDto } from "dto/static/advertisement.dto";
import { AdvertisementType } from "tools/types/advertisementtype";
import { AdvertisementSection } from "tools/types/advertisementsection";
import { AdvertisementItemHomeTop } from "./AdvertisementItemHomeTop";
import { AdvertisementItemImage } from "./AdvertisementItemImage";
import { AdvertisementItemBenefits } from "./AdvertisementItemBenefits";
import { AdvertisementLoginBenefits } from "./AdvertisementLoginBenefits";
import { AdvertisementVideoItemHomeTop } from "./AdvertisementVideoItemHomeTop";

type Props = {
  item: AdvertisementDto;
  index: number;
  isHomePage?: boolean;
};

const AdvertisementItem: React.FC<Props> = ({ item, index, isHomePage }) => {
  if (!item) return null;

  if (
    item?.type === AdvertisementType.IMAGE_SIMPLE &&
    item?.section === AdvertisementSection.BENEFITS
  )
    return <AdvertisementItemBenefits item={item} index={index} />;
  if (
    item?.type === AdvertisementType.IMAGE_SIMPLE &&
    item?.section === AdvertisementSection.HOME_TOP
  )
    return <AdvertisementItemImage item={item} index={index} />;
  if (
    item?.type === AdvertisementType.IMAGE_CTA &&
    item?.section === AdvertisementSection.HOME_TOP
  )
    return <AdvertisementItemHomeTop item={item} index={index} />;
  if (
    item?.type === AdvertisementType.IMAGE_SIMPLE &&
    item?.section === AdvertisementSection.LOGIN_PAGE_BENEFITS
  )
    return <AdvertisementLoginBenefits item={item} index={index} />;
  if (
    item?.type === AdvertisementType.VIDEO &&
    item?.section === AdvertisementSection.HOME_TOP
  )
    return (
      <AdvertisementVideoItemHomeTop
        item={item}
        index={index}
        isHomePage={isHomePage}
      />
    );
  return null;
};

export { AdvertisementItem };
