import { Skeleton } from "@mui/material";
import { GoogleMaps } from "components/elements/map/GoogleMaps";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { ContactPointDTO } from "dto/static/contactpoint.dto";
import { useList } from "hooks/useList";
import React, { useMemo } from "react";
import { ContactPointService } from "services/static/contactpoint.service";
import { Status } from "tools/types/status";
import { ContactPointItem } from "./ContactPointItem";
import { useConfig } from "hooks/useConfig";

const service = new ContactPointService();
const ContactPointMap: React.FC = () => {
  const getList = (cb?: any, cbParams?: any, data?: RequestListDTO) => {
    service.getList(cb, cbParams, data);
  };
  const [loading, objects] = useList<ContactPointDTO>(
    getList,
    new RequestListDTO(
      [RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()])],
      1,
      -1
    ),
    [],
    true,
    "contact_map"
  );
  const { CC } = useConfig();
  const dLat = parseFloat(CC("DEFAULT_LATITUDE", "47.01638"));
  const dLng = parseFloat(CC("DEFAULT_LONGITUDE", "28.847504"));

  const processItem = (object: ContactPointDTO, index: number) => {
    if (!object) return null;
    return <ContactPointItem key={index} object={object} />;
  };

  const bounds = useMemo(() => {
    if (!objects || objects.length <= 1) return undefined;
    const bounds = new google.maps.LatLngBounds();
    objects.forEach((object) => {
      if (object.lat && object.lng) {
        bounds.extend(new google.maps.LatLng(object.lat, object.lng));
      }
    });
    return bounds;
  }, [objects]);

  if (loading)
    return (
      <Skeleton
        sx={{
          width: { xs: "100%", md: "50%" },
          height: { xs: "400px", md: "600px" },
        }}
      />
    );
  if (!objects) return null;
  return (
    <GoogleMaps bounds={bounds} lat={dLat} lng={dLng}>
      {objects.map((object, index) => processItem(object, index))}
    </GoogleMaps>
  );
};

export { ContactPointMap };
