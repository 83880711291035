import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { CategoryProductDto } from "dto/product/categoryproduct.dto";
import { createDataContext } from "hoc/createDataContext";
import { CategoryProductService } from "services/product/categoryproduct.service";
import { CommonTools } from "tools/utils/common.tool";
import { Status } from "tools/types/status";

export type StateResource = {
  openMainDrawer: boolean;
  mainCategoryObjects: Array<CategoryProductDto> | null;
  totalMainCategoryObjects: number;
  selectedMainCategory: CategoryProductDto | null;
  codeMainCategory: string;
};

export type Actions = {
  handleOpenMainDrawer: () => void;
  closeMainDrawer: () => void;
  closeAllDrawers: () => void;
  getListMainCategory: (
    saveCache: (data: any, identifier: string) => void,
    getCache: (identifier: string) => any
  ) => void;
  selectMainCategory: (category: CategoryProductDto) => void;
};

const service = new CategoryProductService();

const OPEN_MAIN_DRAWER = "open_main_drawer";
const CLOSE_MAIN_DRAWER = "close_main_drawer";
const CLOSE_ALL_DRAWERS = "close_all_drawers";
const SET_LIST_MAIN_CATEGORY = "set_list_main_category";
const SELECT_MAIN_CATEGORY = "select_main_category";


const resourceReducer = (state: StateResource, action: any) => {
  switch (action.type) {
    case OPEN_MAIN_DRAWER: {
      return { ...state, openMainDrawer: true };
    }
    
    case CLOSE_MAIN_DRAWER: {
      return { ...state, openMainDrawer: false };
    }
    case CLOSE_ALL_DRAWERS: {
      return {
        ...state,
        openMainDrawer: false,
        selectedMainCategory: null,
        codeMainCategory: "",
      };
    }
    case SET_LIST_MAIN_CATEGORY: {
      return {
        ...state,
        mainCategoryObjects: action.payload.objects,
        totalMainCategoryObjects: action.payload.total,
      };
    }
    case SELECT_MAIN_CATEGORY: {
      return {
        ...state,
        selectedMainCategory: action.payload,
        openSecondaryDrawer: true,
        codeMainCategory: CommonTools.processObjectField(action, [
          "payload",
          "hierarchicalcode",
        ]),
      };
    }
   

    default:
      return state;
  }
};

const handleOpenMainDrawer = (dispatch: any) => () => {
  dispatch({ type: OPEN_MAIN_DRAWER });
};

const closeMainDrawer = (dispatch: any) => () => {
  dispatch({ type: CLOSE_MAIN_DRAWER });
};

const closeAllDrawers = (dispatch: any) => () => {
  dispatch({ type: CLOSE_ALL_DRAWERS });
};

const getListMainCategory =
  (dispatch: any) =>
  (
    saveCache: (data: any, identifier: string) => void,
    getCache: (identifier: string) => any
  ) => {
    const req = new RequestListDTO();
    req.page = 1;
    req.onpage = -1;
    req.filters = [
      RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()]),
    ];
    req.sortcriteria = [
      RequestSortCriteriaDTO.prepareSortCriteria("order", true),
    ];
    const cache = getCache(JSON.stringify(req));
    if (cache) {
      dispatch({
        type: SET_LIST_MAIN_CATEGORY,
        payload: { objects: cache.objects, total: cache.total },
      });
      return;
    }
    service.getList(
      handleGetListMainCategory,
      { dispatch, saveCache, req },
      req
    );
  };

const handleGetListMainCategory =
  (dispatch: any) =>
  (result: ResultListDTO<CategoryProductDto>, cbParams?: any) => {
    if (!result) return;
    if (result.error) return;
    const objects = result.objects || null;
    const total = result.total || 0;
    dispatch({
      type: SET_LIST_MAIN_CATEGORY,
      payload: { objects, total },
    });
    if (cbParams && cbParams.saveCache) {
      cbParams.saveCache(result, JSON.stringify(cbParams.req));
    }
  };

const selectMainCategory =
  (dispatch: any) => (category: CategoryProductDto) => {
    dispatch({ type: SELECT_MAIN_CATEGORY, payload: category });
  };



export const { Provider, Context } = createDataContext<StateResource, Actions>(
  resourceReducer,
  {
    handleOpenMainDrawer,
    closeMainDrawer,
    closeAllDrawers,
    getListMainCategory,
    selectMainCategory,
  },
  {
    openMainDrawer: false,
    mainCategoryObjects: null,
    totalMainCategoryObjects: 0,
    selectedMainCategory: null,
    codeMainCategory: "",
  }
);
