import * as React from "react";
import { Box, Divider } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Unstable_Grid2";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { inherits } from "util";
import { useResponsive } from "hooks/useResponsive";

interface SkeletonProps {
  lines?: number;
  width?: number;
}

const SkeletonOrderHistory: React.FC<SkeletonProps> = ({ lines }) => {
  const {
    matchesXS,
    matchesSM,
    matchesMD,
    matchesLG,
    downXS,
    downLG,
    downXL,
    downSM,
  } = useResponsive();

  let gridItemsCount;
  if (downSM) {
    gridItemsCount = 12;
  } else if (downLG) {
    gridItemsCount = 12;
  } else if (downXL) {
    gridItemsCount = 12;
  } else {
    gridItemsCount = 12;
  }

  const gridItem = (
    <Grid xs={gridItemsCount}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignContent: "center",
          alignItems: "center",
          // marginX: "auto",
          flexDirection: "column",
          width: "100%",
          mb: 1,
        }}>
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 2,
              width: "100%",
            }}>
            <Skeleton
              variant="text"
              sx={{ fontSize: { xs: "16px", md: "14px" }, width: "190px" }}
            />
            <Skeleton
              variant="text"
              sx={{ fontSize: { xs: "16px", md: "14px" }, width: "90px" }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 2,
            }}>
            <Skeleton
              variant="text"
              sx={{ fontSize: { xs: "16px", md: "14px" }, width: "120px" }}
            />
            <Skeleton
              variant="text"
              sx={{ fontSize: { xs: "16px", md: "14px" }, width: "140px" }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 2,
            }}>
            <Skeleton
              variant="text"
              sx={{ fontSize: { xs: "16px", md: "14px" }, width: "140px" }}
            />
            <Skeleton
              variant="text"
              sx={{ fontSize: { xs: "16px", md: "14px" }, width: "90px" }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 2,
            }}>
            <Skeleton
              variant="text"
              sx={{ fontSize: { xs: "16px", md: "14px" }, width: "100px" }}
            />
            <Skeleton
              variant="text"
              sx={{ fontSize: { xs: "16px", md: "14px" }, width: "150px" }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 2,
            }}>
            <Skeleton
              variant="text"
              sx={{ fontSize: { xs: "16px", md: "14px" }, width: "120px" }}
            />
            <Skeleton
              variant="text"
              sx={{ fontSize: { xs: "16px", md: "14px" }, width: "150px" }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 2,
            }}>
            <Skeleton
              variant="text"
              sx={{ fontSize: { xs: "16px", md: "14px" }, width: "150px" }}
            />
            <Skeleton
              variant="text"
              sx={{ fontSize: { xs: "16px", md: "14px" }, width: "80px" }}
            />
          </Box>
        </Box>
      </Box>
    </Grid>
  );

  return (
    <Box sx={{ width: "100%", height: "auto" }} className="">
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          maxHeight: { xs: "630px", md: "770px" },

          //   margin: "auto",
          //   padding: "20px 0px 20px 0px",
        }}>
        <Grid
          container
          sx={{
            // maxWidth: "1522px",
            width: "100%",
            overflow: "hidden",
          }}
          spacing={2}>
          {Array(lines)
            .fill(null)
            .map((_, index) => (
              <React.Fragment key={index}>{gridItem}</React.Fragment>
            ))}
        </Grid>
      </Box>
    </Box>
  );
};

export { SkeletonOrderHistory };
