import React from "react";
import {
  Typography,
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useLabel } from "hooks/useLabel";

type ConfirmDialogProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  titleDialog: string;
  contentDialog: string;
  setTitleDialog: any;
  setContentDialog: any;
  handleConfirm: () => void;
  handleCancel: () => void;
};

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  open,
  setOpen,
  titleDialog,
  contentDialog,
  setTitleDialog,
  setContentDialog,
  handleConfirm,
  handleCancel,
  ...props
}) => {
  const {LL} = useLabel();
  const handleClose = () => {
    if (handleCancel === undefined) {
      setOpen(false);
      setTitleDialog("");
      setContentDialog("");
      return;
    }
    handleCancel();
    setTitleDialog("");
    setContentDialog("");
    setOpen(false);
  };

  const confirmAction = () => {
    if (handleConfirm === undefined) {
      setTitleDialog("");
      setContentDialog("");
      setOpen(false);
      return;
    }
    handleConfirm();
    setTitleDialog("");
    setContentDialog("");
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{titleDialog}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography>{contentDialog}</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={confirmAction} color="secondary">
          {LL("Confirm")}
        </Button>
        <Button onClick={handleClose} color="primary" autoFocus>
          {LL("Cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { ConfirmDialog };
