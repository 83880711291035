import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
} from "@mui/material";
import { BlogDto } from "dto/static/blog.dto";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";
import { logger } from "tools/utils/logger";
import { RouteTools } from "tools/utils/route.tool";

type Props = {
  item: BlogDto;
};

const BlogItem: React.FC<Props> = ({ item }) => {
  const navigate = () => {
    const url = CommonTools.processObjectField(item, ["_urlObj", "urlfull"]);
    if (!url) return;
    RouteTools.setHistory(url, {});
  };
  if (!item) return null;
  return (
    <Card
      sx={{
        width: "100%",
        height: "100%",
        position: "relative",
        boxShadow: "none",
        borderRadius: 0,
      }}>
      <CardActionArea onClick={navigate}>
        <CardMedia
          sx={{}}
          component="img"
          height="345"
          image={CommonTools.processImageUrl(
            CommonTools.processObjectField(item, ["_galleryDefault", "cdnurl"]),
            345,
            345
          )}
          alt={CommonTools.processObjectField(item, ["_name"])}
        />

        <CardContent sx={{ px: 1 }}>
          <Typography sx={{ color: "gray", fontSize: 12 }}>
            {CommonTools.processObjectField(item, ["refcode"])}
          </Typography>
          <Typography
            sx={{
              fontSize: 16,
              width: "calc(100% - 60px)",
            }}>
            {CommonTools.processObjectField(item, ["_name"])}
          </Typography>
          <Typography
            sx={{
              fontSize: 12,
              width: "calc(100% - 60px)",
            }}>
            {CommonTools.processObjectField(item, ["_shortdescription"])}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export { BlogItem };
