import React from "react";

import { Box } from "@mui/material";
import { useProduct } from "hooks/useProduct";
import { Image } from "components/elements/display/Image";
import { CommonTools } from "tools/utils/common.tool";
import { GalleryCarousel } from "./GalleryCarousel";

import { NoImage } from "components/elements/display/NoImage";

const ProductMedia: React.FC = () => {
  const { product } = useProduct();
  if (!product) return null;
  if (!product._galleryDefault) return <NoImage />;
  if (!product._galleryActiveList) return null;
  if (product._galleryActiveList.length === 1)
    return (
      <Box
        sx={{
          maxHeight: "844px",
          height: { xs: "auto", md: "910px" },
          overflow: "hidden",
        }}>
        <Image
          url={CommonTools.processObjectField(product, [
            "_galleryDefault",
            "cdnurl",
          ])}
          typeMeasure="percentage"
          // sizeInPercentage={{ width: "100%", height: "90%" }}
          withLinK={false}
          style={{
            objectFit: "cover",
            width: "100%",
          }}
        />
      </Box>
    );
  return (
    <Box>
      <GalleryCarousel objects={product._galleryActiveList} />
    </Box>
  );
};

export { ProductMedia };
