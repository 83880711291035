import SelectOptions from "dto/app/selectoptions.dto";

export class Status {
  public static _LL: any = false;
  public static setLL(_l: any): any {
    this._LL = _l;
  }

  public static ACTIVE = 1;
  public static INACTIVE = 2;
  public static USER_ACTIVE = 10;
  public static USER_NEEDVERIFY = 11;
  public static USER_BLOCKED = 12;

  public static UNPROCESSED = 20;
  public static PROCESSED = 21;
  public static NEEDPROCESSED = 22;

  public static ORD_P_UNPAID = 50;
  public static ORD_P_PAID = 51;
  public static ORD_P_NEEDRETURN = 52;
  public static ORD_P_CANCELED = 53;
  public static ORD_P_RETURNED = 54;

  public static _constants: { [key: number]: string } = {
    1: "ACTIVE",
    2: "INACTIVE",
    10: "USER_ACTIVE",
    11: "USER_NEEDVERIFY",
    12: "USER_BLOCKED",
    20: "UNPROCESSED",
    21: "PROCESSED",
    22: "NEEDPROCESSED",
    50: "ORD_P_UNPAID",
    51: "ORD_P_PAID",
    52: "ORD_P_NEEDRETURN",
    53: "ORD_P_CANCELED",
    54: "ORD_P_RETURNED",

  };

  
  public static LL = (key: any) => {
    const k: number = parseInt(key);

    if (!this._constants.hasOwnProperty(k)) return "";

    return this._LL("Status_" + this._constants[k].toString());
  };

  public static GA_KEYS = (key: string): Array<number> => {
    let rez: Array<number> = [];

    if (key === "clasificator") {
      rez = [this.ACTIVE, this.INACTIVE];
    }

    if (key === "user") {
      rez = [this.USER_ACTIVE, this.USER_NEEDVERIFY, this.USER_BLOCKED];
    }

    if (key === "status") {
      rez = [this.UNPROCESSED, this.PROCESSED];
    }

    if (key === "orderpayment") {
      rez = [
        this.ORD_P_UNPAID,
        this.ORD_P_PAID,
        this.ORD_P_NEEDRETURN,
        this.ORD_P_CANCELED,
        this.ORD_P_RETURNED,
      ];
    }
    return rez;
  };

  public static GA = (
    key: string,
    emptyValue?: boolean,
    emptyLabel?: string
  ) => {
    const keys = this.GA_KEYS(key);

    const rez: Array<SelectOptions> = [];
    if (emptyValue) {
      const empty = new SelectOptions();
      empty.value = "";
      empty.label = emptyLabel ?? Status._LL("all");
      rez.push(empty);
    }
    keys.forEach((k) => {
      const so = new SelectOptions();
      so.value = k;
      so.label = Status.LL(k);
      rez.push(so);
    });

    return rez;
  };
}
