import React from "react";

import { Box, Typography } from "@mui/material";
import { OrderProductFilterDto } from "dto/sale/orderproductfilter.dto";
import { CommonTools } from "tools/utils/common.tool";

type Props = {
  item: OrderProductFilterDto;
};

const OrderProductFilterListItem: React.FC<Props> = ({ item }) => {
  if (!item) return null;
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          flexDirection: "row",
          gap: "2px",
        }}
      >
        <Typography sx={{ fontWeight: "bold", fontSize: "12px" }}>
          {CommonTools.processObjectField(item, ["filterObj", "_name"])}:
        </Typography>
        <Typography sx={{ fontSize: "12px" }}>
          {CommonTools.processObjectField(item, [
            "filterDictionarObj",
            "_name",
          ])}
        </Typography>
      </Box>
    </Box>
  );
};

export { OrderProductFilterListItem };
