import * as React from "react";
import { Box, Divider } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Unstable_Grid2";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { inherits } from "util";
import { useResponsive } from "hooks/useResponsive";

interface SkeletonProps {
  lines?: number;
  width?: number;
}

const SkeletonProductList: React.FC<SkeletonProps> = ({ lines }) => {
  const {
    matchesXS,
    matchesSM,
    matchesMD,
    matchesLG,
    downXS,
    downLG,
    downXL,
    downMD,
    downSM,
  } = useResponsive();

  let gridItemsCount;
  if (downMD) {
    gridItemsCount = 12;
  } else if (downLG) {
    gridItemsCount = 6;
  } else if (downXL) {
    gridItemsCount = 4;
  } else {
    gridItemsCount = 3;
  }

  const gridItem = (
    <Grid xs={gridItemsCount}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignContent: "center",
          alignItems: "center",
          // marginX: "auto",
          flexDirection: "column",
          width: "100%",
        }}>
        <Box sx={{ width: "94%", height: "595px", maxWidth: "460px" }}>
          <Skeleton
            variant="rectangular"
            sx={{ minHeight: { xs: "70vw", sm: "435px" } }}
          />
          <Box sx={{ px: 1 }}>
            <Skeleton
              variant="text"
              sx={{ height: "30px", width: { xs: "90%", md: "300px" }, mt: 1 }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              px: 1,
              mt: 3,
            }}>
            <Skeleton
              variant="text"
              sx={{ height: "25px", width: { xs: "90px", md: "130px" } }}
            />
            <Skeleton
              variant="text"
              sx={{ height: "25px", width: { xs: "90px", md: "130px" } }}
            />
          </Box>
        </Box>
      </Box>
    </Grid>
  );

  return (
    <Box sx={{ width: "100%", height: "auto", py: 4 }} className="">
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          //   maxHeight: { xs: "630px", md: "770px" },

          //   margin: "auto",
          //   padding: "20px 0px 20px 0px",
        }}>
        <Grid
          container
          sx={{
            // maxWidth: "1522px",
            width: "100%",
            overflow: "hidden",
            maxWidth: "1920px",
            px: { xs: 4, sm: 6, md: 10 },
          }}
          spacing={2}>
          {Array(lines)
            .fill(null)
            .map((_, index) => (
              <React.Fragment key={index}>
                {Array(4)
                  .fill(null)
                  .map((_, index) => (
                    <React.Fragment key={index}>{gridItem}</React.Fragment>
                  ))}
              </React.Fragment>
            ))}
        </Grid>
      </Box>
    </Box>
  );
};

export { SkeletonProductList };
