import { MyPagination } from "components/elements/pagination/MyPagination";
import { useBlogCategory } from "hooks/useBlogCategory";
import React from "react";


const BlogPagination: React.FC = () => {
  const { pageBlog, setPageBlog, totalPagesBlogs, totalBlogs } =
    useBlogCategory();

  if (totalPagesBlogs === -1) return null;
  if (totalBlogs === -1) return null;
  if (!totalBlogs) return null;
  return (
    <MyPagination
      setPage={setPageBlog}
      totalPage={totalPagesBlogs}
      total={totalBlogs}
      page={pageBlog}
    />
  );
};

export { BlogPagination };
