import React from "react";
import { MyButton, MyButtonProps } from "./MyButton";
import { Box, Stack } from "@mui/material";
import { CircularLoading } from "../loading/CircularLoading";

type Props = MyButtonProps & {
  loading: boolean;
  maxWidth?: string;
};

const ButtonWithLoading: React.FC<Props> = ({
  loading,
  children,
  maxWidth = "400px",
  ...props
}) => {
  return (
    <MyButton
      {...props}
      fullWidth
      sx={{
        maxWidth: maxWidth,
        // borderRadius: 20,
        height: "100%",
        width: "100%",
      }}>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent={"center"}
        width={"100%"}>
        {loading && (
          <CircularLoading
            size={15}
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: -12,
              marginLeft: -22,
              zIndex: 1,
            }}
          />
        )}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            ml: 0,
          }}>
          {children}
        </Box>
      </Stack>
    </MyButton>
  );
};

export { ButtonWithLoading };
