import { Box } from "@mui/material";
import { AttachmentList } from "components/elements/attachment/AttachmentList";
import { PageBreadcrumb } from "components/static/page/content/PageBreadcrumb";
import { PageContent } from "components/static/page/content/PageContent";
import { PageMainImage } from "components/static/page/content/PageMainImage";
import { PageShortDescription } from "components/static/page/content/PageShortDescription";
import { PageTitle } from "components/static/page/content/PageTitle";
import { PageVideo } from "components/static/page/content/PageVideo";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { PageDto } from "dto/static/page.dto";
import { IPage } from "interfaces/page.interface";
import React, { useCallback, useEffect, useState } from "react";
import { PageService } from "services/static/page.service";
import { CommonTools } from "tools/utils/common.tool";
import { GalleryList } from "components/elements/gallery/GalleryList";
import { SkeletonBreadCrumb } from "components/elements/skeleton/SkeletonBreadCrumbs";
import { SkeletonStaticPage } from "components/elements/skeleton/blog/SkeletonStaticPage";
import { useResponsive } from "hooks/useResponsive";
import { Config } from "tools/utils/config";

const service = new PageService();
const StaticPage: React.FC<IPage> = ({ currentRoute }) => {
  const [object, setObject] = useState<PageDto | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [idObject, setIdObject] = useState<string>("");

  const {  matchesMD } = useResponsive();

  const getObjectInfo = useCallback(() => {
    let id = CommonTools.processObjectField(currentRoute, [
      "processedData",
      "idobj",
    ]);
    if (!id) {
      id = CommonTools.processObjectField(currentRoute, [
        "processedData",
        "_obj",
        "id",
      ]);
    }
    if (!id) {
      id = CommonTools.processObjectField(currentRoute, [
        "processedData",
        "_getVars",
        "id",
      ]);
    }
    const object =
      currentRoute &&
      currentRoute.processedData &&
      currentRoute.processedData._obj
        ? currentRoute.processedData._obj
        : null;

    setIdObject(id);
    setObject(object);
  }, [currentRoute]);

  const getObject = useCallback(() => {
    if (!idObject) return;
    if (object && object.id && object.id === parseInt(idObject)) return;
    setLoading(true);
    service.get(idObject, handleGet, {});
  }, [idObject, object]);

  const handleGet = (result: ResultObjectDTO<PageDto>) => {
    if (!result) return;
    if (result.error) return;
    const object = result.obj ? result.obj : null;
    setObject(object);
  };
  const checkLoading = useCallback(() => {
    let loading = false;
    if (!object) loading = true;
    setLoading(loading);
  }, [object]);

  useEffect(() => {
    getObjectInfo();
  }, [getObjectInfo]);

  useEffect(() => {
    checkLoading();
  }, [checkLoading]);

  useEffect(() => {
    getObject();
  }, [getObject]);

  if (loading)
    return (
      <Box sx={{ width: "100%" }}>
        <SkeletonBreadCrumb />
        <SkeletonStaticPage />
      </Box>
    );
  if (!object) return null;
  return (
    <Box
      sx={{
        position: "relative",
        // height: matchesHeight ? "calc(100vh - 66px)" : "auto",

        // height: "100vh",
        overflow: "auto",
        mt: "-118px",
        maxWidth: "1920px",
        marginX: "auto",
        minHeight: `calc(100vh - ${Config.HEADER_HEIGHT}px - ${
          matchesMD ? Config.FOOTER_HEIGHT : Config.FOOTER_HEIGHT_MOBILE
        }px + 118px)`,
      }}>
      <Box
        sx={{
          position: "relative",
          top: "-118px",
          left: 0,
          width: "100%",
          height: "118px",
          background: "white",
          zIndex: 0,
        }}
      />
      <Box
        sx={{
          position: "fixed",
          width: "100%",
          height: { xs: "50vh", md: "70vh" },
          minHeight: "400px",
          zIndex: -1,
          display: "flex",
          justifyContent: "center",
          overflow: "hidden",
          backgroundRepeat: "no-repeat",
        }}>
        <Box
          sx={{
            minWidth: "900px",
            width: "100%",
            height: "100%",
            objectFit: "cover",
            backgroundPosition: "center",
          }}>
          <PageMainImage object={object} />
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              maxWidth: "1920px",
            }}
          />
        </Box>
      </Box>
      <Box
        className="breadcrumbBox"
        sx={{
          width: "auto",
          px: { xs: 4, sm: 6, md: 10 },
          py: 3,
          background: "white",
          position: "relative",
          "::before": {
            content: '""',
            position: "absolute",
            top: "-180px",
            left: 0,
            height: "180px",
            width: "100%",
            background: "white",
          },
        }}>
        <PageBreadcrumb object={object} />
      </Box>
      <Box
        data-aos="zoom-in"
        data-aos-delay="150"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: { xs: "250px", md: "400px" },
          position: "relative",
          zIndex: 1,
          textAlign: "center",
        }}>
        <PageTitle object={object} textColor="white" />
      </Box>
      <Box
        sx={{
          background: "white",
          minHeight: `calc(100vh - ${Config.HEADER_HEIGHT}px - ${
            matchesMD ? Config.FOOTER_HEIGHT : Config.FOOTER_HEIGHT_MOBILE
          }px - ${matchesMD ? 400 : 250}px - 67px  )`,
        }}>
        <Box data-aos="zoom-in" data-aos-delay="150">
          <PageShortDescription object={object} />
        </Box>
        <Box>
          <GalleryList objects={object._galleryActiveList} excludeDefault />
        </Box>
        <Box
          sx={{
            // background: "white",
            py: 6,
            px: { xs: 4, sm: 6, md: 10 },
            width: "auto",
            display: "flex",
            // height: matchesHeight ? "calc(100vh - 846px)" : "auto",
            justifyContent: "center",
            // minHeight: `calc(100vh - ${Config.HEADER_HEIGHT}px - ${Config.FOOTER_HEIGHT}px - 400px - 66px - 96px)`,
          }}>
          <Box
            sx={{
              width: "100%",
              maxWidth: "800px",
              display: "flex",
              justifyContentL: "center",
              flexDirection: "column",
              // textAlign: "center",
            }}>
            <Box data-aos="zoom-in" data-aos-delay="150" sx={{ mb: 3 }}>
              <PageContent object={object} />
            </Box>
            <PageVideo object={object} />
            <AttachmentList objects={object._attachmentActiveList} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export { StaticPage };
