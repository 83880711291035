import React from "react";

import { Box, IconButton } from "@mui/material";
import { useDrawer } from "hooks/useDrawer";
import CloseIcon from "@mui/icons-material/Close";

const DrawerCloseButton: React.FC = () => {
  const { closeAllDrawers } = useDrawer();

  return (
    <Box
      className="drawerCloseButton"
      sx={{
        mt: 3,
        mr: 1,
        width: "auto",
        position: "absolute",
        right: "20px",
        top: 0,
        zIndex: 10,
      }}>
      <IconButton onClick={closeAllDrawers}>
        <CloseIcon className="drawerSvg" />
      </IconButton>
    </Box>
  );
};

export { DrawerCloseButton };
