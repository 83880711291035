import { Box } from "@mui/material";
import {
  ProductFilterDto,
  DisplayProductFilterDictionar,
  DisplayProductFilter,
} from "dto/product/productfilter.dto";
import { useProduct } from "hooks/useProduct";
import React from "react";
import { ProductFilterItemValue } from "./ProductFilterItemValue";
import { ProductFilterItemDictionar } from "./ProductFilterItemDictionar";

const ProductFilterList: React.FC = () => {
  const { productFilterObjects } = useProduct();

  const processDictinonar = (objects: DisplayProductFilterDictionar[]) => {
    if (!objects) return null;
    if (!objects.length) return null;
    return objects.map((item, index) => {
      return <ProductFilterItemDictionar key={index} item={item} />;
    });
  };
  const processValues = (objects: DisplayProductFilter[]) => {
    if (!objects) return null;
    if (!objects.length) return null;
    return objects.map((item, index) => {
      return <ProductFilterItemValue item={item} key={index} />;
    });
  };
  if (!productFilterObjects) return null;
  if (!productFilterObjects.length) return null;
  const objects = ProductFilterDto.groupByFilterId(productFilterObjects);
  return (
    <Box sx={{}}>
      <Box>{processDictinonar(objects.displayFilterDictinonar)}</Box>
      <Box sx={{ mt: "4px" }}>{processValues(objects.displayFilterValues)}</Box>
    </Box>
  );
};

export { ProductFilterList };
