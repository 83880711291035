import Idto from "interfaces/idto.interface";

export class DictionaryDTO implements Idto {
  key?: number;
  value?: string;
  constructor(key?: number, value?: string) {
    this.key = key || 0;
    this.value = value || "";
  }
}
