import { Box, Skeleton } from "@mui/material";
import { MyBreadcrumbs } from "components/elements/breadcrumb/MyBreadcrumbs";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { BlogDto } from "dto/static/blog.dto";
import { CategoryBlogDto } from "dto/static/categoryblog.dto";
import { useList } from "hooks/useList";
import React from "react";
import { CategoryBlogService } from "services/static/categoryblog.service";
import { Status } from "tools/types/status";
import { CommonTools } from "tools/utils/common.tool";

type Props = {
  object: BlogDto;
};
const service = new CategoryBlogService();
const BlogBreadcrumb: React.FC<Props> = ({ object }) => {
  const codeCategory = CommonTools.processObjectField(object, ["categorycode"]);

  const getList = (cb?: any, cbParams?: any, req?: RequestListDTO) => {
    service.getList(cb, cbParams, req);
  };

  const [loading, objects] = useList<CategoryBlogDto>(
    getList,
    new RequestListDTO(
      [
        RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()]),
        RequestFilterDTO.prepareFilter("hpcodes", [codeCategory]),
      ],
      1,
      -1,
      [RequestSortCriteriaDTO.prepareSortCriteria("level", true)]
    ),
    [codeCategory]
  );

  if (loading) return <Skeleton />;
  if (!objects) return null;
  if (!objects.length) return null;

  return (
    <Box sx={{ width: "100%" }}>
      <MyBreadcrumbs objects={CategoryBlogDto.prepareBreadcrumbs(objects)} />
    </Box>
  );
};

export { BlogBreadcrumb };
