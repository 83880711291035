import React from "react";

import { ProductDto } from "dto/product/product.dto";
import { Box, Typography } from "@mui/material";
import { CommonTools } from "tools/utils/common.tool";
import { OfferBlock } from "./OfferBlock";

type Props = {
  item: ProductDto;
};

const ProductShortInfo: React.FC<Props> = ({ item }) => {
  return (
    <Box>
      <Box>
        <Typography sx={{ fontSize: "16px", color: "gray" }}>
          {CommonTools.processObjectField(item, ["refcode"])}
        </Typography>
      </Box>

      <Box>
        <Typography sx={{ fontSize: "24px" }}>
          {CommonTools.processObjectField(item, ["_name"])}
        </Typography>
      </Box>
      <Box sx={{ mt: 3 }}>
        <OfferBlock />
      </Box>
      <Box sx={{ mt: 4 }}>
        <Typography sx={{ fontSize: "16px" }}>
          {CommonTools.processObjectField(item, ["_shortdescription"])}
        </Typography>
      </Box>
    </Box>
  );
};
export { ProductShortInfo };
