import React from "react";

import { NavigatorDto } from "dto/static/navigator.dto";
import { SxProps, Typography } from "@mui/material";
import { CommonTools } from "tools/utils/common.tool";

type Props = {
  item: NavigatorDto;
  myStyle?: SxProps;
  className?: string;
};

const MenuNoUrl: React.FC<Props> = ({ item, myStyle = {}, className }) => {
  if (!item) return null;

  return (
    <Typography sx={myStyle} className={className}>
      {CommonTools.processObjectField(item, ["_name"])}
    </Typography>
  );
};

export { MenuNoUrl };
