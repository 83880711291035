import { MyPagination } from "components/elements/pagination/MyPagination";
import { useCategoryProduct } from "hooks/useCategoryProduct";
import React from "react";

const ProductPagination: React.FC = () => {
  const { pageProducts, setPageProducts, totalPagesProducts, totalProducts } =
    useCategoryProduct();

  if (totalPagesProducts === -1) return null;
  if (totalProducts === -1) return null;
  if (!totalProducts) return null;
  return (
    <MyPagination
      setPage={setPageProducts}
      totalPage={totalPagesProducts}
      total={totalProducts}
      page={pageProducts}
    />
  );
};

export { ProductPagination };
