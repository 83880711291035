import React, { useState } from "react";
import { LanguageDto } from "dto/static/language.dto";
import { Box, Menu } from "@mui/material";
import { useLanguage } from "providers/LanguageProvider";
import { LanguageSiteOption } from "./LanguageSiteOption";
import { SelectedLanguageButton } from "./SelectedLanguageButton";
import { CommonTools } from "tools/utils/common.tool";

type Props = {
  colorSvg?: string;
};

const LanguageSiteOptions: React.FC<Props> = ({ colorSvg }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { languageSiteObjects } = useLanguage();

  const toggleDetail = (event: React.MouseEvent<HTMLButtonElement>) => {
    const t: boolean = !open;
    setOpen(t);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  if (!languageSiteObjects || !languageSiteObjects.length) return null;

  return (
    <React.Fragment>
      <Box sx={{ px: 0 }}>
        <SelectedLanguageButton
          open={open}
          toggleDetail={toggleDetail}
          colorSvg={colorSvg}
        />
      </Box>
      <Menu
        id="basic-menu-language"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button-language",
        }}>
        {languageSiteObjects.map((item: LanguageDto) => (
          <LanguageSiteOption
            key={CommonTools.processObjectField(item, ["id"])}
            item={item}
            toggleDetail={toggleDetail}
          />
        ))}
      </Menu>
    </React.Fragment>
  );
};

export { LanguageSiteOptions };
