import { Link } from "@mui/material";
import { useConfig } from "hooks/useConfig";
import React from "react";

const DrawerItemPhone: React.FC = () => {
  const { CC } = useConfig();
  const phone = CC("contact_phone_drawer", "0");
  if (phone === "0") return null;
  return (
    <Link href={`tel:${phone}`} sx={{ color: "#000", textDecoration: "none" }}>
      {phone}
    </Link>
  );
};

export { DrawerItemPhone };
