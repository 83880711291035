import * as React from "react";
import { Box, Divider } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Unstable_Grid2";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { inherits } from "util";
import { useResponsive } from "hooks/useResponsive";
import { SkeletonGalleryHome } from "../SkeletonGalleryHome";

interface SkeletonProps {
  lines?: number;
  width?: number;
}

const SkeletonStaticPage: React.FC<SkeletonProps> = ({ lines }) => {
  const {
    matchesXS,
    matchesSM,
    matchesMD,
    matchesLG,
    downXS,
    downLG,
    downXL,
    downSM,
  } = useResponsive();

  let gridItemsCount;
  if (downSM) {
    gridItemsCount = 12;
  } else if (downXL) {
    gridItemsCount = 6;
  } else {
    gridItemsCount = 3;
  }

  const gridItem = (
    <Grid xs={12}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignContent: "center",
          alignItems: "start",
          // marginX: "auto",
          flexDirection: "column",
          width: "100%",
        }}>
        <Box
          sx={{
            width: "100%",
            // px: 4,
            display: "flex",
            justifyContent: "start",
            alignItems: "start",
            flexDirection: "column",
            position: "relative",
            // background: "red",
          }}>
          <Skeleton
            variant="rectangular"
            sx={{ width: "100%", height: { xs: "250px", md: "400px" } }}
          />
          <Box
            position="absolute"
            top={0}
            left={0}
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}>
            <Skeleton
              variant="text"
              sx={{
                width: { xs: "290px", md: "590px" },
                fontSize: { xs: "34px", md: "48px" },
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}>
          <Box
            sx={{
              flexGrow: 1,
              px: { xs: 4, sm: 6, md: 10 },
              display: "flex",
              justifyContent: "center",
            }}>
            <Box
              sx={{
                width: "100%",
                height: "500px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Box
                sx={{
                  maxWidth: "900px",
                  width: "100%",
                }}>
                <Skeleton
                  variant="text"
                  sx={{ width: "95%", fontSize: "18px", mt: "14px" }}
                />
                <Skeleton
                  variant="text"
                  sx={{ width: "93%", fontSize: "18px", mb: "14px" }}
                />
                <Skeleton
                  variant="text"
                  sx={{ width: "97%", fontSize: "18px", mt: "14px" }}
                />
                <Skeleton
                  variant="text"
                  sx={{ width: "93%", fontSize: "18px" }}
                />
                <Skeleton
                  variant="text"
                  sx={{ width: "95%", fontSize: "18px" }}
                />
                <Skeleton
                  variant="text"
                  sx={{ width: "93%", fontSize: "18px" }}
                />
                <Skeleton
                  variant="text"
                  sx={{ width: "97%", fontSize: "18px", mb: "14px" }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "start",
            flexDirection: "column",
            width: "100%",
          }}>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
              //   flexDirection: "ROW",
              gap: 1,

              //   display: "grid",
              //   gridTemplateColumns: {
              //     xs: "repeat(2, 1fr)",
              //     md: "repeat(4, 1fr)",
              //   },
              //   gridTemplateRows: {
              //     xs: "repeat(4, 475px)",
              //     md: "repeat(2, 475px)",
              //   },
              //   gap: "10px",
              //   width: "100%",
            }}>
            <Skeleton
              variant="rectangular"
              sx={{ height: 475, width: "calc(50% - 8px)" }}
            />
            <Skeleton
              variant="rectangular"
              sx={{ height: 475, width: "calc(50% - 8px)" }}
            />
            <Skeleton
              variant="rectangular"
              sx={{ height: 475, width: "100%" }}
            />
            <Skeleton
              variant="rectangular"
              sx={{ height: 475, width: "calc(50% - 8px)" }}
            />
            <Skeleton
              variant="rectangular"
              sx={{ height: 475, width: "calc(50% - 8px)" }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}>
          <Box
            sx={{
              flexGrow: 1,
              px: { xs: 4, sm: 6, md: 10 },
              display: "flex",
              justifyContent: "center",
            }}>
            <Box
              sx={{
                width: "100%",
                height: "500px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Box
                sx={{
                  maxWidth: "900px",
                  width: "100%",
                }}>
                <Skeleton
                  variant="text"
                  sx={{ width: "95%", fontSize: "18px", mt: "14px" }}
                />
                <Skeleton
                  variant="text"
                  sx={{ width: "93%", fontSize: "18px", mb: "14px" }}
                />
                <Skeleton
                  variant="text"
                  sx={{ width: "97%", fontSize: "18px", mt: "14px" }}
                />
                <Skeleton
                  variant="text"
                  sx={{ width: "93%", fontSize: "18px" }}
                />
                <Skeleton
                  variant="text"
                  sx={{ width: "95%", fontSize: "18px" }}
                />
                <Skeleton
                  variant="text"
                  sx={{ width: "93%", fontSize: "18px" }}
                />
                <Skeleton
                  variant="text"
                  sx={{ width: "97%", fontSize: "18px", mb: "14px" }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            mb: 6,
          }}>
          <Box
            sx={{
              width: "100%",
              maxWidth: "900px",
              display: "flex",
              justifyContent: "center",
              alignItems: "start",
              flexDirection: "column",
              px: { xs: 4, sm: 6, md: 10 },
            }}>
            <Skeleton
              variant="rectangular"
              sx={{ height: { xs: "50vh", md: "70vh" }, width: "100%" }}
            />
          </Box>
        </Box>
      </Box>
    </Grid>
  );

  return (
    <Box sx={{ width: "100%", height: "auto" }} className="">
      <Box
        sx={{
          width: "100%",
          minHeight: "960px",
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          flexDirection: "column",
          //   mt: 4,
        }}>
        <Grid
          container
          sx={{
            width: "100%",
            overflow: "hidden",
            // mt: 4,
          }}
          spacing={2}>
          {Array(lines)
            .fill(null)
            .map((_, index) => (
              <React.Fragment key={index}>{gridItem}</React.Fragment>
            ))}
        </Grid>
      </Box>
    </Box>
  );
};

export { SkeletonStaticPage };
