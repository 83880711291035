import Idto from "interfaces/idto.interface";
import { FilterDto } from "./filter.dto";
import { FilterDictionarDto } from "./filterdictionar.dto";
import { FilterType } from "tools/types/filtertype";
import { ProductDto } from "./product.dto";
import { OfferFilterDto } from "./offerfilter.dto";

export class ProductFilterDto implements Idto {
  id?: number | string;
  idproduct?: number;
  idfilter?: number;
  idfilterdictionar?: number;
  filtervalue?: string;
  filterObj?: FilterDto;
  filterDictionarObj?: FilterDictionarDto;

  constructor(
    id?: number | string,
    idproduct?: number,
    idfilter?: number,
    idfilterdictionar?: number,
    filtervalue?: string
  ) {
    this.id = id || 0;
    this.idproduct = idproduct || 0;
    this.idfilter = idfilter || 0;
    this.idfilterdictionar = idfilterdictionar || 0;
    this.filtervalue = filtervalue || "";
  }

  static groupByFilterId(arr: ProductFilterDto[]): DisplayProductFilterGroup {
    let displayFilterDictinonar: DisplayProductFilterDictionar[] = [];
    let displayFilterValues: DisplayProductFilter[] = [];
    let processedFilters = new Set<number>();

    arr.forEach((item) => {
      if (item && item.idfilter) {
        if (item && item.filterObj && !processedFilters.has(item.idfilter)) {
          processedFilters.add(item.idfilter);
          if (item.filterObj.type === FilterType.DICTIONAR) {
            let filterDictinonar = arr.filter(
              (x) => x.idfilter === item.idfilter
            );
            displayFilterDictinonar.push({
              filter: item.filterObj,
              dictionarValues: filterDictinonar.map(
                (x) => x.filterDictionarObj ?? new FilterDictionarDto()
              ),
            });
          } else {
            displayFilterValues.push({
              filter: item.filterObj,
              filterValue: item.filtervalue ?? "",
            });
          }
        }
      }
    });

    return { displayFilterDictinonar, displayFilterValues };
  }

  static productFilterToSelectedProductFilterDto(
    object: ProductFilterDto
  ): SelectedProductFilterDto {
    return new SelectedProductFilterDto(
      object.idfilter ?? 0,
      object.idfilterdictionar ?? 0
    );
  }
  static arrProductFilterToSelectedProductFilterDto(
    arr: ProductFilterDto[]
  ): SelectedProductFilterDto[] {
    if (!arr || !arr.length) return [];
    return arr.map((x) => this.productFilterToSelectedProductFilterDto(x));
  }
}

export type DisplayProductFilterDictionar = {
  filter: FilterDto;
  dictionarValues: FilterDictionarDto[];
};

export type DisplayProductFilter = {
  filter: FilterDto;
  filterValue: string;
};

export type DisplayProductFilterGroup = {
  displayFilterDictinonar: DisplayProductFilterDictionar[];
  displayFilterValues: DisplayProductFilter[];
};

export class SelectedProductFilterDto {
  idfilter: number;
  idfilterdictionar: number;
  filterObj?: FilterDto;
  filterDictionarObj?: FilterDictionarDto;

  constructor(
    idfilter: number,
    idfilterdictionar: number,
    
  ) {
    if (!idfilter || !idfilterdictionar) idfilter = idfilterdictionar = 0;
    idfilter = typeof idfilter == "string" ? parseInt(idfilter) : idfilter;
    idfilterdictionar =
      typeof idfilterdictionar == "string"
        ? parseInt(idfilterdictionar)
        : idfilterdictionar;
    this.idfilter = idfilter;
    this.idfilterdictionar = idfilterdictionar;
    
  }

  static prepareDefaultFilters(
    arr: DisplayProductFilterDictionar[],
    product: ProductDto | null
  ): SelectedProductFilterDto[] {
    if (!arr) return [];
    if (!Array.isArray(arr)) return [];
    if (!arr.length) return [];
    let defaultFilters: OfferFilterDto[] = [];

    if (
      product &&
      product.defaultOfferObj &&
      product.defaultOfferObj.offerFilters &&
      product.defaultOfferObj.offerFilters.length
    ) {
      defaultFilters = product.defaultOfferObj.offerFilters;
    }
    let result: SelectedProductFilterDto[] = [];
    if (!defaultFilters.length) {
      arr.forEach((item) => {
        if (item && item.dictionarValues && item.filter && item.filter.id) {
          if (
            item.dictionarValues.length &&
            item.dictionarValues[0] &&
            item.dictionarValues[0].id
          ) {
            result.push(
              new SelectedProductFilterDto(
                typeof item.filter.id == "string"
                  ? parseInt(item.filter.id)
                  : item.filter.id,
                typeof item.dictionarValues[0].id == "string"
                  ? parseInt(item.dictionarValues[0].id)
                  : item.dictionarValues[0].id
              )
            );
          }
        }
      });
    } else {
      arr.forEach((item) => {
        if (item && item.filter && item.filter.id) {
          let defaultFilter = defaultFilters.find(
            (x) => x.idfilter === item.filter.id
          );
          if (
            defaultFilter &&
            defaultFilter.idfilterdictionar &&
            defaultFilter.idfilter
          ) {
            result.push(
              new SelectedProductFilterDto(
                typeof defaultFilter.idfilter == "string"
                  ? parseInt(defaultFilter.idfilter)
                  : defaultFilter.idfilter,
                typeof defaultFilter.idfilterdictionar == "string"
                  ? parseInt(defaultFilter.idfilterdictionar)
                  : defaultFilter.idfilterdictionar
              )
            );
          }
        }
      });
    }

    return result;
  }
}
