import React, { useState } from "react";
import { Badge, Box } from "@mui/material";
import { IconType } from "components/elements/icon/IconContext";
import { MyIconButton } from "components/elements/icon/MyIconButton";
import { Cart } from "components/product/cart/Cart";
import { useCart } from "hooks/useCart";
import { HeaderBlockProps } from "interfaces/header.interface";


const CartBlock: React.FC<HeaderBlockProps> = ({ colorSvg }) => {
  const { cartLength } = useCart();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  return (
    <Box>
      <Badge badgeContent={cartLength} color="primary">
        <MyIconButton
          id="basic-button-cart"
          aria-controls={open ? "basic-menu-cart" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          typeIcon={IconType.CART}
          colorSvg={colorSvg}
        />
      </Badge>
      <Cart anchorEl={anchorEl} handleClose={handleClose} open={open} />
    </Box>
  );
};

export { CartBlock };
