import { Box, Typography } from "@mui/material";
import { HtmlTag } from "components/elements/display/HtmlTag";

import { usePage } from "hooks/usePage";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";
import { Config } from "tools/utils/config";

import { GalleryDto } from "dto/system/gallery.dto";

import { SkeletonAboutUs } from "components/elements/skeleton/SkeletonAboutUs";

import { ContactPointMap } from "components/static/contactpoint/ContactPointMap";

type Props = {
  item?: GalleryDto;
};

const ContactHome: React.FC<Props> = ({ item }) => {
  const id = Config.ID_CONTACTS_PAGE;
  const { object, loading } = usePage(id, true, "contacts_block_home");

  if (!id) return null;
  if (loading)
    return (
      <Box sx={{ mt: 3 }}>
        <SkeletonAboutUs />
      </Box>
    );
  if (!object) return null;
  return (
    <Box
      sx={{
        py: 3,
        width: "100%",
        display: "flex",
        flexGrow: 1,
        justifyContent: "center",
      }}>
      {/* <Image
        url={CommonTools.processObjectField(object, [
          "_galleryDefault",
          "cdnurl",
        ])}
        typeMeasure="percentage"
        sizeInPercentage={{ width: "100%", height: "100%" }}
        withLinK={false}
        style={{ width: "100%", height: "100%", objectFit: "cover" }}
        // style={{
        //   objectFit: "cover",
        //   width: "100%",
        // }}
        // cutImageWidth={844}
        // cutImageHeight={910}
      /> */}

      <Box
        sx={{
          width: "100%",
          maxWidth: "1500px",
          px: { xs: 4, sm: 6, md: 10 },
          display: "flex",
          justifyContent: "space-around",
          alignItems: "start",
          flexDirection: { xs: "column", md: "row" },
        }}>
        <Box
          sx={{
            width: { xs: "100%", md: "50%" },
            height: { xs: "400px", md: "600px" },
          }}>
          <ContactPointMap/>
        </Box>
        <Box sx={{ width: { xs: "100%", md: "50%" }, mt: { xs: 4, md: 0 } }}>
          <Typography
            data-aos="fade-right"
            data-aos-delay="0"
            sx={{
              width: "100%",
              fontSize: { xs: 36, md: 48 },
              fontWeight: "bold",

              color: "#000",
              mt: 3,
              // px: { xs: 6, sm: 4, md: 0 },
              textAlign: { xs: "center", lg: "center" },
            }}>
            {CommonTools.processObjectField(object, ["_name"])}
          </Typography>
          <Box
            data-aos="fade-right"
            data-aos-delay="100"
            sx={{
              width: "100%",
              mt: 4,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              fontSize: { xs: "18px", md: "24px" },
              // px: { xs: 6, sm: 4, md: 0 },
              color: "#000",
            }}>
            <Box
              className="contact-home"
              sx={{
                textAlign: "center",
                width: "100%",
                textDecoration: "none",
                color: "#000",
              }}>
              <HtmlTag
                content={CommonTools.processObjectField(object, [
                  "_description",
                ])}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export { ContactHome };
