import React from "react";

import { Typography } from "@mui/material";
import { DisplayProductFilter } from "dto/product/productfilter.dto";
import { DetailItem } from "../product/page/DetailItem";
import { CommonTools } from "tools/utils/common.tool";

type Props = {
  item: DisplayProductFilter;
};

const ProductFilterItemValue: React.FC<Props> = ({ item }) => {
  if (!item) return null;
  return (
    <DetailItem
      identifier={CommonTools.processObjectField(item, ["filter", "_name"])}
      defaultOpen={true}
    >
      <Typography>
        {CommonTools.processObjectField(item, ["filterValue"])}
      </Typography>
    </DetailItem>
  );
};

export { ProductFilterItemValue };
