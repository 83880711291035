import RequestListDTO from "dto/app/requestlist.dto";
import GeneralRepository from "../general.repository";
import IGeneralRepository from "interfaces/irepository.interface";
import { ConfigTypes } from "tools/types/configtypes";

export class ConfigFileRepository
  extends GeneralRepository
  implements IGeneralRepository
{
  constructor() {
    super("configfile");
  }

  getHash = async (cb?: any, cbParams?: any) => {
    const url = `/${this.mainUrl}/hash?filters=type,${ConfigTypes.SITE}`;
    return await this.getObject(url, cb, cbParams);
  };

  getConfig = async (str: string, type: number, cb?: any, cbParams?: any) => {
    const url = `/${this.mainUrl}/getconfig/${str}/${type}`;
    return await this.getObject(url, cb, cbParams);
  };

  getConfigSite = async (cb?: any, cbParams?: any, data?: RequestListDTO) => {
    const url = `/${this.mainUrl}/listconfig`;
    return await this.getListObject(url, cb, cbParams, data);
  };
}
