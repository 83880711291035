import { Box } from "@mui/material";
import { VideoPlayer } from "components/elements/display/VideoPlayer";
import { BlogDto } from "dto/static/blog.dto";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";

type Props = {
  object: BlogDto;
};

const BlogVideo: React.FC<Props> = ({ object }) => {
  if (!object) return null;
  if (!CommonTools.processObjectField(object, ["_videoDefault", "video_id"]))
    return null;
  if (!CommonTools.processObjectField(object, ["_videoDefault", "video_type"]))
    return null;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        height: { xs: "50vw", sm: "50vw", md: "450px" },
        mb: 4,
      }}>
      <Box className="videoBlog" sx={{ width: "100%", height: "100%" }}>
        <VideoPlayer
          videoId={CommonTools.processObjectField(object, [
            "_videoDefault",
            "video_id",
          ])}
          type={parseInt(
            CommonTools.processObjectField(object, [
              "_videoDefault",
              "video_type",
            ])
          )}
          width={500}
          height={300}
        />
      </Box>
    </Box>
  );
};

export { BlogVideo };
