import React from "react";
import { LanguageDto } from "dto/static/language.dto";
import { Button, MenuItem } from "@mui/material";
import { CommonTools } from "tools/utils/common.tool";
import { useLanguage } from "providers/LanguageProvider";
type Props = {
  item: LanguageDto;
  toggleDetail: (event: React.MouseEvent<HTMLButtonElement>) => void;
};
const LanguageSiteOption: React.FC<Props> = ({ item, toggleDetail }) => {
  const { selectedLanguageCode, changeLanguage } = useLanguage();
  const isSelected =
    selectedLanguageCode === CommonTools.processObjectField(item, ["code"]);

  const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (isSelected) return;
    if (!CommonTools.processObjectField(item, ["code"])) return;
    toggleDetail(event);
    changeLanguage(CommonTools.processObjectField(item, ["code"]));
  };
  
  return isSelected ? null : (
    <MenuItem
      key={CommonTools.processObjectField(item, ["id"])}
      onClick={onClick}
      component={Button}
    >
      {CommonTools.processObjectField(item, ["nativename"])}
    </MenuItem>
  );
};

export { LanguageSiteOption };
