import Idto from "../../interfaces/idto.interface";
import MessageDto from "./message.dto";
import { ResultMetaDTO } from "./resultmeta.dto";

export default class ResultObjectDTO<T = Idto > implements Idto {
  error?: number;
  htmlcode?: number
  messages?: MessageDto[];
  obj?: T | null;
  metaObj?: ResultMetaDTO;
}
