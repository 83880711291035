import Idto from "interfaces/idto.interface";

export class RequestCriteriaDTO implements Idto {
  id: string;
  values: string[];

  constructor(id: string, values: string[]) {
    this.id = id;
    this.values = values;
  }
}
